import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

import { message } from 'utils'
import { HeaderRow, TableTitle, Col } from 'components/display'
import CommonIssueProblemTable from 'components/table/commonIssueProblemTable'
import { FieldInput } from 'components/input'

const CommonProblemList = (props) => {
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const [query, setQuery] = useState('')
  const [timeoutId, setTimeoutId] = useState(null)
  const navigate = useNavigate()
  const timeout = 1500

  const { display } = props.common_problem.toJS()
  const { pagination } = display

  const onInit = useCallback(
    async ({ index, per_page } = {}) => {
      try {
        setLoading(true)

        await Promise.all([
          props.common_problem.getCategoryList(false),
          props.common_problem.getCountList(false),
        ])

        await props.common_problem.getList()
      } catch (e) {
        message.error({
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.common_problem]
  )
  const onQuery = useCallback(
    async (val) => {
      try {
        setLoading(true)
        await props.common_problem.getList(val)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.admin]
  )

  useEffect(() => {
    let values = query
    onQuery(values)
  }, [query])

  useEffect(() => {
    onInit()
  }, [onInit])

  const handleChange = (value) => {
    setText(value)
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, timeout)
    )
  }
  const onAdd = () => {
    const doc = {}
    props.common_problem.setCommonProblem(doc)
    navigate('/common-problem/detail')
  }
  const onEdit = () => {
    navigate('/common-problem/category')
  }

  return (
    <>
      <ContainerInputField>
        <FieldInput
          className="input"
          type="text"
          placeholder="ค้นหา"
          value={text}
          onChange={handleChange}
          startIcon={<SearchOutlined className="iconsSearch" />}
          admin
        />
        <div className="containerBtn">
          <button onClick={onEdit}>แก้ไขหมวด</button>
          <TableTitle onAdd={onAdd} />
        </div>
      </ContainerInputField>

      <CommonIssueProblemTable
        data={display.list}
        loading={loading}
        onChange={onInit}
        page={pagination}
      />
    </>
  )
}

const PageView = styled.div`
  width: 100%;
`
const ContainerInputField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .containerBtn {
    align-items: center;
    display: flex;
  }
  .containerBtn button {
    width: 120px;
    height: 40px;
    margin-bottom: 4px;
    color: #fdc851;
    background-color: transparent;
    border: 2px solid #d9dbe9;
    border-radius: 16px;
    :hover {
      cursor: pointer;
    }
  }
`

export default inject('common_problem')(observer(CommonProblemList))
