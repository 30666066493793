import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import AppMenu from 'pages/menu'
import { message } from 'utils'
import Loading from 'components/loading'

import download from '../../assets/Download.png'
import Rating from './rating'
import Detail from './detail'
import FeedbackTab from './component/FeedbackTab'

function Feedback(props) {
  const [pageSelect, setPageSelect] = useState('rating')

  const onClick = (value) => {
    setPageSelect(value)
  }
  const [loading, setLoading] = useState(false)
  const { feedback } = props
  const { summary = {} } = feedback.toJS()

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await feedback.getSummary()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [feedback])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onDownload = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)

      await props.feedback.getReport()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  return (
    <AppMenu name="feedback">
      <Loading loading={loading}>
        <Container>
          <FeedbackTab onClick={onClick} />

          <Download onClick={onDownload}>
            <div style={{ cursor: 'pointer' }}>
              <img src={download} alt="download" />
            </div>
          </Download>

          {pageSelect === 'rating' ? (
            <>
              <Rating data={summary} />
            </>
          ) : pageSelect === 'feedback' ? (
            <>
              <Detail data={summary} />
            </>
          ) : null}
        </Container>
      </Loading>
    </AppMenu>
  )
}

const Container = styled.div``
const Download = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`

export default inject('feedback')(observer(Feedback))
