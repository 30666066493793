export const IssueProblemStatus = {
  pending: 'รอการตอบรับ',
  response: 'ตอบรับแล้ว',
  closed: 'ปิด',
}

export const PackageType = {
  trial: 'แพ็กเกจฟรี',
  free: 'แพ็กเกจฟรี',
  starter: 'แพ็กเกจเริ่มต้น',
  standard: 'แพ็กเกจเริ่มต้น',
  team: 'แพ็กเกจทีม',
  professional: 'แพ็กเกจโปร',
}

export const NewPackageType = {
  free: 'แพ็กเกจฟรี',
  standard: 'แพ็กเกจเริ่มต้น',
}

export const PackageTypeOptions = Object.entries(NewPackageType).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const PackageDuration = { per_month: 'รายเดือน', per_year: 'รายปี' }

export const PackageDurationOptions = Object.entries(PackageDuration).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const PromotionType = {
  package: 'package',
  credit: 'credit',
  discount: 'discount',
}

export const PromotionTypeTH = {
  [PromotionType.package]: 'แพ็กเกจ',
  [PromotionType.credit]: 'เครดิต',
  [PromotionType.discount]: 'ส่วนลด',
}

export const PromotionTypeUnitTh = {
  [PromotionType.package]: 'แพ็กเกจ',
  [PromotionType.credit]: 'บาท',
  [PromotionType.discount]: 'บาท',
}

export const PromotionTypeOptions = [
  { label: PromotionTypeTH.package, value: PromotionType.package },
  { label: PromotionTypeTH.credit, value: PromotionType.credit },
  { label: PromotionTypeTH.discount, value: PromotionType.discount },
]

export const PAYMENT_TYPE = Object.freeze({
  CARD: 'card',
  QR: 'qr',
})

export const PAYMENT_TYPE_TH = Object.freeze({
  [PAYMENT_TYPE.CARD]: 'บัตรเครดิต',
  [PAYMENT_TYPE.QR]: 'หมายเลขโทรศัพท์',
})

export const LIFE_BRIDGE_STATE = {
  NEW: 'newcomer',
  PROCESSING: 'pending',
  REJECTED: 'rejected',
  APPROVED: 'approved',
}

export const LIFE_BRIDGE_STATE_TH = {
  [LIFE_BRIDGE_STATE.NEW]: 'ใหม่',
  [LIFE_BRIDGE_STATE.PROCESSING]: 'ดำเนินการ',
  [LIFE_BRIDGE_STATE.REJECTED]: 'ปฎิเสธ',
  [LIFE_BRIDGE_STATE.APPROVED]: 'อนุมัติแล้ว',
}

export const LIFE_BRIDGE_STATE_OPTIONS = Object.values(LIFE_BRIDGE_STATE).map(
  (l) => ({
    label: LIFE_BRIDGE_STATE_TH[l],
    value: l,
  })
)

export const USER_AGENT_TYPE = Object.freeze({
  LIA: {
    EN: 'Life Insurance Agent',
    TH: 'ใบอนุญาตตัวแทนประกันชีวิต',
  },
  'LIA/ULP': {
    EN: 'Life Insurance Agent + Unit-linked Product',
    TH: 'ใบอนุญาตตัวแทนประกันชีวิต+ประกันชีวิตควบการลงทุน',
  },
  NIA: {
    EN: 'Non-life Insurance Agent',
    TH: 'ใบอนุญาตตัวแทนประกันวินาศภัย',
  },
  LIB: {
    EN: 'Life Insurance Broker',
    TH: 'ใบอนุญาตนายหน้าประกันชีวิต',
  },
  NIB: {
    EN: 'Non-life Insurance Broker',
    TH: 'ใบอนุญาตนายหน้าประกันวินาศภัย',
  },
  IC: {
    EN: 'Investment Consultant',
    TH: 'ใบอนุญาตผู้แนะนำการลงทุน',
  },
  IB: {
    EN: 'Investment Brokerage',
    TH: 'ใบอนุญาตผู้แนะนำลูกค้าหลักทรัพย์',
  },
  IP: {
    EN: 'Investment Planner',
    TH: 'ใบอนุญาตผู้วางแผนการลงทุน',
  },
  CFP: {
    EN: 'Certified Financial Planner',
    TH: 'สมาคมนักวางแผนการเงินไทย',
  },
  AFPT: {
    EN: 'Associate Financial Planner Thailand',
    TH: 'เลขที่สมาชิกวิสามัญ สมาคมนักวางแผนการเงินไทย',
  },
})
