import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1} = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23 3H1C0.4 3 0 3.4 0 4V18C0 19.7 1.3 21 3 21H21C22.7 21 24 19.7 24 18V4C24 3.4 23.6 3 23 3ZM19.9 5L12.6 10.3C12.2 10.6 11.8 10.6 11.4 10.3L4.10001 5H19.9ZM21 19H3C2.4 19 2 18.6 2 18V6L10.2 12C10.7 12.4 11.3 12.6 12 12.6C12.7 12.6 13.2 12.4 13.8 12L22 6V18C22 18.6 21.6 19 21 19Z" fill="#14142B"/>
    </svg>

  )
}

// eslint-enable
export default Logo
