import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Dropdown, FieldInput } from 'components/input'
import { Link } from 'components/link'
import Loading from 'components/loading'
import { Text } from 'components/typo'
import { helper, message } from 'utils'

import validate from './edit.validate'

function LifeBridgeTeamEdit(props) {
  const {
    current = {},
    lifeBridgeAgentOptions = [],
    provinceOptions = [],
  } = props.life_bridge.toJS()
  const { id } = useParams()
  const navigate = useNavigate()

  const [initialized, setInitialized] = useState(false)
  const [formData, setFormData] = useState({
    name: null,
    leader_id: null,
    province_id: null,
  })
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const modifiedLifeBridgeAgentOptions = useMemo(() => {
    return lifeBridgeAgentOptions.map((l) => ({
      value: l.life_bridge_agent_id,
      label: `${l.firstname} ${l.lastname}`,
    }))
  }, [lifeBridgeAgentOptions])

  const modifiedProvinceOptions = useMemo(() => {
    return provinceOptions.map((p) => ({
      value: p.province_id,
      label: p.name_th,
    }))
  }, [provinceOptions])

  const isValid = useMemo(() => {
    const formTouched =
      formData.name !== current.company_affiliation_name ||
      formData.leader_id !== current.leader_id ||
      formData.province_id !== current.province_id
    return formTouched && helper.isEmpty(errors)
  }, [formData, current, errors])

  const onChange = (val, name) => {
    setFormData({ ...formData, [name]: val })
    setErrors((prev) => {
      const modifiedErrors = { ...prev }
      delete modifiedErrors[name]
      return modifiedErrors
    })
  }

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      setErrors({})
      const errors = validate(formData)
      const isValid = helper.isEmpty(errors)
      if (isValid) {
        const modifiedFormData = { ...formData }
        if (modifiedFormData.leader_id) {
          modifiedFormData.leader_name = modifiedLifeBridgeAgentOptions.find(
            (o) => o.value === modifiedFormData.leader_id
          ).value
        }
        await props.life_bridge.updateTeam({
          company_affiliation_id: parseInt(id),
          ...formData,
        })
        message.success({ message: 'บันทึกสำเร็จ' })
        navigate(`/life-bridge/${id}`)
      } else {
        setErrors(errors)
      }
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [
    id,
    formData,
    props.life_bridge,
    modifiedLifeBridgeAgentOptions,
    navigate,
  ])

  const getTeamDetail = useCallback(
    async (company_affiliation_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeam(company_affiliation_id)
        setInitialized(true)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const onInit = useCallback(
    async (id) => {
      try {
        await props.life_bridge.getProvinceOptions()
        await props.life_bridge.getLifeBridgeAgentOptions({
          page: 1,
          per_page: 999,
        })
        await getTeamDetail(id)
      } catch (error) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      }
    },
    [props.life_bridge, getTeamDetail]
  )

  useEffect(() => {
    onInit(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Loading loading={loading}>
      {initialized && (
        <HackedForm current={current} setFormData={setFormData} />
      )}
      <Link
        to={`/life-bridge/${id}`}
        style={{ color: 'var(--fd-secondary-dark-color)' }}
      >
        {`< หน่วย ${current.company_affiliation_name}`}
      </Link>
      <Container>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row justify="center">
              <Text large bold align="center">
                แก้ไข หน่วย {current.company_affiliation_name}
              </Text>
            </Row>
          </Col>
          <Col span={24}>
            <FieldInput
              label=""
              name="name"
              placeholder="ชื่อทีม"
              value={formData.name}
              onChange={onChange}
              errorText={errors.name}
            />
          </Col>
          <Col span={24}>
            <Dropdown
              label=""
              name="leader_id"
              placeholder="หัวหน้าทีม"
              value={formData.leader_id}
              onChange={onChange}
              errorText={errors.leader_id}
              options={modifiedLifeBridgeAgentOptions}
            />
          </Col>
          <Col span={24}>
            <Dropdown
              label=""
              filterable
              name="province_id"
              placeholder="จังหวัด"
              value={formData.province_id}
              onChange={onChange}
              errorText={errors.province_id}
              options={modifiedProvinceOptions}
            />
          </Col>
          <Col span={24}>
            <Row justify="center">
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={onSubmit}
                disabled={!isValid}
              >
                บันทึก
              </button>
            </Row>
          </Col>
        </Row>
      </Container>
    </Loading>
  )
}

function HackedForm(props) {
  useEffect(() => {
    props.setFormData({
      name: props.current.company_affiliation_name,
      leader_id: props.current.leader_id,
      province_id: props.current.province_id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export default inject('life_bridge')(observer(LifeBridgeTeamEdit))

const Container = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
`
