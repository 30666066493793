import { Divider, Empty, Progress } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import Loading from 'components/loading'
import { Text } from 'components/typo'
import {
  Rate_expect,
  Rate_feeling,
  Rate_package,
  Rate_suggest,
} from 'definition/feedback'
import { message } from 'utils'

import FeedbackComment from '../component/Comment'

function Detail(props) {
  const { feedback } = props
  const { pagination = {} } = feedback.toJS()
  const { data = {} } = props

  const {
    expect_summary = {},
    feeling_summary = {},
    suggest_summary = {},
    change_package_summary = {},
  } = data

  const Rate_expect_key = Object.keys(expect_summary)
  const Rate_expect_val = Object.values(expect_summary)
  const average_expect = Math.round(expect_summary.avg)

  const Rate_feeling_key = Object.keys(feeling_summary)
  const Rate_feeling_val = Object.values(feeling_summary)
  const average_feel = Math.round(feeling_summary.avg)

  const Rate_suggest_key = Object.keys(suggest_summary)
  const Rate_suggest_val = Object.values(suggest_summary)
  const average_suggest = Math.round(suggest_summary.avg)

  const Rate_package_key = Object.keys(change_package_summary)
  const Rare_package_val = Object.values(change_package_summary)
  const average_package = Math.round(change_package_summary.avg)

  const { per_page } = pagination
  const [page, setPage] = useState(1)
  const [comments, setComments] = useState([])
  const [prevY, setPrevY] = useState(0)
  const [loading, setLoading] = useState(false)
  let hasMore = true
  let loadingRef = useRef(null)
  let prevYRef = useRef({})
  let pageRef = useRef({})
  let commentRef = useRef({})
  commentRef.current = comments
  pageRef.current = page
  prevYRef.current = prevY

  const modifiedCommentList = useMemo(() => {
    return comments.filter((c) => c.feedback_list)
  }, [comments])

  const onInit = async () => {
    try {
      setLoading(true)
      await feedback.getFeedback(pageRef.current, per_page)

      setComments([...commentRef.current, ...toJS(props.feedback.comment)])
      if (toJS(props.feedback.comment).length < per_page) {
        feedback.setHasmore(false)
      } else {
        feedback.setHasmore(true)
      }

      hasMore = props.feedback.hasMore
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    onInit()

    setPage(pageRef.current + 1)

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)
    observer.observe(loadingRef.current)
  }, [])

  const handleObserver = (entities, observer) => {
    setLoading(true)
    const y = entities[0].boundingClientRect.y
    if (prevYRef.current > y && hasMore) {
      onInit()
      setPage(pageRef.current + 1)
    }
    setLoading(false)
    setPrevY(y)
  }

  return (
    <>
      <Topsection>
        <Text>แอพพลิชันของเราตอบสนองต่อความคาดหวังของคุณได้ดีเพียงใด</Text>
        <div></div>

        <div className="strCon">
          <Text huge bold align="center">
            {Rate_expect[`rate_lv${average_expect}`] || '-'}
          </Text>
        </div>

        <BarItems>
          {Rate_expect_val.map((item, idx) => {
            return idx !== Rate_expect_val.length - 1 ? (
              <Wrapper>
                <span className="barName">
                  {Rate_expect[Rate_expect_key[idx]]}
                </span>
                <Progress
                  strokeColor={'#FDC850'}
                  strokeWidth={15}
                  key={idx}
                  percent={item ? parseInt(item) : 0}
                />
              </Wrapper>
            ) : null
          })}
        </BarItems>

        <Text>แอพพลิชันของเราให้คุณรู้สึกอย่างไร</Text>
        <div></div>

        <div className="strConL">
          <Text large bold align="center">
            {Rate_feeling[`rate_lv${average_feel}`] || '-'}
          </Text>
        </div>

        <BarItems>
          {Rate_feeling_val.map((item, idx) =>
            idx !== Rate_feeling_val.length - 1 ? (
              <WrapperLong>
                <span className="barName">
                  {Rate_feeling[Rate_feeling_key[idx]]}
                </span>

                <Progress
                  strokeColor={'#FDC850'}
                  strokeWidth={15}
                  key={idx}
                  percent={item ? parseInt(item) : 0}
                />
              </WrapperLong>
            ) : null
          )}
        </BarItems>

        <Text>
          คุณมีแนวโน้มที่จะปรับไปใช้แพ็กเกจเริ่มต้นของ friday ในอนาคตหรือไม่
        </Text>
        <div></div>
        <div className="strCon">
          <Text huge bold align="center">
            {Rate_package[`rate_lv${average_package}`] || '-'}
          </Text>
        </div>

        <BarItems>
          {Rare_package_val.map((item, idx) =>
            idx !== Rare_package_val.length - 1 ? (
              <Wrapper>
                <span className="barName">
                  {Rate_package[Rate_package_key[idx]]}
                </span>
                <Progress
                  strokeColor={'#FDC850'}
                  strokeWidth={15}
                  key={idx}
                  percent={item ? parseInt(item) : 0}
                />
              </Wrapper>
            ) : null
          )}
        </BarItems>

        <Text>คุณสนใจอยากให้ข้อมูลเพิ่มเติมกับทีมพัฒนาโดยตรงหรือไม่</Text>
        <div></div>
        <div className="strCon">
          <Text huge bold align="center">
            {Rate_suggest[`rate_lv${average_suggest}`] || '-'}
          </Text>
        </div>

        <BarItems>
          {Rate_suggest_val.map((item, idx) =>
            idx !== Rate_suggest_val.length - 1 ? (
              <Wrapper>
                <span className="barName">
                  {Rate_suggest[Rate_suggest_key[idx]]}
                </span>
                <Progress
                  strokeColor={'#FDC850'}
                  strokeWidth={15}
                  key={idx}
                  percent={item ? parseInt(item) : 0}
                />
              </Wrapper>
            ) : null
          )}
        </BarItems>
      </Topsection>

      <Divider />
      <ol>
        {modifiedCommentList.length > 0 ? (
          modifiedCommentList.map((item, idx) => {
            return (
              <FeedbackComment key={idx} item={item}>
                <p>
                  <span>
                    แอพพลิชันของเราตอบสนองต่อความคาดหวังของคุณได้ดีเพียงใด:
                  </span>{' '}
                  <b>{item.feedback_list.expectation_score || '-'}</b>
                  <br />
                  {item.feedback_list.expectation_reason ? (
                    <>
                      <span>บอกเหตุผลให้เราทราบ:</span>{' '}
                      <b>{item.feedback_list.expectation_reason || '-'}</b>
                      <br />
                    </>
                  ) : null}
                  <span>แอพพลิชันของเราให้คุณรู้สึกอย่างไร:</span>{' '}
                  <b>{item.feedback_list.feeling_score || '-'}</b>
                  <br />
                  <span>
                    คุณมีแนวโน้มที่จะปรับไปใช้แพ็กเกจเริ่มต้นของ friday
                    ในอนาคตหรือไม่:
                  </span>{' '}
                  <b>{item.feedback_list.change_package || '-'}</b>
                  <br />
                  {item.feedback_list.change_package_reason ? (
                    <>
                      <span>บอกเหตุผลให้เราทราบ:</span>{' '}
                      <b>{item.feedback_list.change_package_reason || '-'}</b>
                      <br />
                    </>
                  ) : null}
                  <span>
                    คุณสนใจอยากให้ข้อมูลเพิ่มเติมกับทีมพัฒนาโดยตรงหรือไม่:
                  </span>{' '}
                  <b>{item.feedback_list.suggestion_developer || '-'}</b>
                  <br />
                  {item.feedback_list.comment ? (
                    <>
                      <span>คุณมีอะไรอยากบอกเราอีกไหม:</span>{' '}
                      <b>{item.feedback_list.comment || '-'}</b>
                    </>
                  ) : null}
                </p>
              </FeedbackComment>
            )
          })
        ) : (
          <Empty />
        )}
        <div ref={loadingRef}>
          <Loading loading={loading}></Loading>
        </div>
      </ol>
    </>
  )
}

const Topsection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 40px;
  margin-top: 20px;
  margin-left: 50px;
  .star-ratings {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-top: 10px !important;
  }

  .strCon {
    display: grid;
    grid-template-columns: auto;
    margin-top: 50px;
    padding-right: 20px;
    max-width: 100%;
  }
  .strConL {
    display: grid;
    grid-template-columns: auto;
    margin-top: 50px;
    padding-right: 20%;
    padding-left: 20%;
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  width: 600px;

  span {
    padding-right: 10px;
  }
`

const WrapperLong = styled.div`
  display: block;
  width: 600px;

  span {
    padding-right: 10px;
  }
`

const BarItems = styled.div`
  display: grid;
  grid-template-columns: auto;

  .barName {
    width: 100px;
  }
`

export default inject('feedback')(observer(Detail))
