if (typeof window === 'undefined')
  global.window = { location: { host: 'testhost' } }

const maps = {
  'friday-admin-develop.web.app': 'testing',
  'uat-admin.friday.in.th': 'uat',
  'admin.friday.in.th': 'prod',
}

const host = window.location.host
const found = maps[host]

const env = found ? found : 'uat'

export const config = window._env_ ? window._env_ : require(`./config.${env}`)
// console.log(`version:`, config.version)
