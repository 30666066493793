import React from 'react'

import FieldInput from 'components/input/FieldInput'
import { Eye } from 'icon'

const PasswordInput = (props) => {
  const { label = 'รหัสผ่าน' } = props
  return (
    <FieldInput
      {...props}
      id={props.id || 'password'}
      label={label}
      startIcon={<Eye />}
      type="password"
      clearable
    />
  )
}

export default PasswordInput
