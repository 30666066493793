import React from 'react'
import styled from 'styled-components'

import Text from '../typo/Text'
import BaseBtn from '../button/BaseBtn'

const TabButton = (props) => {
  if (props.isSelected)
    return (
      <CustomButton isSelected={props.isSelected} onClick={props.onClick}>
        <Text small bold>
          {props.children}
        </Text>
        <Dot isSelected={props.isSelected} />
      </CustomButton>
    )

  return (
    <CustomButton isSelected={props.isSelected} onClick={props.onClick}>
      <Text small bold>
        {props.children}
      </Text>
    </CustomButton>
  )
}

const Dot = styled.div`
  border-radius: 100%;
  width: 8px;
  height: 8px;
  background-color: ${(props) => props.theme.gray.titleActive};

  transition: all 0.2s;
  transform: ${(props) => (props.isSelected ? 'scale(1)' : 'scale(0)')};
`

const CustomButton = styled(BaseBtn)`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  width: 108px;
  height: ${(props) => (props.isSelected ? '60px' : '48px;')};
  padding: 10px !important;
  border-radius: 16px !important;
  color: ${(p) => p.theme.gray.titleActive} !important;

  background-color: ${(p) =>
    p.isSelected && p.theme.color.primaryBg} !important;

  &:hover {
    color: ${(p) => p.theme.gray.body} !important;

    & ${Dot} {
      background-color: ${(p) => p.theme.gray.body} !important;
    }
  }

  &:disabled {
    opacity: 0.7;
  }
`

export default TabButton
