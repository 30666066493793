import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoRow, Row } from 'components/display'
import { Dropdown } from 'components/input'
import { Link } from 'components/link'
import Loading from 'components/loading'
import { PromotionTypeUnitTh } from 'definition/support'
import AppMenu from 'pages/menu'
import { format, message } from 'utils'

const PromotionDetail = (props) => {
  const [loading, setLoading] = useState(false)

  const { code } = useParams()
  const { current = {} } = props.promotion.toJS()

  const promotionUnit = useMemo(() => {
    return PromotionTypeUnitTh[current.promotion_type] || ''
  }, [current.promotion_type])

  const onInit = useCallback(
    async (code) => {
      try {
        setLoading(true)
        await props.promotion.getPromotion(code)
      } catch (e) {
        message.error({ message: e.message })
      } finally {
        setLoading(false)
      }
    },
    [props.promotion, setLoading]
  )

  const onEdit = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.promotion.updatePromotion({ ...params, code })
        message.success()
        onInit(code)
      } catch (e) {
        message.error({ message: e.message })
      } finally {
        setLoading(false)
      }
    },
    [code, props.promotion, onInit, setLoading]
  )

  const onChange = useCallback(
    async (val, _) => {
      if (val !== current.status) {
        await onEdit({ status: val })
      }
    },
    [current, onEdit]
  )

  useEffect(() => {
    onInit(code)
  }, [onInit, code])

  useEffect(() => {
    return () => {
      props.promotion.clearPromotion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppMenu name="promotion">
      <Row>
        <Link
          to="/setting/promotion"
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< ตั้งค่าโปรโมชัน`}
        </Link>
      </Row>
      <Loading loading={loading}>
        <PageView>
          <InfoContainer>
            <InfoRow
              label="สถานะ"
              text={
                <Dropdown
                  label="สถานะ"
                  name="status"
                  value={current.status}
                  options={[
                    { label: 'ใช้งาน', value: 'active' },
                    { label: 'ปิดการใช้งาน', value: 'inactive' },
                  ]}
                  onChange={onChange}
                />
              }
            />
            <InfoRow label="CODE" text={current.code} />
            <InfoRow label="ชื่อโปรโมชัน" text={current.name} />
            <InfoRow
              label="ประเภทโปรโมชัน"
              text={format.toThaiPromotionType(current.promotion_type)}
            />
            <InfoRow
              label="มูลค่าโปรโมชัน"
              text={`${current.value} ${promotionUnit}`}
            />
            <InfoRow
              label="โปรโมชันลิมิต"
              text={current.promotion_limit || 'ไม่จำกัด'}
            />
            <InfoRow
              label="ประเภทแพ็กเกจ"
              text={`${format.toThaiPackage(
                current.package_type
              )} / ${format.toThaiPackageDuration(current.package_duration)}`}
            />
            <InfoRow
              label="วันที่สร้าง"
              text={format.toThaiDate(current.created_at)}
            />
            <InfoRow
              label="วันที่อัพเดต"
              text={format.toThaiDate(current.updated_at)}
            />
          </InfoContainer>
        </PageView>
      </Loading>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

const InfoContainer = styled.div`
  margin-bottom: 2rem;
`

export default inject('promotion')(observer(PromotionDetail))
