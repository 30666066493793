import React from 'react'
import styled from 'styled-components'
import { Drawer } from 'antd'

import { Row, Col } from 'components/display'
import { Button } from 'components/button'

const DrawerView = (props) => {
  const {
    title,
    width,
    visible,
    disabled,
    loading,
    placement,
    onClose,
    onConfirm,
    children,
    size = 8,
  } = props

  const span = 24 - size * 2
  const footer = (
    <Footer>
      <Row>
        <Col span={span} />
        <Col span={size}>
          <Button text="ยกเลิก" onClick={onClose} />
        </Col>
        <Col span={size}>
          <Button
            text="ตกลง"
            color="primary"
            loading={loading}
            disabled={disabled}
            onClick={onConfirm}
          />
        </Col>
      </Row>
    </Footer>
  )
  return (
    <PageView>
      <Drawer
        title={title}
        width={width}
        visible={visible}
        placement={placement}
        onClose={onClose}
        footer={footer}
      >
        <ContentView>{children}</ContentView>
      </Drawer>
    </PageView>
  )
}

const PageView = styled.div``

const ContentView = styled.div`
  padding: 15px;
`

const Footer = styled.div`
  padding: 2px;
`

export default DrawerView
