import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import AppMenu from 'pages/menu'

const LifeBridge = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <AppMenu name="lifebridge">
      <PageView>
        <Outlet />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  max-width: 830px;
  margin: 0 auto;
  padding: 40px 0;
`

export default LifeBridge
