import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { Text } from 'components/typo'

import Pagination from './Pagination'

const TableTitle = (props) => {
  const { title, page, onChange, onAdd } = props

  const pagination = page ? (
    <Pagination page={page} onChange={onChange} />
  ) : undefined

  const add = onAdd ? (
    <PlusCircleOutlined className="iconsCircle" onClick={onAdd} />
  ) : (
    <></>
  )

  return (
    <PageView>
      <Row justify="start" align="middle">
        <Col lg={8}>
          <Text>
            {title}
            {add}
          </Text>
        </Col>

        <Col lg={16}>{pagination}</Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px;
  margin-bottom: 4px;

  i {
    margin-left: 6px;
    font-size: 24px;
    cursor: pointer;
  }
  .iconsCircle {
    font-size: 40px;
    color: #fdc851;
  }
`

export default TableTitle
