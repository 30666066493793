import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  user: {
    consent_id: undefined,
    ver: '',
    consent_type: 'privacy',
    status: 'active',
    content: '',
  },
}

let state
export class Province extends BaseStore {
  constructor() {
    super()
    this.observable({
      users: {
        filter: {
          status: '',
        },
        pagination: {
          index: 1,
          per_page: 40,
          total: 0,
        },
        list: [],
      },
      detail: cloneDeep(original.user),
    })
    state = this
  }

  async getUserList() {
    const url = `${config.api}/v1/admin/user`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.users.list = list
    })
  }

  async getCountUserList() {
    const url = `${config.api}/v1/admin/user/count`
    const resp = await http.get(url)

    const { counter = 0 } = resp.body
    runInAction(() => {
      state.users.pagination.total = counter
    })
  }
}

export default new Province()
