import React from 'react'
import styled from 'styled-components'

import { format } from 'utils'
import { Row, Col, UserImg } from 'components/display'

const Message = (props) => {
  const { item } = props
  const getName = () => {
    const prefix = item.admin_id ? 'ทีมงาน' : ''
    return `${prefix} ${item.name}`
  }

  return (
    <PageView>
      <Row>
        <Col lg={3}>
          <Img>
            <UserImg img_url={item.img_url} />
          </Img>
        </Col>
        <Col lg={20}>
          <Actor>
            {getName()}
            <span>{format.toDatetime(item.created_at)}</span>
          </Actor>
          <Text>{item.text}</Text>
        </Col>
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 10px 0px;
`

const Img = styled.div`
  text-align: center;
`
const Actor = styled.div`
  color: #a0a3bd;
  span {
    float: right;
  }
`

const Text = styled.div`
  padding: 2px 0px 10px 0px;

  border: 0px solid #d9dbe9;
  border-bottom-width: 1px;
`

export default Message
