import React, { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'

import Text from 'components/typo/Text'
import IconButton from 'components/button/IconButton'
import { Close, Info } from 'icon'
import HelperText from 'components/display/HelperText'
import Tooltip from 'components/Tooltip'

const FieldInput = (props) => {
  const {
    id,
    ref,
    value = '',
    name,
    type,
    startIcon,
    endIcon,
    label,
    placeholder = 'placeholder',
    toolTip = '',
    disabled = false,
    clearable = false,
    readonly = false,
    helperText = '',
    errorText = '',
    successText = '',
    autoFocus = false,
    isNumber = false,
    onClick,
    onChange = () => {},
    admin = false,
  } = props

  const theme = useTheme()
  const inputRef = useRef(ref)
  const [isEnter, setEnter] = useState(false)
  const [hasValue, setHasValue] = useState(false)

  const onFocus = () => {
    inputRef.current.focus()
    if (onClick) onClick()
  }
  const resetValue = () => onChange('', name)
  const onValChange = (e) => {
    let val = e.target.value || ''
    if (isNumber) {
      const num = +val
      val = num ? val : value
    }
    onChange(val, name)
  }

  const styleProps = {
    size: getSize(props),
    color: getColor(
      theme,
      { isEnter, disabled },
      { error: errorText, success: successText }
    ),
  }

  useEffect(() => {
    setHasValue(isEnter || value)
  }, [isEnter, value])

  return (
    <InputWrapper
      onClick={onFocus}
      onFocus={() => setEnter(true)}
      onBlur={() => setEnter(false)}
    >
      <InsideWrapper
        {...styleProps}
        isEnter={isEnter}
        disabled={disabled}
        adminInput={admin}
      >
        {startIcon && <StartIcon>{startIcon}</StartIcon>}
        <ContentWrapper isReadOnly={readonly}>
          <InputLabel hasValue={hasValue} {...styleProps}>
            <Text small={!hasValue} xsmall={hasValue}>
              {label || placeholder}
            </Text>
          </InputLabel>
          <Input
            id={id}
            ref={inputRef}
            type={type}
            autoFocus={autoFocus}
            readOnly={readonly}
            disabled={disabled}
            value={value}
            onChange={onValChange}
          />
        </ContentWrapper>
        {clearable && value && (
          <IconButton onClick={resetValue}>
            <Close />
          </IconButton>
        )}
        {endIcon && <EndIcon>{endIcon}</EndIcon>}
      </InsideWrapper>
      {toolTip && (
        <CustomIconButton>
          <Tooltip text={toolTip}>
            <Info />
          </Tooltip>
        </CustomIconButton>
      )}
      <MessageBox>
        <HelperText
          helperText={helperText}
          successText={successText}
          errorText={errorText}
        />
      </MessageBox>
    </InputWrapper>
  )
}

const getSize = (props) => {
  if (props.small) return { height: '56px', padding: '14px 24px' }
  return { height: '64px', padding: '18px 24px' }
}

const getColor = (theme, states = {}, options = {}) => {
  if (options.error) {
    return {
      border: `2px ${theme.color.error}`,
      bg: theme.color.errorLight,
      label: theme.color.errorDark,
    }
  }

  if (options.success) {
    return {
      border: `2px ${theme.color.success}`,
      bg: theme.color.successLight,
      label: theme.color.successDark,
    }
  }

  return {
    border: states.isEnter
      ? `2px ${theme.color.primary}`
      : `1px ${theme.gray.line}`,
    bg: states.disabled ? theme.gray.inputBackground : 'white',
    label: theme.gray.label,
  }
}

const CustomIconButton = styled(IconButton)`
  position: absolute;
  right: -36px;

  &:hover path {
    fill: ${(props) => props.theme.color.primaryDark};
  }
`

const StartIcon = styled.div`
  display: flex;
  align-items: center;
`

const EndIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Input = styled.input`
  border: none;
  outline: none;
  background: none;

  width: 100%;
  height: 18px;
  line-height: 18px;
  margin-top: 14px;
  padding: 5px 0;
  color: ${(props) => props.theme.color.titleActive};

  &:disabled {
    color: ${(props) => props.theme.gray.placeholder};
  }

  &:read-only {
    pointer-events: none;
  }
`

const InputLabel = styled.div`
  position: absolute !important;
  pointer-events: none;
  transition: all 0.2s;
  color: ${(props) => props.color.label};

  margin-top: ${(props) => (props.hasValue ? '-14px' : '0')};
`

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;

  user-select: ${(p) => p.isReadOnly && 'none'};
`

const InsideWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  transition: all 0.1s;
  border-radius: 16px;
  width: 100%;
  max-height: ${(props) => (props.adminInput ? '57px' : 'unset')};
  max-width: ${(props) => (props.adminInput ? '300px' : 'unset')};

  padding: ${(props) => props.size.padding};
  box-shadow: 0 0 0 ${(props) => props.color.border} inset;
  background-color: ${(props) => props.color.bg};
  opacity: ${(props) => props.disabled && 0.5};
  height: ${(props) => props.size.height};
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  width: 100%;
  column-gap: 18px;
  /* margin-bottom: 24px; */
`

const MessageBox = styled.div`
  position: absolute;
  bottom: -24px;
`

export default FieldInput
