import { Modal as AntdModal, Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import download from 'assets/cloud_download.png'
import success from 'assets/success.png'
import { InfoRow, UserImg } from 'components/display'
import { Dropdown, TextArea } from 'components/input'
import { Link } from 'components/link'
import { Text } from 'components/typo'
import { USER_AGENT_TYPE } from 'definition/support'
import { format, helper, message } from 'utils'

const DOCUMENT_OPTIONS = [
  { label: 'ไม่อนุมัติ', value: 'rejected' },
  { label: 'อนุมัติ', value: 'approved' },
]

function LifeBridgeTeamAgent(props) {
  const { id, agent_id } = useParams()
  const { current: currentTeam = {}, currentAgent = {} } =
    props.life_bridge.toJS()

  const [loading, setLoading] = useState(false)
  const [isOpenModal, setOpenModal] = useState(false)
  const [isOpenUpdateModal, setOpenUpdateModal] = useState(false)
  const [isOpenApprovedModal, setOpenApprovedModal] = useState(false)
  const [formData, setFormData] = useState({
    contract_attachment_status: null,
    id_card_attachment_status: null,
    criminal_record_attachment_status: null,
    contract_attachment_descr: null,
    id_card_attachment_descr: null,
    criminal_record_attachment_descr: null,
  })
  const [field, setField] = useState(null)
  const [description, setDescription] = useState('')

  const canApprove = useMemo(() => {
    return (
      currentAgent.contract_attachment_status === 'approved' &&
      currentAgent.id_card_attachment_status === 'approved' &&
      currentAgent.criminal_record_attachment_status === 'approved'
    )
  }, [currentAgent])

  const onApprove = useCallback(async () => {
    try {
      setLoading(true)
      await props.life_bridge.approveLifeBridgeAgent({
        life_bridge_agent_id: parseInt(agent_id),
      })
      setOpenApprovedModal(true)
    } catch (e) {
      message.error({ message: e.message })
    }
    setLoading(false)
  }, [agent_id, props.life_bridge])

  const onStatusChange = useCallback((name, value) => {
    setFormData((prev) => {
      return { ...prev, [`${name}_attachment_status`]: value }
    })
    setField(name)
    if (value === 'rejected') {
      setOpenModal(true)
    }
  }, [])

  const onDescriptionChange = useCallback((val) => {
    setDescription(val)
  }, [])

  const onCloseModal = useCallback(() => {
    setOpenModal(false)
    setField(null)
    setDescription('')
  }, [])

  const onSubmitDescription = useCallback(() => {
    setFormData((prev) => {
      return { ...prev, [`${field}_attachment_descr`]: description }
    })
    onCloseModal()
  }, [field, description, onCloseModal])

  const getTeamDetail = useCallback(
    async (company_affiliation_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeam(company_affiliation_id)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getAgentDetail = useCallback(
    async (agent_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getLifeBridgeAgent(agent_id)
      } catch (e) {
        message.error({ message: e.message })
      }
      setLoading(false)
    },
    [props.life_bridge, setLoading]
  )

  const onUpdateData = useCallback(async () => {
    try {
      setLoading(true)
      const modifiedValues = {
        life_bridge_agent_id: parseInt(agent_id),
      }
      if (
        formData.contract_attachment_status !==
          currentAgent.contract_attachment_status ||
        formData.contract_attachment_descr !==
          currentAgent.contract_attachment_descr
      ) {
        modifiedValues.contract_attachment_status =
          formData.contract_attachment_status
        if (formData.contract_attachment_status === 'rejected') {
          modifiedValues.contract_attachment_descr =
            formData.contract_attachment_descr
        }
      }
      if (
        formData.id_card_attachment_status !==
          currentAgent.id_card_attachment_status ||
        formData.id_card_attachment_descr !==
          currentAgent.id_card_attachment_descr
      ) {
        modifiedValues.id_card_attachment_status =
          formData.id_card_attachment_status
        if (formData.id_card_attachment_status === 'rejected') {
          modifiedValues.id_card_attachment_descr =
            formData.id_card_attachment_descr
        }
      }
      if (
        formData.criminal_record_attachment_status !==
          currentAgent.criminal_record_attachment_status ||
        formData.criminal_record_attachment_descr !==
          currentAgent.criminal_record_attachment_descr
      ) {
        modifiedValues.criminal_record_attachment_status =
          formData.criminal_record_attachment_status
        if (formData.criminal_record_attachment_status === 'rejected') {
          modifiedValues.criminal_record_attachment_descr =
            formData.criminal_record_attachment_descr
        }
      }
      await props.life_bridge.updateDocumentLifeBridgeAgent(modifiedValues)
      await getAgentDetail(agent_id)
      setOpenUpdateModal(true)
    } catch (error) {
      message.error({
        message: 'เกิดข้อผิดพลาดบางอย่าง',
      })
    } finally {
      setLoading(false)
    }
  }, [agent_id, formData, currentAgent, props.life_bridge, getAgentDetail])

  useEffect(() => {
    getTeamDetail(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    getAgentDetail(agent_id)
  }, [getAgentDetail, agent_id])

  return (
    <>
      <Modal
        width={516}
        visible={isOpenModal}
        onCancel={onCloseModal}
        footer={null}
      >
        <ModalContainer>
          <Text large bold align="center">
            คำแนะนำ
          </Text>
          <Text align="center">ระบุคำแนะนำสำหรับเอกสารที่ไม่อนุมัติ</Text>
          <TextArea
            label="โปรดระบุ"
            rows="2"
            name="description"
            value={description}
            onChange={onDescriptionChange}
          />
          <button
            className="btn btn-primary"
            onClick={onSubmitDescription}
            disabled={!description}
          >
            บันทึก
          </button>
          <button className="btn btn-ghost" onClick={onCloseModal}>
            ไม่ระบุ
          </button>
        </ModalContainer>
      </Modal>
      <Modal
        width={516}
        visible={isOpenUpdateModal}
        onCancel={() => setOpenUpdateModal(false)}
        footer={null}
      >
        <ModalContainer>
          <Text large bold align="center">
            อัปเดตข้อมูลสำเร็จ
          </Text>
          <Text color="var(--fd-greyscale-body-color)" align="center">
            ข้อมูลเอกสารจะถูกส่งไปที่ตัวแทน
          </Text>
          <button
            className="btn btn-primary"
            onClick={() => setOpenUpdateModal(false)}
          >
            เข้าใจแล้ว
          </button>
        </ModalContainer>
      </Modal>
      <Modal
        width={516}
        visible={isOpenApprovedModal}
        onCancel={() => setOpenApprovedModal(false)}
        footer={null}
      >
        <ModalContainer>
          <Text large bold align="center">
            อนุมัติสำเร็จ
          </Text>
          <img src={success} alt="" width={62} height={62} />
          <Text color="var(--fd-greyscale-body-color)" align="center">
            อนุมัติเข้าร่วมโครงการ Life Bridge แล้ว
            <br />
            ข้อมูลจะถูกอัปเดตไปยังตัวแทน
          </Text>
        </ModalContainer>
      </Modal>
      <Row>
        <Link
          to={`/life-bridge/${id}`}
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< หน่วย ${currentTeam.company_affiliation_name}`}
        </Link>
      </Row>
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Text small bold align="center">
          ข้อมูลผู้สมัคร
        </Text>
      </Row>
      <Container>
        <AvatarContainer>
          <UserImg size="120px" img_url={null} gender={currentAgent.gender} />
        </AvatarContainer>
        <InfoContainer>
          <InfoRow
            label="ชื่อ - นามสกุล"
            text={currentAgent.life_bridge_agent_name || '-'}
          />
          <InfoRow
            label="หมายเลขโทรศัพท์"
            text={
              currentAgent.mobile
                ? helper.toMobile(currentAgent.mobile, 'xxx-xxx-xxxx')
                : '-'
            }
          />
          <InfoRow label="อีเมล" text={currentAgent.email || '-'} />
          <InfoRow
            label="หมายเลขใบอนุญาต"
            text={
              currentAgent.license_list && currentAgent.license_list.length > 0
                ? currentAgent.license_list.map((l) => {
                    const foundAgentType = USER_AGENT_TYPE[l.license_type]
                    return (
                      <p key={l.license_no} style={{ lineHeight: 1.4 }}>
                        {l.license_type} {l.license_no}
                        <br />
                        <small>{foundAgentType.EN}</small>
                        <br />
                        <small>{foundAgentType.TH}</small>
                        <br />
                        <small>
                          หมดอายุ {format.toShortThaiDate(l.expired_at)}
                        </small>
                      </p>
                    )
                  })
                : '-'
            }
          />
          <InfoRow
            label="แพ็กเกจ"
            text={format.toThaiPackage(currentAgent.package_type)}
          />
        </InfoContainer>
      </Container>
      <DocumentTable>
        {currentAgent.life_bridge_status !== 'success' ? (
          <colgroup>
            <col style={{ width: '474px' }} />
            <col style={{ width: '140px' }} />
            <col style={{ width: '216xpx' }} />
          </colgroup>
        ) : (
          <colgroup>
            <col style={{ width: '690px' }} />
            <col style={{ width: '140px' }} />
          </colgroup>
        )}
        <thead>
          <tr>
            <th>เอกสาร</th>
            <th>วันที่อัปเดต</th>
            {currentAgent.life_bridge_status !== 'success' && <th>สถานะ</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Row gutter={[8, 8]} align="middle">
                <Col>
                  <a
                    href={(currentAgent.contract_attachment || {}).url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                </Col>
                <Col>
                  <Text small>สัญญา</Text>
                  {currentAgent.contract_attachment_status === 'rejected' &&
                    currentAgent.contract_attachment_descr && (
                      <>
                        <br />
                        <Text
                          small
                          color="var(--fd-greyscale-placeholder-color)"
                        >
                          คำแนะนำ: {currentAgent.contract_attachment_descr}
                        </Text>
                      </>
                    )}
                </Col>
              </Row>
            </td>
            <td>
              {helper.isValue(currentAgent.contract_attachment_updated_at)
                ? format.toShortThaiDate(
                    currentAgent.contract_attachment_updated_at
                  )
                : '-'}
            </td>
            {currentAgent.life_bridge_status !== 'success' && (
              <td>
                <Dropdown
                  label=""
                  name="contract_status"
                  placeholder="สถานะ"
                  value={
                    formData.contract_attachment_status ||
                    currentAgent.contract_attachment_status
                  }
                  onChange={(val) => {
                    onStatusChange('contract', val)
                  }}
                  options={DOCUMENT_OPTIONS}
                />
              </td>
            )}
          </tr>
          <tr>
            <td>
              <Row gutter={[8, 8]} align="middle">
                <Col>
                  <a
                    href={(currentAgent.id_card_attachment || {}).url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                </Col>
                <Col>
                  <Text small>สำเนาบัตรประชาชน</Text>
                  {currentAgent.id_card_attachment_status === 'rejected' &&
                    currentAgent.id_card_attachment_descr && (
                      <>
                        <br />
                        <Text
                          small
                          color="var(--fd-greyscale-placeholder-color)"
                        >
                          คำแนะนำ: {currentAgent.id_card_attachment_descr}
                        </Text>
                      </>
                    )}
                </Col>
              </Row>
            </td>
            <td>
              {helper.isValue(currentAgent.id_card_attachment_updated_at)
                ? format.toShortThaiDate(
                    currentAgent.id_card_attachment_updated_at
                  )
                : '-'}
            </td>
            {currentAgent.life_bridge_status !== 'success' && (
              <td>
                <Dropdown
                  label=""
                  name="id_card_status"
                  placeholder="สถานะ"
                  value={
                    formData.id_card_attachment_status ||
                    currentAgent.id_card_attachment_status
                  }
                  onChange={(val) => {
                    onStatusChange('id_card', val)
                  }}
                  options={DOCUMENT_OPTIONS}
                />
              </td>
            )}
          </tr>
          <tr>
            <td>
              <Row gutter={[8, 8]} align="middle">
                <Col>
                  <a
                    href={(currentAgent.criminal_record_attachment || {}).url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ cursor: 'pointer' }}
                    />
                  </a>
                </Col>
                <Col>
                  <Text small>รายงานการตรวจประวัติการอาชญากรรม</Text>
                  {currentAgent.criminal_record_attachment_status ===
                    'rejected' &&
                    currentAgent.criminal_record_attachment_descr && (
                      <>
                        <br />
                        <Text
                          small
                          color="var(--fd-greyscale-placeholder-color)"
                        >
                          คำแนะนำ:{' '}
                          {currentAgent.criminal_record_attachment_descr}
                        </Text>
                      </>
                    )}
                </Col>
              </Row>
            </td>
            <td>
              {helper.isValue(
                currentAgent.criminal_record_attachment_updated_at
              )
                ? format.toShortThaiDate(
                    currentAgent.criminal_record_attachment_updated_at
                  )
                : '-'}
            </td>
            {currentAgent.life_bridge_status !== 'success' && (
              <td>
                <Dropdown
                  label=""
                  name="criminal_record_status"
                  placeholder="สถานะ"
                  value={
                    formData.criminal_record_attachment_status ||
                    currentAgent.criminal_record_attachment_status
                  }
                  onChange={(val) => {
                    onStatusChange('criminal_record', val)
                  }}
                  options={DOCUMENT_OPTIONS}
                />
              </td>
            )}
          </tr>
        </tbody>
      </DocumentTable>
      {currentAgent.life_bridge_status !== 'success' && (
        <Row justify="end" align="middle" gutter={[8, 8]}>
          <Col>
            <button
              className="btn btn-secondary btn-md"
              width="160px"
              type="button"
              onClick={onUpdateData}
              disabled={loading}
            >
              อัปเดตข้อมูล
            </button>
          </Col>
          <Col>
            <button
              className="btn btn-primary btn-md"
              width="160px"
              type="button"
              disabled={loading || !canApprove}
              onClick={onApprove}
            >
              อนุมัติร่วมโครงการ
            </button>
          </Col>
        </Row>
      )}
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 140px auto;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
`

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
`

const InfoContainer = styled.div`
  width: 100%;
  max-width: 586px;
`

const DocumentTable = styled.table`
  width: 100%;
  margin-bottom: 16px;

  th,
  td {
    padding: 16px 16px;
  }

  th {
    background: var(--fd-greyscale-input-bg-color);
    border: 1px solid var(--fd-greyscale-line-color);
    border-left: none;
    color: var(--fd-greyscale-title-color);
    font-weight: bold;
    text-align: left;
  }
  thead > tr:first-child th:first-child,
  thead > tr:first-child th:first-child {
    border-left: 1px solid var(--fd-greyscale-line-color);
  }
  tbody > tr > td {
    background: var(--fd-greyscale-white-color);
    border: 1px solid var(--fd-greyscale-line-color);
    border-left: none;
    border-top: none;
    color: var(--fd-greyscale-body-color);
  }
  tbody > tr td:first-child {
    border-left: 1px solid var(--fd-greyscale-line-color);
  }
`

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 60px;
`

export default inject('life_bridge')(observer(LifeBridgeTeamAgent))
