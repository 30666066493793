import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  privacy: {
    consent_id: undefined,
    ver: '',
    consent_type: 'privacy',
    status: 'active',
    content: '',
  },
  terms: {
    consent_id: undefined,
    ver: '',
    consent_type: 'privacy',
    status: 'active',
    content: '',
  },
}

let state
export class Consent extends BaseStore {
  constructor() {
    super()
    this.observable({
      privacy: cloneDeep(original.privacy),
      terms: cloneDeep(original.terms),
    })
    state = this
  }

  async getPrivacy() {
    const url = `${config.api}/v1/public/consent/privacy`
    const resp = await http.get(url)

    const data = resp.body || {}

    const tmp = data.consent_id ? data : cloneDeep(original.privacy)
    runInAction(() => {
      state.privacy = tmp
    })
  }

  async savePrivacy(json = {}) {
    const url = `${config.api}/v1/admin/consent/privacy`
    await http.put(url, { json })
  }

  async getTerms() {
    const url = `${config.api}/v1/public/consent/terms`
    const resp = await http.get(url)

    const data = resp.body || {}
    const tmp = data.consent_id ? data : cloneDeep(original.privacy)

    runInAction(() => {
      state.terms = tmp
    })
  }

  async saveTerms(json = {}) {
    const url = `${config.api}/v1/admin/consent/terms`
    await http.put(url, { json })
  }

  setPrivacy(doc) {
    runInAction(() => {
      state.privacy = doc
    })
  }

  setTerms(doc) {
    runInAction(() => {
      state.terms = doc
    })
  }
}

export default new Consent()
