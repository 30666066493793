import React from 'react'
/* eslint-disable */
const Logo = (props) => {
  const { size = 1} = props
  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9884 3C9.89061 3 7.89268 3.6 5.99464 4.6L4.69598 3.3C4.29639 2.9 3.69702 2.9 3.29743 3.3C2.89784 3.7 2.89784 4.3 3.29743 4.7L4.39628 5.8C2.89783 7 1.59918 8.5 0.500317 10.3C-0.0990639 11.4 -0.0990639 12.7 0.500317 13.8C3.19753 18.3 7.49309 21 11.9884 21C14.0863 21 16.0842 20.4 17.9822 19.4L19.2809 20.7C19.4807 20.9 19.7804 21 19.9802 21C20.18 21 20.4797 20.9 20.6795 20.7C21.079 20.3 21.079 19.7 20.6795 19.3L19.6805 18.3C21.1789 17.1 22.4776 15.6 23.4766 13.8C24.1758 12.7 24.1758 11.4 23.4766 10.3C20.7794 5.6 16.5837 3 11.9884 3ZM11.9884 19C8.09247 19 4.59608 16.7 2.19856 12.7C1.89887 12.2 1.89887 11.7 2.19856 11.2C3.19753 9.5 4.39629 8.2 5.79485 7.1L8.59196 9.9C8.19237 10.5 7.99257 11.2 7.99257 11.9C7.99257 14.1 9.79071 15.9 11.9884 15.9C12.6877 15.9 13.387 15.7 13.9864 15.3L16.4838 17.8C15.0852 18.6 13.5868 19 11.9884 19ZM13.8865 12.5L11.489 10.1C11.6888 10.1 11.7886 10 11.9884 10C13.0873 10 13.9864 10.9 13.9864 12C13.9864 12.2 13.9864 12.3 13.8865 12.5ZM12.4879 13.9C12.2881 13.9 12.1882 14 11.9884 14C10.8896 14 9.99051 13.1 9.99051 12C9.99051 11.8 9.99051 11.7 10.0904 11.5L12.4879 13.9ZM21.7783 12.7C20.7794 14.4 19.5806 15.7 18.182 16.8L15.3849 14C15.7845 13.4 15.9843 12.7 15.9843 12C15.9843 9.8 14.1862 8 11.9884 8C11.2892 8 10.5899 8.2 9.99051 8.6L7.49309 6.1C8.89164 5.4 10.3901 5 11.9884 5C15.8844 5 19.3808 7.3 21.7783 11.3C22.078 11.7 22.078 12.3 21.7783 12.7Z" fill="#14142B"/>
    </svg>
  )
}

// eslint-enable
export default Logo
