import styled from 'styled-components'

import { Text } from 'components/typo'

const InfoRow = ({ className, label, text, color }) => {
  return (
    <PageView className={className}>
      <Label className="label">
        <Text>{label || '-'}</Text>
      </Label>
      <Info>
        <Text color={color}>{text || '-'}</Text>
      </Info>
    </PageView>
  )
}

const PageView = styled.div`
  display: flex;
  width: 100%;
`

const Label = styled.label`
  width: 50%;
  padding-right: 5px;
`
const Info = styled.div`
  width: 50%;
  padding-left: 5px;
`
export default InfoRow
