import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import FileSaver from 'file-saver'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  user: {
    name: '',
    surname: 'active',
    email: '',
  },
}

let state
export class User extends BaseStore {
  constructor() {
    super()
    this.observable({
      users: {
        filter: {
          status: '',
        },
        pagination: {
          index: 1,
          per_page: 10,
          total: 0,
        },
        list: [],
      },
      detail: cloneDeep(original.user),
    })
    state = this
  }

  async getUserList(params) {
    const { users } = this.toJS()
    const query = new URLSearchParams(params)
    const url = `${config.api}/v1/admin/user${query ? `?${query}` : ''}`
    const resp = await http.get(url)

    const list = resp.body || []
    users.list = list
    users.pagination.index = params.page
    users.pagination.per_page = params.per_page
    runInAction(() => {
      state.users = users
    })
  }

  async getCountUserList(params) {
    const query = new URLSearchParams(params)
    const url = `${config.api}/v1/admin/user/count${query ? `?${query}` : ''}`
    const resp = await http.get(url)

    const { counter = 0 } = resp.body
    runInAction(() => {
      state.users.pagination.total = counter
    })
  }

  async getUserById(id) {
    const url = `${config.api}/v1/admin/user/${id}/detail`
    const resp = await http.get(url)

    const doc = resp.body || {}
    runInAction(() => {
      state.detail = doc
    })
  }

  async getExport() {
    const url = `${config.api}/v1/admin/user/export`
    let option = { responseType: 'arraybuffer' }
    const resp = await http.get(url, option)

    const blob = new Blob([resp.body], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileSaver(blob, `User.xlsx`)
  }
}

export default new User()
