import { Modal } from 'antd'

import store from 'stores'

export class Message {
  async error({ message = 'ขออภัยพบปัญหาบ้างอย่าง' } = {}) {
    store.messager.error({
      message,
    })
  }

  async warning({ message }) {
    store.messager.warning({
      message,
    })
  }

  async success({ message = 'บันทึกสำเร็จ' } = {}) {
    store.messager.success({
      message,
    })
  }

  confirm({ title }, onOk = () => {}) {
    Modal.confirm({
      title,
      content: '',
      onOk,
      onCancel() {},
    })
  }
}

export const message = new Message()
export default message
