import { Row } from 'antd'
import styled from 'styled-components'

import trash from 'assets/trash.png'
import { Pagination, UserImg } from 'components/display'
import { Dropdown } from 'components/input'
import { Text } from 'components/typo'
import { format, helper } from 'utils'

function LifeBridgeTeamEditAgentTable(props) {
  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <table className="table">
        <colgroup>
          <col style={{ width: '42px' }} />
          <col style={{ width: '182px' }} />
          <col style={{ width: '100xpx' }} />
          <col style={{ width: '140xpx' }} />
          <col style={{ width: '90xpx' }} />
          <col style={{ width: '216xpx' }} />
          <col style={{ width: '60xpx' }} />
        </colgroup>
        <thead>
          <tr>
            <th>ที่</th>
            <th>สมาชิก</th>
            <th>สังกัด</th>
            <th>ใบอนุญาต</th>
            <th>แพ็กเกจ</th>
            <th>ทีม</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {(props.data || []).map((row, idx) => {
            const pagination = props.page || {}
            const no =
              (pagination.index ? pagination.index - 1 : 0) *
                (pagination.size || 10) +
              idx +
              1

            return (
              <tr key={row.life_bridge_agent_id}>
                <td>{no || '-'}</td>
                <td>
                  <>
                    <Avatar
                      img_url={row.img_url}
                      size="27px"
                      gender={row.agent?.gender || null}
                    />
                    <Text xsmall>{row.name || '-'}</Text>
                  </>
                </td>
                <td>{row.company_name || '-'}</td>
                <td>
                  {(row.license_list || []).length > 0
                    ? format.toLicenseList(row.license_list)
                    : '-'}
                </td>
                <td>
                  {helper.isValue(row.package_type)
                    ? format.toThaiPackage(row.package_type)
                    : '-'}
                </td>
                <td>
                  <Dropdown
                    label=""
                    name="team"
                    placeholder="เลือกหน่วย"
                    value={row.company_affiliation_id}
                    onChange={(val) => props.onTeamChange(val, row)}
                    options={props.teamOptions || []}
                  />
                </td>
                <td>
                  <TrashIcon
                    src={trash}
                    alt=""
                    onClick={() => {
                      if (props.onDelete) {
                        props.onDelete(row)
                      }
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;

  > table {
    margin-bottom: 1rem;
  }
`

const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

const TrashIcon = styled.img`
  width: 38px;
  height: 38px;
  cursor: pointer;
`

export default LifeBridgeTeamEditAgentTable
