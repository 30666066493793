import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './assets'
import ScrollToTop from 'components/ScrollToTop'
import { theme } from 'definition'
import store from 'stores'

import App from './App'

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
