import React from 'react'
import styled from 'styled-components'

function Card(props) {
  const { label = '', value = '' } = props
  return (
    <Container>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem 2rem;
  width: 100%;
  height: 140px;
  border-radius: 1rem;
  color: var(--fd-warning-dark-color);
  background-color: var(--fd-secondary-color);
`
const Label = styled.div``
const Value = styled.div`
  font-size: 48px;
  line-height: 34px;
  font-weight: bold;
  text-align: center;
`

export default Card
