import { Row, Table } from 'antd'
import styled from 'styled-components'

import { Pagination } from 'components/display'
import { format, helper } from 'utils'

function LifeBridgeTeamHistoryTable(props) {
  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <>
      <StyledTable
        scroll={{ x: 830 }}
        dataSource={props.data}
        className={props.className}
        columns={[
          {
            title: 'ที่',
            dataIndex: 'no',
            key: 'no',
            width: 46,
            render: (_, __, idx) => {
              const pagination = props.page || {}
              const no =
                (pagination.index ? pagination.index - 1 : 0) *
                  (pagination.size || 10) +
                idx +
                1
              return no || '-'
            },
          },
          {
            title: 'การแก้ไข',
            dataIndex: 'details',
            key: 'details',
            width: 572,
            ellipsis: true,
            render: (text) => text || '-',
          },
          {
            title: 'วันที่แกัไข',
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: 124,
            render: (text) =>
              helper.isValue(text) ? format.toShortThaiDate(text) : '-',
          },
        ]}
        loading={props.loading}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </>
  )
}

const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`

export default LifeBridgeTeamHistoryTable
