import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { UserImg } from 'components/display'
import { format } from 'utils'

const UserMenu = (props) => {
  const { member } = props
  const { user } = member.toJS()

  return (
    <Wrapper>
      <Avatar img_url={user.img_url} size="51px" />
      <div>{format.toUsername(user)}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  @media screen and (max-width: 425px) {
    display: none;
  }
`

const Avatar = styled(UserImg)`
  margin-right: 1rem;
`

export default inject('member')(observer(UserMenu))
