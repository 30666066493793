import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { HeaderRow, TableTitle, Col } from 'components/display'
import Loading from 'components/loading'
import AppMenu from 'pages/menu'

import col from './col'

const ProvinceList = (props) => {
  const [loading, setLoading] = useState(false)

  return (
    <AppMenu name="setting.province">
      <PageView>
        <TableTitle title="จังหวัด" />

        <HeaderRow>
          <Col {...col.name}>ชื่อ</Col>
          <Col {...col.seq}>ลำดับ</Col>
          <Col {...col.date}>สร้างเมื่อ</Col>
          <Col {...col.date}>ปรับปรุงเมื่อ</Col>
        </HeaderRow>
        <Loading loading={loading}>{''}</Loading>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('province')(observer(ProvinceList))
