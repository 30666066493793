import { Layout } from 'antd'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SideMenu = (props) => {
  const { name, list, member, children } = props

  const onLogout = () => {
    member.logout(() => window.location.reload())
  }

  return (
    <OuterMenu>
      <MenuContainer>
        <Content>
          {list.map((l, index) => (
            <Topic key={index}>
              {l.link ? (
                <Link to={l.link}>
                  <CustomLink active={name === l.key}>{l.name}</CustomLink>
                </Link>
              ) : (
                <Name topic={l.type === 'topic'}>{l.name}</Name>
              )}
              {l.submenu.map((submenu, jndex) => (
                <div key={jndex}>
                  {submenu.link ? (
                    <Link to={submenu.link}>
                      <CustomLink active={name === submenu.key}>
                        {submenu.name}
                      </CustomLink>
                    </Link>
                  ) : (
                    <Name>{submenu.name}</Name>
                  )}
                </div>
              ))}
            </Topic>
          ))}
          <Logout onClick={onLogout}>ออกจากระบบ</Logout>
        </Content>
      </MenuContainer>
      <Page>{children}</Page>
    </OuterMenu>
  )
}

const OuterMenu = styled(Layout)`
  position: relative;
`

const MenuContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  max-height: calc(100vh - 110px);
  overflow: hidden auto;

  @media print {
    display: none !important;
  }
`

const Topic = styled.div`
  margin-bottom: 1rem;
`

const Name = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 8px 22px;
  margin-bottom: ${(props) => (props.topic ? '0' : '0.5rem')};
  font-weight: ${(props) => (props.topic ? 'bold' : 'normal')};
`

const CustomLink = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 8px 22px;
  margin-bottom: 0.5rem;
  color: ${(props) =>
    props.active ? props.theme.color.secondaryDark : props.theme.gray.body};
  background-color: ${(props) =>
    props.active ? props.theme.color.secondaryBg : 'transparent'};
  border-radius: 0.5rem;
`

const Logout = styled(Name)`
  margin: -1rem 0 0;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  padding: 2rem 0.5rem 1rem;
  background-color: ${(props) => props.theme.gray.offWhite};
  border-radius: 1rem;
`

const Page = styled.div`
  padding: 20px;
  padding-left: 240px;
  width: 100%;
  min-height: 85vh;
  background-color: ${(props) => props.theme.gray.white};
`

export default inject('menu', 'member')(observer(SideMenu))
