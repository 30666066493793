import React from 'react'
import styled from 'styled-components'

import { Row, Col, Status } from 'components/display'

const Header = (props) => {
  const { doc = {} } = props

  const list = [
    { title: 'Issue ID', value: doc.issue_problem_id },
    { title: 'หัวข้อ', value: doc.category_name },
    { title: 'สถานะ', value: <Status status={doc.status} /> },
  ]

  const content = list.map((it, i) => {
    return (
      <Row key={i}>
        ​<Col lg={4}>{it.title}</Col>
        <Col lg={16}>{it.value}</Col>
      </Row>
    )
  })
  return <PageView>{content} </PageView>
}

const PageView = styled.div`
  width: 100%;
`

export default Header
