import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

const Cell = (props) => {
  return (
    <Col {...props}>
      <ContentView>{props.children}</ContentView>
    </Col>
  )
}

const ContentView = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
`
export default Cell
