import admin from './Admin'
import common_problem from './CommonProblem'
import consent from './Consent'
import dashboard from './Dashboard'
import feedback from './Feedback'
import issue_problem from './IssueProblem'
import life_bridge from './LifeBridge'
import member from './Member'
import menu from './Menu'
import messager from './Message'
import promotion from './Promotion'
import province from './Province'
import transactions from './Transactions'
import user from './User'

/* eslint-disable */
export default {
  member,
  menu,
  consent,
  user,
  admin,
  common_problem,
  issue_problem,
  province,
  messager,
  dashboard,
  transactions,
  feedback,
  life_bridge,
  promotion,
}
// eslint-enable
