import { Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'

import { Link } from 'components/link'
import LifeBridgeAgentTable from 'components/table/lifeBridgeAgentTable'
import { Text } from 'components/typo'
import { message } from 'utils'

import EmptyTableMessage from '../components/EmptyTableMessage'

function LifeBridgeNew(props) {
  const { agentList = [], pagination = {} } = props.life_bridge.toJS()

  const [loading, setLoading] = useState(false)

  const getTeamAgents = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeamAgents(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const onTableChange = (page) => {
    getTeamAgents({
      status: 'newcomer',
      page: page.index,
      per_page: page.per_page,
    })
  }

  useEffect(() => {
    getTeamAgents({
      status: 'newcomer',
      page: 1,
      per_page: 10,
    })
  }, [getTeamAgents])

  return (
    <>
      <Row style={{ marginBottom: 8 }}>
        <Link
          to="/life-bridge"
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< Life Bridge`}
        </Link>
      </Row>
      <Row justify="center" style={{ marginBottom: 24 }}>
        <Text large bold align="center">
          สมาชิกที่เข้าร่วมใหม่
        </Text>
      </Row>
      {agentList.length > 0 ? (
        <LifeBridgeAgentTable
          data={agentList}
          page={pagination}
          loading={loading}
          onChange={onTableChange}
        />
      ) : (
        <EmptyTableMessage message="ยังไม่มีสมาชิกที่เข้าร่วมใหม่" />
      )}
    </>
  )
}

export default inject('life_bridge')(observer(LifeBridgeNew))
