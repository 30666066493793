import { runInAction } from 'mobx'
import FileSaver from 'file-saver'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

let state

export class Feedback extends BaseStore {
  constructor() {
    super()

    this.observable({
      summary: {},
      comment: [],
      pagination: {
        per_page: 10,
        total: 30,
      },
      hasMore: true,
    })

    state = this
  }
  setHasmore(param) {
    runInAction(() => {
      state.hasMore = param
    })
  }

  async getSummary() {
    const url = `${config.api}/v1/admin/support/rating/summary`

    const resp = await http.get(url)

    runInAction(() => {
      this.summary = resp.body
    })
  }

  async getFeedback(page, per_page) {
    const query = `page=${page}&per_page=${per_page}`
    const url = `${config.api}/v1/admin/support/rating/feedback?${query}`

    const resp = await http.get(url)

    runInAction(() => {
      state.comment = resp.body
    })
  }

  async getReport() {
    const url = `${config.api}/v1/admin/support/rating/feedback/export`

    let option = { responseType: 'arraybuffer' }
    const resp = await http.get(url, option)

    const blob = new Blob([resp.body], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileSaver(blob, `feedback-report.xlsx`)
  }
}

export default new Feedback()
