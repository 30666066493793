import { helper } from 'utils'

const REQUIRED_FIELDS = ['name', 'province_id']

const validate = (values = {}) => {
  const errors = {}
  REQUIRED_FIELDS.forEach((field) => {
    if (helper.isNull(values[field])) {
      errors[field] = 'กรุณากรอกรายละเอียด'
    }
  })

  return errors
}

export default validate
