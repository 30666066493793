import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'

import AppMenu from 'pages/menu'
import { FieldInput } from 'components/input'
import { Text } from 'components/typo'
import { Row } from 'components/display'
import { message } from 'utils'
import TransTable from 'components/table/transactionsTable'

import download from '../../assets/Download.png'

function Transactions(props) {
  const { transactions } = props
  const { Transactionsdata, pagination = {} } = transactions.toJS()

  const [loading, setLoading] = useState(false)
  const [text, setText] = useState('')
  const [searchtxt, setSearchtxt] = useState('')
  const [timeoutId, setTimeoutId] = useState(null)

  const timeout = 1000

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await transactions.getUserProfile()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [transactions])

  useEffect(() => {
    onInit()
  }, [onInit])

  useEffect(() => {
    let txt = searchtxt
    if (txt !== '') {
      onSearch(txt)
    } else {
      onInit()
    }
  }, [searchtxt])

  const handleChange = async (value) => {
    setText(value)

    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        setSearchtxt(value)
      }, timeout)
    )
  }

  const onSearch = useCallback(
    async (txt) => {
      try {
        setLoading(true)
        await transactions.SearchUser(txt)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [transactions]
  )

  return (
    <AppMenu name="transactions">
      <PageView>
        <div style={{ marginTop: '70px' }}>
          <Row align="center">
            <Text large bold align="center">
              ธุรกรรมการเงิน
            </Text>
          </Row>
        </div>

        <ItemsContainer>
          <RowAdmin>
            <FieldInput
              className="input"
              type="text"
              placeholder="ค้นหา"
              value={text}
              onChange={handleChange}
              startIcon={<SearchOutlined className="iconsSearch" />}
              admin
            />
          </RowAdmin>
          <ReportBtn>
            <Text xsmall>รายงานสรุป</Text>
          </ReportBtn>
          <ImgDiv>
            <img src={download} alt="download" />
          </ImgDiv>
        </ItemsContainer>
        <div className="count">
          <Text color="#A0A3BD" xsmall>
            300 รายการ
          </Text>
        </div>

        <TransTable
          data={Transactionsdata}
          onChange={onInit}
          loading={loading}
          page={pagination}
        />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-right: 50px;

  .count {
    display: flex;
    justify-content: flex-end;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 2%;
  align-items: center;
`
const ReportBtn = styled.div`
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #d9dae9;
  cursor: pointer;
  color: #fdca51;
  padding-top: 5px;
  text-align: center;

  width: 120px;
  height: 40px;
  text-decoration: none;
  margin-left: 60%;
`
const ImgDiv = styled.div`
  padding-left: 10px;
`
const RowAdmin = styled.div.attrs({
  className: 'RowAdmin',
})`
  width: 27%;
`

export default inject('transactions')(observer(Transactions))
