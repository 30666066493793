import React from 'react'
import styled from 'styled-components'
import { Modal as MyModal } from 'antd'

import IconButton from 'components/button/IconButton'

const Modal = (props) => {
  const { children, visible, setOpen } = props

  const closeModal = () => setOpen(false)
  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <ConfirmModal
      width={781}
      footer={null}
      visible={visible}
      onCancel={handleCancel}
    >
      <CloseIcon>
        <IconButton onClick={closeModal}></IconButton>
      </CloseIcon>
      {children}
    </ConfirmModal>
  )
}

const CloseIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

const ConfirmModal = styled(MyModal)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    display: flex;
    align-items: center;
    height: 438px;
    justify-content: center;
  }
`

export default Modal
