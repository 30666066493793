import { SearchOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'

import { FieldInput } from 'components/input'
import { Link } from 'components/link'
import LifeBridgeTable from 'components/table/lifeBridgeTable'
import { Text } from 'components/typo'
import { message } from 'utils'

import EmptyTableMessage from '../components/EmptyTableMessage'

const LifeBridgeList = (props) => {
  const { list = [], pagination = {} } = props.life_bridge.toJS()

  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [text, setText] = useState('')
  const [timeout, setTimeoutId] = useState(null)

  const getLifeBridgeList = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.life_bridge.getLifeBridgeList(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const handleChange = (value) => {
    setText(value)
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, 1500)
    )
  }

  const onTableChange = (page) => {
    getLifeBridgeList({
      name: query || null,
      page: page.index,
      per_page: page.per_page,
    })
  }

  useEffect(() => {
    getLifeBridgeList({
      name: query || null,
      page: 1,
      per_page: 10,
    })
  }, [query, getLifeBridgeList])

  return (
    <>
      <Row align="center" style={{ marginBottom: 8 }}>
        <Text large bold align="center">
          Life Bridge
        </Text>
      </Row>
      <Row justify="space-between" align="middle" style={{ marginBottom: 8 }}>
        <Col lg={8}>
          <FieldInput
            className="input"
            type="text"
            placeholder="ค้นหา"
            value={text}
            onChange={handleChange}
            startIcon={<SearchOutlined className="iconsSearch" />}
          />
        </Col>
        <Col>
          <Row align="middle" gutter={[8]}>
            <Col>
              <Link to="/life-bridge/new">
                <button
                  className="btn btn-secondary btn-sm"
                  type="button"
                  data-state={loading ? 'loading' : ''}
                >
                  เข้าร่วมใหม่
                </button>
              </Link>
            </Col>
            <Col>
              <Link to="/life-bridge/create">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  data-state={loading ? 'loading' : ''}
                >
                  + สร้างทีม
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      {list.length > 0 ? (
        <LifeBridgeTable
          data={list}
          page={pagination}
          loading={loading}
          onChange={onTableChange}
        />
      ) : (
        <EmptyTableMessage message="ยังไม่มีหน่วย" />
      )}
    </>
  )
}

export default inject('life_bridge')(observer(LifeBridgeList))
