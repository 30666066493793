import React from 'react'
import styled from 'styled-components'
import { Table, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { UserImg, Pagination, Status } from 'components/display'
import { Text } from 'components/typo'
import { format } from 'utils'

const columns = [
  {
    title: 'หมวด',
    dataIndex: 'category_name',
    key: 'category_name',
    width: '10%',
  },
  {
    title: 'หัวข้อ',
    dataIndex: 'title',
    key: 'title',
    width: '15%',
    render: (text) => text || '-',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: '5%',
    render: (text) => <Status status={text} />,
  },
  {
    title: 'วันที่สร้าง',
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%',
    render: (text) => format.toDate(text) || '-',
  },
  {
    title: 'อัพเดทล่าสุด',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: '10%',
    render: (text) => format.toDate(text) || '-',
  },
]

function CommonIssueProblemTable(props) {
  const navigate = useNavigate()

  const pagination = props.data.length
  return (
    <Container className={props.className}>
      <Row justify="end" lg={24}>
        {pagination} รายการ
      </Row>
      <StyledTable
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(`/common-problem/${record.common_problem_id}/detail`)
            },
          }
        }}
        pagination={false}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`
const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default CommonIssueProblemTable
