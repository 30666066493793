import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import Chart from 'chart.js/auto'

const ChartView = (props) => {
  const [chart, setChart] = useState(undefined)
  const { id, data = {} } = props

  const onRef = useCallback((node) => {
    if (node) {
      set(node)
    }
  }, [])

  const set = (ctx) => {
    const myChart = new Chart(ctx, data)
    setChart(myChart)
  }
  return (
    <PageView>
      <Canvas id={id} ref={onRef} />
    </PageView>
  )
}

const PageView = styled.div`
  padding-right: 4px;
`

const Canvas = styled.canvas`
  width: 100%;
`

export default ChartView
