import { Col, Divider, Row } from 'antd'
import styled from 'styled-components'

import AppMenu from 'pages/menu'

function DesignSystem() {
  return (
    <AppMenu>
      <PageView>
        <Divider orientation="left">Large</Divider>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col span={4}>Initial</Col>
          <Col span={5}>
            <button className="btn btn-primary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-secondary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-ghost">Button</button>
          </Col>
          <Col span={4}>Loading</Col>
          <Col span={5}>
            <button className="btn btn-primary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-secondary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-ghost" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={4}>Disabled</Col>
          <Col span={5}>
            <button className="btn btn-primary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-secondary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-ghost" disabled>
              Button
            </button>
          </Col>
        </Row>
        <Divider orientation="left">Medium</Divider>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col span={4}>Initial</Col>
          <Col span={5}>
            <button className="btn btn-md btn-primary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-secondary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-ghost">Button</button>
          </Col>
          <Col span={4}>Loading</Col>
          <Col span={5}>
            <button className="btn btn-md btn-primary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-secondary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-ghost" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={4}>Disabled</Col>
          <Col span={5}>
            <button className="btn btn-md btn-primary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-secondary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-md btn-ghost" disabled>
              Button
            </button>
          </Col>
        </Row>
        <Divider orientation="left">Small</Divider>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col span={4}>Initial</Col>
          <Col span={5}>
            <button className="btn btn-sm btn-primary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-secondary">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm">Button</button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-ghost">Button</button>
          </Col>
          <Col span={4}>Loading</Col>
          <Col span={5}>
            <button className="btn btn-sm btn-primary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-secondary" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-ghost" data-state="loading">
              Button
            </button>
          </Col>
          <Col span={4}>Disabled</Col>
          <Col span={5}>
            <button className="btn btn-sm btn-primary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-secondary" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm" disabled>
              Button
            </button>
          </Col>
          <Col span={5}>
            <button className="btn btn-sm btn-ghost" disabled>
              Button
            </button>
          </Col>
        </Row>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.section`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

export default DesignSystem
