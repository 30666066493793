import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoRow } from 'components/display'
import { Link } from 'components/link'
import LifeBridgeTeamHistoryTable from 'components/table/lifeBridgeTeamHistoryTable'
import { Text } from 'components/typo'
import { format, message } from 'utils'

function LifeBridgeTeamHistory(props) {
  const { teamLogs = {}, pagination = {} } = props.life_bridge.toJS()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)

  const getTeamLogs = useCallback(
    async (company_affiliation_id, params) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeamLogs(company_affiliation_id, params)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const onTableChange = useCallback(
    (page) => {
      getTeamLogs(id, {
        page: page.index,
        per_page: page.per_page,
      })
    },
    [id, getTeamLogs]
  )

  useEffect(() => {
    getTeamLogs(id, { page: 1, per_page: 10 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Container>
      <Row>
        <Link
          to={`/life-bridge/${id}`}
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< หน่วย ${teamLogs.company_affiliation_name}`}
        </Link>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row justify="center">
            <Text large bold align="center">
              ประวัติการแก้ไข หน่วย {teamLogs.company_affiliation_name}
            </Text>
          </Row>
        </Col>
        <Col span={24}>
          <StyledInfoRow label="หัวหน้าทีม" text={teamLogs.leader_name} />
          <StyledInfoRow label="จังหวัด" text={teamLogs.province} />
          <StyledInfoRow
            label="จำนวนสมาชิก"
            text={`${teamLogs.members_count || 0} คน`}
          />
          <StyledInfoRow
            label="วันที่สร้างทีม"
            text={format.toThaiDate(teamLogs.created_date)}
          />
        </Col>
        <Col span={24}>
          <LifeBridgeTeamHistoryTable
            loading={loading}
            data={teamLogs.logs_table}
            page={pagination}
            onChange={onTableChange}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default inject('life_bridge')(observer(LifeBridgeTeamHistory))

const Container = styled.div`
  width: 100%;
`

const StyledInfoRow = styled(InfoRow)`
  > label {
    width: 30%;
  }
  > div {
    width: 70%;
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`
