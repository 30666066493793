import React from 'react'
import styled from 'styled-components'
import { Col } from 'antd'

const ColItem = (props) => {
  const { children, isMobile = false, isTablet = false } = props

  let content
  if (isTablet) {
    content = <PageTabletView>{children}</PageTabletView>
  } else if (isMobile) {
    content = <PageMobileView>{children}</PageMobileView>
  } else {
    content = <PageView>{children}</PageView>
  }
  return <Col {...props}>{content}</Col>
}

const PageView = styled.div`
  padding-right: 4px;
`

const PageTabletView = styled.div`
  padding-right: 4px;

  @media screen and (max-width: 800px) {
    padding: 8px 4px 0px 0px;
  }
`

const PageMobileView = styled.div`
  padding-right: 4px;

  @media screen and (max-width: 465px) {
    padding: 8px 4px 0px 0px;
  }
`

export default ColItem
