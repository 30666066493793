export class Errors {
  lunch({ message }) {
    throw Error(message)
  }

  isError(val, { message = 'ขออภัย พบความผิดพลาด' } = {}) {
    if (val === true) {
      throw Error(message)
    }
  }

  isRespError(resp, params = {}) {
    let { message } = params
    if (resp.statusCode === 200) {
      return false
    } else if (resp.statusCode === 401) {
      resp.body = {}
      message = 'กรุณาเข้าสู่ระบบใหม่'
    } else if (resp.statusCode === 404) {
      resp.body = {}
      message = 'ระบบไม่พร้อมใช้งาน'
    }

    const msg = message ? message : resp.body.message

    this.lunch({ message: msg })
    return true
  }

  isNull(val, params = {}) {
    if (val === '' || val === null || val === undefined) {
      let { message = 'ขออภัย พบความผิดพลาด' } = params

      this.lunch({ message })
    }
  }

  isArray(val, params = {}) {
    if (Array.isArray(val) === false) {
      let { message = 'ขออภัย พบความผิดพลาด' } = params

      this.lunch({ message })
    }
  }
}

export const error = new Errors()
export default error
