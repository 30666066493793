import styled from 'styled-components'

import { helper } from 'utils'

const CollapseList = (props) => {
  const { open, setOpen, options, value, onChange = () => {} } = props

  const onSelect = (obj) => {
    onChange(obj.value)
    setOpen(false)
  }

  const onEnter = (e) => {
    e.preventDefault()

    const found = options.find((item) => helper.searchString(item.label, value))
    if (found) {
      onSelect(found)
    }
  }

  return (
    <Wrapper onSubmit={onEnter}>
      {props.children}
      <ListBox isOpen={open}>
        <List>
          {options?.map((item, i) => {
            return (
              <Item
                key={String(item.value) + i}
                isSelect={helper.compareString(item.label, value)}
                isDisabled={item.disabled}
                onClick={() => {
                  if (item.disabled) return
                  onSelect(item)
                }}
              >
                {item.label}
              </Item>
            )
          })}
        </List>
      </ListBox>
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const ListBox = styled.div`
  background-color: white;
  position: absolute;
  white-space: nowrap;

  min-width: 100%;
  padding: 4px 2px 4px 4px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 10;
  border: 1px solid ${(p) => p.theme.gray.background};

  // animation
  transition: 0.2s;
  transform-origin: top;
  transform: scaleY(${(p) => (p.isOpen ? '1' : '0')});
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: white;
  cursor: pointer;

  border: none;
  overflow-y: scroll;
  max-height: 300px;
  width: auto;
  padding: 10px 4px;
  margin: 0;
  row-gap: 10px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background: ${(p) => p.theme.color.primaryBg};
  }
`

const Item = styled.li`
  padding: 8px;
  border-radius: 16px;

  color: ${(p) => p.isSelect && p.theme.color.primary};
  background-color: ${(p) => p.isSelect && p.theme.color.primaryBg};

  &:hover {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.primaryBg};
  }

  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
`

export default CollapseList
