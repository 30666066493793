import React, { useState } from 'react'
import styled from 'styled-components'

import TabButton from 'components/tab/TabButton'

const tabs = [
  {
    label: 'การให้คะแนนและรีวิว',
    value: 'rating',
  },
  {
    label: 'ความคิดเห็นเพิ่มเติม',
    value: 'feedback',
  },
]

const FeedbackTab = (props) => {
  const [selected, setSelect] = useState(tabs[0].value)

  const onClick = (value) => {
    setSelect(value)
    props.onClick(value)
  }

  return (
    <Wrapper>
      {tabs.map((tab) => (
        <TabButton
          key={tab.value}
          onClick={() => onClick(tab.value)}
          isSelected={tab.value === selected}
        >
          {tab.label}
        </TabButton>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: 60px;

  justify-content: center;

  .ant-btn {
    width: 160px;
  }
`

export default FeedbackTab
