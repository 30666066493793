export class Validator {
  checkValue(data = {}, name_list = []) {
    const valid = {}
    const size = name_list.length
    for (let i = 0; i < size; i++) {
      const name = name_list[i]
      const val = data[name]
      const value = typeof val === 'string' ? val.trim() : val

      if (value === null || value === undefined || value === '') {
        valid[name] = true
      }
    }

    return valid
  }

  isEmail(email) {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  isPassword(val = '') {
    const re = new RegExp('^(?=.*[a-z, A-Z])(?=.*[0-9])')
    return re.test(val)
  }
}

export const validator = new Validator()
export default validator
