import React, { useState, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import AppMenu from 'pages/menu'
import Button from 'components/button/Button'
import { UserImg } from 'components/display'
import { FieldInput, Dropdown } from 'components/input'
import { message } from 'utils'

function ProfileEdit(props) {
  const { member } = props
  const { user } = member.toJS()
  const [editUser, setEditUser] = useState(user)
  const [isLoading, setLoading] = useState(user)

  const onChange = (val, name) => {
    setEditUser({ ...editUser, [name]: val })
  }

  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      await member.editUserProfile(editUser)
      message.success({ message: 'บันทึกสำเร็จ' })
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [member, editUser, setLoading])

  return (
    <AppMenu name="profile">
      <Container>
        <ButtonContainer>
          <Button
            maxWidth="117px"
            type="button"
            text="บันทึก"
            onClick={onSave}
          />
        </ButtonContainer>
        <div>
          <Avatar img_url={user.img_url} size="127px" />
        </div>
        <div>
          <Title>โปรไฟล์ของฉัน</Title>
          <Row gutter={[24]}>
            <Col {...col.field}>
              <FieldInput
                label="ชื่อ"
                name="name"
                value={editUser.name}
                onChange={onChange}
              />
            </Col>
            <Col {...col.field}>
              <FieldInput
                label="นามสกุล"
                name="surname"
                value={editUser.surname}
                onChange={onChange}
              />
            </Col>
            <Col {...col.field}>
              <Dropdown
                label="เพศ"
                name="gender"
                value={editUser.gender}
                options={[
                  { label: 'ชาย', value: 'male' },
                  { label: 'หญิง', value: 'female' },
                ]}
                onChange={onChange}
              />
            </Col>
            <Col {...col.field}>
              <FieldInput
                label="วัน เดือน ปีเกิด"
                name="birthday_at"
                value={editUser.birthday_at}
                onChange={onChange}
                // type="date"
              />
            </Col>
            <Col {...col.field}>
              <FieldInput
                label="หมายเลขโทรศัพท์"
                name="mobile"
                value={editUser.mobile}
                onChange={onChange}
              />
            </Col>
            <Col {...col.field}>
              <FieldInput
                label="อีเมล"
                name="email"
                value={editUser.email}
                onChange={onChange}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </AppMenu>
  )
}

const Container = styled.div`
  position: relative;
  padding: 4rem 0;
  width: 100%;
  max-width: 524px;
  margin: 0 auto;
`
const ButtonContainer = styled.div`
  position: absolute;
  top: 4rem;
  right: 0;
  width: 114px;
`
const Avatar = styled(UserImg)`
  display: block;
  margin: 0 auto 2rem;
`
const Title = styled.h1`
  margin-bottom: 2rem;
  font-weight: bold;
  text-align: center;
`

const col = {
  field: { xl: 12, lg: 12, md: 12, xs: 24 },
}

export default inject('member')(observer(ProfileEdit))
