import React from 'react'
import styled from 'styled-components'
import { Table, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { UserImg, Pagination, Status } from 'components/display'

const columns = [
  {
    title: 'Issue ID',
    dataIndex: 'issue_problem_id',
    key: 'issue_problem_id',
    width: 130,
  },
  {
    title: 'หัวข้อ',
    dataIndex: 'category_name',
    key: 'category_name',
    width: 207,
    render: (text) => text || '-',
  },
  {
    title: 'ผู้ส่ง',
    dataIndex: 'email',
    key: 'email',
    width: 144,
    render: (text) => text || '-',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: 160,
    render: (text) => <Status status={text} />,
  },
  {
    title: 'ผู้ดูแล',
    dataIndex: 'company',
    key: 'company',
    width: 189,
    render: (text) => text || '-',
  },
]

function IssueProblemTable(props) {
  const navigate = useNavigate()

  const pagination = props.data.length
  return (
    <Container className={props.className}>
      <Row justify="end" lg={24}>
        {pagination} รายการ
      </Row>
      <Table
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        scroll={{ y: 340 }}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(`/issue-problem/${record.issue_problem_id}/detail`)
            },
          }
        }}
        pagination={false}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const Icon = styled.div`
  display: flex;
  align-items: center;

  span {
    width: 10px;
    height: 10px;
    background-color: white;
    align-items: center;
    border: 3px solid #00ba88;
    border: ${(props) => props.text === 'pending' && '3px solid #00BA88'};
    border: ${(props) => props.text === 'closed' && '3px solid #A0A3BD'};
    border: ${(props) => props.text === 'new' && '3px solid #FDC851'};
    border: ${(props) => props.text === '-' && '3px solid #A0A3BD'};
    border-radius: 50%;
    margin-right: 2px;
  }

  color: ${(props) => props.text === 'pending' && '#00BA88'};
  color: ${(props) => props.text === 'closed' && '#A0A3BD'};
  color: ${(props) => props.text === 'new' && '#FDC851'};
  color: ${(props) => props.text === '-' && '#A0A3BD'};
`
const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default IssueProblemTable
