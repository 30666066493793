import moment from 'moment'
import numeral from 'numeral'

import {
  PAYMENT_TYPE_TH,
  PackageDuration,
  PackageType,
  PromotionTypeTH,
} from 'definition/support'

export class Format {
  isNull(val) {
    if (val === undefined || val === null || val === '') return true
    else return false
  }

  toInt(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0')
  }

  toDigi(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0')
  }

  precision(val) {
    return Math.floor(val * 100) / 100
  }

  toMoney(val) {
    if (this.isNull(val) || isNaN(+val)) return '0'
    else return numeral(val).format('0,0.00')
  }

  toText(val) {
    if (this.isNull(val)) return '-'
    else return val
  }

  toDatetime(val) {
    if (this.isNull(val)) return '-'

    const mm = moment(val)
    const year = mm.year() + 543
    const date = mm.format('DD/MM')
    const time = mm.format('HH:mm')
    return `${date}/${year} เวลา ${time} น.`
  }

  toShortDay(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('DD')
  }

  toShortMonth(val) {
    if (this.isNull(val)) return '-'
    else if (typeof val === 'string') return val.slice(0, 3)
    else return moment(val).format('MM')
  }

  toDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD/MM/YYYY')
  }

  toNameDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('DD MMM YYYY')
  }

  toWeekDate(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('dddd')
  }

  toTime(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).format('HH:mm')
  }

  toTimeAgo(val) {
    if (this.isNull(val)) return '-'
    else return moment(val).fromNow()
  }

  toUsername(user = {}) {
    const name = user.name || ''
    const surname = user.surname || ''
    return `${name} ${surname}`
  }

  limitText(text, size = 100) {
    text = text ? text : ''
    if (text.length <= size) return text

    return text.substr(0, size - 3) + '..'
  }

  upperText(text = '') {
    if (text.length === 0) return text
    return text[0].toUpperCase() + text.substring(1)
  }

  toThaiDate(val) {
    if (this.isNull(val)) return '-'

    const date = moment(val)
    const m = date.month()
    return `${date.date()} ${MONTH[m]} ${date.year() + 543}`
  }

  toShortThaiDate(val) {
    if (this.isNull(val)) return '-'

    const date = moment(val)
    const m = date.month()
    return `${date.date()} ${SHORT_MONTH[m]} ${date.year() + 543}`
  }

  toThaiPackage(p) {
    if (this.isNull(p)) return '-'

    return PackageType[p]
  }

  toThaiPackageDuration(p) {
    if (this.isNull(p)) return '-'

    return PackageDuration[p]
  }

  toThaiPromotionType(p) {
    if (this.isNull(p)) return '-'

    return PromotionTypeTH[p]
  }

  toThaiPaymentType(payment_type) {
    if (this.isNull(payment_type)) return '-'

    return PAYMENT_TYPE_TH[payment_type]
  }

  toLatinNum(num) {
    if (this.isNull(num) || isNaN(+num)) return '0'
    if (num < 1000) return num.toString()
    let cal = (num / 1000).toFixed(2)
    let latNum = cal.toString() + 'K'
    return latNum
  }

  toLicenseList(license_list = []) {
    return license_list.reduce((prev, curr, idx) => {
      if (idx === 0) return curr.license_type
      return `${prev}/${curr.license_type}`
    }, '')
  }
}

const MONTH = {
  0: 'มกราคม',
  1: 'กุมภาพันธ์',
  2: 'มีนาคม',
  3: 'เมษายน',
  4: 'พฤษภาคม',
  5: 'มิถุนายน',
  6: 'กรกฎาคม',
  7: 'สิงหาคม',
  8: 'กันยายน',
  9: 'ตุลาคม',
  10: 'พฤศจิกายน',
  11: 'ธันวาคม',
}
const SHORT_MONTH = {
  0: 'ม.ค.',
  1: 'ก.พ.',
  2: 'ม.ค.',
  3: 'เม.ย.',
  4: 'พ.ค.',
  5: 'มิ.ย.',
  6: 'ก.ค.',
  7: 'ส.ค.',
  8: 'ก.ค.',
  9: 'ต.ค.',
  10: 'พ.ย.',
  11: 'ธ.ค.',
}
export const format = new Format()
export default format
