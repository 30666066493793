import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

import Button from 'components/button/Button'

function DeleteProfie(props) {
  const { onSubmit = () => {} } = props

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = useCallback(() => {
    setIsModalVisible(true)
  }, [setIsModalVisible])

  const handleCancel = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  return (
    <React.Fragment>
      <Link className="label" onClick={() => showModal()}>
        ลบบัญชีผู้ใช้งาน
      </Link>
      <Modal
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <Container>
          <Topic>ลบบัญชีผู้ใช้งาน</Topic>
          <p>
            เมื่อกดยืนยัน บัญชีนี้จะถูกลบออกจากระบบ
            <br />
            คุณจะมีเวลา 30 วันในการกู้คืนบัญชี
          </p>
          <Button
            color="primary"
            maxWidth="117px"
            type="button"
            text="กลับ"
            onClick={() => handleCancel()}
          />
          <Button
            maxWidth="117px"
            type="button"
            text="ยืนยัน"
            onClick={() => onSubmit(() => handleCancel())}
          />
        </Container>
      </Modal>
    </React.Fragment>
  )
}

const Link = styled.div`
  width: fit-content;
  cursor: pointer;
`
const Container = styled.div`
  text-align: center;
  padding: 2rem 0;

  > button {
    margin: 0 auto 1rem;
  }
`
const Topic = styled.h1``

export default DeleteProfie
