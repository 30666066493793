import { Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import CollapseList from 'components/CollapseList'
import { Text } from 'components/typo'
import { message } from 'utils'

function SettingButton(props) {
  const navigate = useNavigate()

  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)

  const toggle = () => {
    setOpen((prev) => !prev)
  }

  const onChange = useCallback(
    (menu) => {
      switch (menu) {
        case 'edit_team':
          navigate(`/life-bridge/${props.companyAffiliationId}/edit`)
          break
        case 'edit_agents_team':
          navigate(`/life-bridge/${props.companyAffiliationId}/edit-agent`)
          break
        case 'commission_team':
          break
        case 'logs_team':
          navigate(`/life-bridge/${props.companyAffiliationId}/history`)
          break
        case 'delete_team':
          if ((props.agentList || []).length > 0) {
            setWarningModalOpen(true)
          } else {
            setConfirmModalOpen(true)
          }
          break
        default:
          break
      }
    },
    [props.agentList, props.companyAffiliationId, navigate]
  )

  const onConfirmDelete = useCallback(async () => {
    try {
      setLoading(true)
      await props.life_bridge.deleteTeam({
        company_affiliation_id: parseInt(props.companyAffiliationId),
      })
      setSuccessModalOpen(true)
    } catch (error) {
      message.error({
        message: 'เกิดข้อผิดพลาดบางอย่าง',
      })
    } finally {
      setConfirmModalOpen(false)
      setLoading(false)
    }
  }, [props.companyAffiliationId, props.life_bridge])

  const onCloseWarningModal = useCallback(() => {
    setWarningModalOpen(false)
  }, [])

  const onCloseConfirmModal = useCallback(() => {
    setConfirmModalOpen(false)
  }, [])

  const onCloseSuccessModal = useCallback(() => {
    setSuccessModalOpen(false)
  }, [])

  return (
    <>
      <Modal
        width={516}
        visible={isWarningModalOpen}
        footer={null}
        closable={false}
      >
        <ModalContainer>
          <Text large bold align="center">
            เกิดความผิดพลาด
          </Text>
          <Text align="center">
            ไม่สามารถลบทีมได้ขณะมีข้อมูลสมาชิกอยู่
            <br />
            เลือกแก้ไขสมาชิกเพื่อย้ายทีมก่อนดำเนินการต่อ
          </Text>
          <button
            className="btn btn-primary btn-md"
            onClick={onCloseWarningModal}
          >
            แก้ไขสมาชิก
          </button>
          <button
            className="btn btn-ghost btn-md"
            onClick={onCloseWarningModal}
          >
            ยกเลิก
          </button>
        </ModalContainer>
      </Modal>
      <Modal
        width={516}
        visible={isConfirmModalOpen}
        footer={null}
        closable={false}
      >
        <ModalContainer>
          <Text large bold align="center">
            ยืนยันการลบทีม
          </Text>
          <Text align="center">
            ทีมจะถูกลบถาวรทันที
            <br />
            ยืนยันที่จะลบ หน่วย {props.companyAffiliationName || '-'}{' '}
            นี้ใช่หรือไม่
          </Text>
          <button className="btn btn-primary btn-md" onClick={onConfirmDelete}>
            ยืนยัน
          </button>
          <button
            className="btn btn-ghost btn-md"
            onClick={onCloseConfirmModal}
          >
            ยกเลิก
          </button>
        </ModalContainer>
      </Modal>
      <Modal
        width={516}
        visible={isSuccessModalOpen}
        footer={null}
        closable={false}
      >
        <ModalContainer>
          <Text large bold align="center">
            ลบทีมแล้ว
          </Text>
          <Text align="center">กำลังดำเนินการต่อ...</Text>
          <button
            className="btn btn-primary btn-md"
            onClick={onCloseSuccessModal}
          >
            เข้าใจแล้ว
          </button>
        </ModalContainer>
      </Modal>

      <CollapseList
        open={isOpen}
        setOpen={setOpen}
        options={[
          {
            label: 'แก้ไขทีม',
            value: 'edit_team',
          },
          {
            label: 'แก้ไขสมาชิก',
            value: 'edit_agents_team',
          },
          {
            label: 'Set Tier Commission',
            value: 'commission_team',
            disabled: true,
          },
          {
            label: 'ประวัติการแก้ไข',
            value: 'logs_team',
          },
          {
            label: 'ลบทีม',
            value: 'delete_team',
          },
        ]}
        onChange={onChange}
      >
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={toggle}
          disabled={loading}
        >
          ตั้งค่า
        </button>
      </CollapseList>
    </>
  )
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 60px;
`

export default inject('life_bridge')(observer(SettingButton))
