import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

import AppMenu from 'pages/menu'
import IssueProblemTable from 'components/table/issueProblemTable'
import { FieldInput } from 'components/input'
import CommonProblemList from 'pages/commonProblem/list'
import { message } from 'utils'

const IssueProblemList = (props) => {
  const [loading, setLoading] = useState(false)
  const { display } = props.issue_problem.toJS()
  const { list = [], pagination } = display
  const [query, setQuery] = useState(null)
  const [text, setText] = useState('')
  const [page, setPage] = useState(0)
  const [timeoutId, setTimeoutId] = useState(null)
  const timeout = 1500

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.issue_problem.getCategoryList(false),
        props.issue_problem.getCountList(false),
      ])
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.issue_problem])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onQuery = useCallback(
    async (val) => {
      try {
        setLoading(true)
        await props.issue_problem.getList(val)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.issue_problem]
  )

  useEffect(() => {
    let values = query
    onQuery(values)
  }, [query, onQuery])

  const handleChange = (value) => {
    setText(value)
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, timeout)
    )
  }

  return (
    <AppMenu name="issue">
      <PageView>
        <HeaderBtn page={page}>
          <div className="issueBtn" onClick={() => setPage(0)}>
            ศูนย์ช่วยเหลือ
            {page === 0 && <span></span>}
          </div>
          <div className="commonIssueBtn" onClick={() => setPage(1)}>
            ปัญหาที่พบบ่อย
            {page === 1 && <span></span>}
          </div>
        </HeaderBtn>

        {page === 0 ? (
          <>
            <ContainerInputField>
              <FieldInput
                className="input_issueProblem"
                type="text"
                placeholder="ค้นหา"
                value={text}
                onChange={handleChange}
                startIcon={<SearchOutlined className="iconsSearch" />}
              />
            </ContainerInputField>
            <IssueProblemTable
              data={list}
              loading={loading}
              onChange={onInit}
              page={pagination}
            />
          </>
        ) : page === 1 ? (
          <CommonProblemList />
        ) : null}
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`
const HeaderBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 34px;
  :hover {
    cursor: pointer;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 142px;
    height: 60px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 16px;
    align-items: center;
  }
  span {
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
  }
  .issueBtn {
    background-color: ${(props) => (props.page === 0 ? '#FFF3D7' : 'unset')};
  }
  .commonIssueBtn {
    background-color: ${(props) => (props.page === 1 ? '#FFF3D7' : 'unset')};
  }
`
const ContainerInputField = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .containerBtn {
    align-items: center;
    display: flex;
  }
  .containerBtn button {
    width: 120px;
    height: 40px;
    margin-bottom: 4px;
    color: #fdc851;
    background-color: transparent;
    border: 2px solid #d9dbe9;
    border-radius: 16px;
    :hover {
      cursor: pointer;
    }
  }
`

export default inject('issue_problem')(observer(IssueProblemList))
