import React from 'react'
import styled from 'styled-components'

const Status = (props) => {
  const { status } = props

  const StatusG = ['active', 'approve', 'pending', 'show', 'success']
  const StatusY = ['new', 'fail', 'wait']
  const StatusGrey = ['inactive', 'closed', 'darft', 'cancel']
  const StatusR = ['reject']

  const objG = {
    ...Object.fromEntries(StatusG.map((key) => [key, '#00BA88'])),
  }
  const objY = {
    ...Object.fromEntries(StatusY.map((key) => [key, '#FDC851'])),
  }
  const objGrey = {
    ...Object.fromEntries(StatusGrey.map((key) => [key, '#A0A3BD'])),
  }

  const objR = {
    ...Object.fromEntries(StatusR.map((key) => [key, '#ED2E7E'])),
  }
  const elmColor = {
    ...objG,
    ...objY,
    ...objGrey,
    ...objR,
  }
  const RealTxt = {
    active: 'ใช้งาน',
    approve: 'อนุมัติ',
    show: 'แสดง',
    pending: 'รอการตอบกลับ',
    success: 'สำเร็จ',
    closed: 'ปิด',
    darft: 'แบบร่าง',
    cancel: 'ยกเลิก',
    new: 'ใหม่',
    fail: 'ล้มเหลว',
    wait: 'รอส่งออก',
    reject: 'ถูกปฏิเสธ',
    inactive: 'ไม่ใช้งาน',
  }

  return (
    <Container>
      <Inline color={elmColor[status] ? elmColor[status] : ''}>
        <Circle color={elmColor[status] ? elmColor[status] : ''} />

        <span>{RealTxt[status] ? RealTxt[status] : status}</span>
      </Inline>
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
`

const Inline = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  span {
    padding-left: 4px;
    font-size: 14px;
    color: ${(props) => (props.color ? props.color : '#000')};
  }
`
const Circle = styled.div`
  border-radius: 50%;
  height: 16px;
  width: 16px;
  border: 4px solid ${(props) => (props.color ? props.color : '#000')};
  padding-top: 2px;
`

export default Status
