import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {}

let state
export class Dashboard extends BaseStore {
  constructor() {
    super()
    this.observable({
      data: {
        issue_problem_count: 0,
        user_count: 0,
      },
      graph: {
        issue_problem: {},
        user: {},
      },
    })
    state = this
  }

  async getDashboard() {
    const url = `${config.api}/v1/admin/dashboard`
    const resp = await http.get(url)

    const body = resp.body || {}
    runInAction(() => {
      state.data = body.data
      state.graph = body.graph
    })
  }
}

export default new Dashboard()
