import axios from 'axios'

let member
const isRespError = (resp, params = {}) => {
  let { message } = params
  if (resp.status === 200) {
    return false
  } else if (resp.status === 401) {
    resp.body = {}
    message = 'กรุณาเข้าสู่ระบบใหม่'

    if (member) member.reset()
  } else if (resp.status === 404) {
    resp.body = {}
    message = 'ระบบไม่พร้อมใช้งาน'
  }

  const msg = message ? message : resp.body.message

  throw Error(msg)
}

export class Http {
  constructor() {
    this.token = undefined
  }

  setToken(auth) {
    this.token = auth
  }

  getToken() {
    return this.token
  }

  setMember(val) {
    member = val
  }

  async process(options) {
    const resp = await axios(options)
    const { status, data } = resp
    return {
      status,
      body: data,
    }
  }

  async setOption(setting, options = {}) {
    const { token = true } = options

    setting.headers = {
      'Content-Type': 'application/json',
    }
    if (options['responseType']) {
      setting.responseType = options.responseType
    }
    if (token && this.token) {
      setting.headers['authorization'] = `Bearer ${this.token}`
    }

    return setting
  }

  async get(url, options = {}) {
    let setting = {
      method: 'get',
      url,
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }

  async post(url, options = {}) {
    let setting = {
      method: 'post',
      url,
      body: options.json || {},
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)
    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }

  async methodPost(url, options) {
    let config = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    }
    const resp = await axios.post(url, options, config)
    return resp
  }

  async methodPut(url, options) {
    let config = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    }
    const resp = await axios.put(url, options, config)
    return resp
  }

  async put(url, options = {}) {
    let setting = {
      method: 'put',
      url,
      body: options.json || {},
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }

  async delete(url, options = {}) {
    let setting = {
      method: 'delete',
      url,
      data: options.json || {},
    }

    setting = await this.setOption(setting, options)
    const resp = await this.process(setting)

    const { check = true } = options
    if (check) isRespError(resp)

    return resp
  }
}

export const http = new Http()
export default http
