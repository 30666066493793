import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Dropdown, FieldInput } from 'components/input'
import { Link } from 'components/link'
import Loading from 'components/loading'
import { Text } from 'components/typo'
import { helper, message } from 'utils'

import validate from './create.validate'

function LifeBridgeTeamCreate(props) {
  const { lifeBridgeAgentOptions = [], provinceOptions = [] } =
    props.life_bridge.toJS()
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: null,
    leader_id: null,
    province_id: null,
  })
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const onChange = (val, name) => {
    setFormData({ ...formData, [name]: val })
    setErrors((prev) => {
      const modifiedErrors = { ...prev }
      delete modifiedErrors[name]
      return modifiedErrors
    })
  }

  const modifiedLifeBridgeAgentOptions = useMemo(() => {
    return lifeBridgeAgentOptions.map((l) => ({
      value: l.life_bridge_agent_id,
      label: `${l.firstname} ${l.lastname}`,
    }))
  }, [lifeBridgeAgentOptions])

  const modifiedProvinceOptions = useMemo(() => {
    return provinceOptions.map((p) => ({
      value: p.province_id,
      label: p.name_th,
    }))
  }, [provinceOptions])

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true)
      setErrors({})
      const errors = validate(formData)
      const isValid = helper.isEmpty(errors)
      if (isValid) {
        const modifiedFormData = { ...formData }
        if (modifiedFormData.leader_id) {
          modifiedFormData.leader_name = modifiedLifeBridgeAgentOptions.find(
            (o) => o.value === modifiedFormData.leader_id
          ).value
        }
        await props.life_bridge.createTeam(formData)
        message.success({ message: 'บันทึกสำเร็จ' })
        navigate('/life-bridge')
      } else {
        setErrors(errors)
      }
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    } finally {
      setIsLoading(false)
    }
  }, [formData, props.life_bridge, modifiedLifeBridgeAgentOptions, navigate])

  const onInit = useCallback(async () => {
    try {
      await props.life_bridge.getProvinceOptions()
      await props.life_bridge.getLifeBridgeAgentOptions({
        page: 1,
        per_page: 999,
      })
    } catch (error) {
      message.error({
        message: 'เกิดข้อผิดพลาดบางอย่าง',
      })
    }
  }, [props.life_bridge])

  useEffect(() => {
    onInit()
  }, [onInit])

  return (
    <>
      <Link
        to="/life-bridge"
        style={{ color: 'var(--fd-secondary-dark-color)' }}
      >
        {`< Life Bridge`}
      </Link>
      <Loading loading={isLoading}>
        <Container>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Row justify="center">
                <Text large bold align="center">
                  สร้างทีม
                </Text>
              </Row>
            </Col>
            <Col span={24}>
              <FieldInput
                label=""
                name="name"
                placeholder="ชื่อทีม"
                value={formData.name}
                onChange={onChange}
                errorText={errors.name}
              />
            </Col>
            <Col span={24}>
              <Dropdown
                label=""
                filterable
                name="leader_id"
                placeholder="หัวหน้าทีม"
                value={formData.leader_id}
                onChange={onChange}
                errorText={errors.leader_id}
                options={modifiedLifeBridgeAgentOptions}
              />
            </Col>
            <Col span={24}>
              <Dropdown
                label=""
                filterable
                name="province_id"
                placeholder="จังหวัด"
                value={formData.province_id}
                onChange={onChange}
                errorText={errors.province_id}
                options={modifiedProvinceOptions}
              />
            </Col>
            <Col span={24}>
              <Row justify="center">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={onSubmit}
                  disabled={!helper.isEmpty(errors)}
                >
                  + สร้างทีม
                </button>
              </Row>
            </Col>
          </Row>
        </Container>
      </Loading>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 325px;
  margin: 0 auto;
`

export default inject('life_bridge')(observer(LifeBridgeTeamCreate))
