import { SearchOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Col, Row, TableTitle } from 'components/display'
import { FieldInput } from 'components/input'
import PromotionTable from 'components/table/promotionTable'
import { Text } from 'components/typo'
import AppMenu from 'pages/menu'
import { helper, message } from 'utils'

const PromotionList = (props) => {
  const { list = [], pagination = {} } = props.promotion.toJS()
  const { index, per_page } = pagination

  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [text, setText] = useState('')
  const [timeout, setTimeoutId] = useState(null)
  const navigate = useNavigate()

  const onInit = useCallback(
    async ({ index, per_page }) => {
      try {
        setLoading(true)

        const params = helper.isValue(query)
          ? { keyword: query, page: index, per_page }
          : { page: index, per_page }

        await props.promotion.getPromotionList(params)
        await props.promotion.getPromotionListCount(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.promotion, query]
  )

  const handleChange = (value) => {
    setText(value)
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, 1500)
    )
  }

  const onAdd = () => {
    navigate('/setting/promotion/create')
  }

  useEffect(() => {
    onInit({ index, per_page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInit])

  return (
    <AppMenu name="promotion">
      <PageView>
        <Row align="center">
          <Text large bold align="center">
            Promotion
          </Text>
        </Row>
        <Row justify="space-between" align="center">
          <Col lg={8}>
            <FieldInput
              className="input"
              type="text"
              placeholder="ค้นหา"
              value={text}
              onChange={handleChange}
              startIcon={<SearchOutlined className="iconsSearch" />}
            />
          </Col>
          <Col>
            <TableTitle onAdd={onAdd} />
          </Col>
        </Row>
        <PromotionTable
          data={list}
          loading={loading}
          onChange={onInit}
          page={pagination}
        />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('promotion')(observer(PromotionList))
