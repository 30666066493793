import React from 'react'
import styled from 'styled-components'
import { Pagination as Pagina } from 'antd'

import { format } from 'utils/format'

const Pagination = (props) => {
  const { text = 'ทั้งหมด', page = {}, onChange = () => {} } = props

  const { index = 1, total = 0, per_page = 1 } = page

  let local_index = index

  const onPage = (val) => {
    if (local_index !== val) {
      onChange({ index: val, per_page })
    }
  }

  const onShowSizeChange = (val, size) => {
    local_index = val
    onChange({ index: val, per_page: size })
  }

  return (
    <PageContent>
      <Pagina
        size="small"
        showTotal={(total) => `${text} ${format.toDigi(total)}`}
        current={index}
        pageSize={per_page}
        total={total}
        onChange={onPage}
        onShowSizeChange={onShowSizeChange}
      />
    </PageContent>
  )
}

const PageContent = styled.div`
  text-align: end;
`

export default Pagination
