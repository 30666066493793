import React from 'react'
/* eslint-disable */

const Logo = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(107 * size)}`
  const height = `${Math.ceil(40 * size)}`
  return (
    <svg width={width} height={height} viewBox="0 0 107 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M10.9066 7.69156C7.73552 7.69156 6.14998 9.28769 6.14998 12.4316V13.3506H9.60934C10.0898 13.3506 10.5222 13.544 10.8586 13.8826C11.1949 14.2212 11.3871 14.6565 11.3871 15.1402C11.3871 15.6238 11.1949 16.0592 10.8586 16.4461C10.5222 16.7847 10.0898 16.9781 9.60934 16.9781H6.14998V29.9407C6.14998 30.1825 6.10193 30.4243 6.00584 30.6178C5.90974 30.8596 5.7656 31.0531 5.62146 31.1982C5.47732 31.3433 5.28514 31.4884 5.0449 31.5852C4.80467 31.6819 4.61248 31.7303 4.3242 31.7303C4.08397 31.7303 3.84374 31.6819 3.6035 31.5852C3.36327 31.4884 3.21913 31.3433 3.02694 31.1982C2.8828 31.0531 2.73866 30.8596 2.64257 30.6178C2.54648 30.376 2.49843 30.1825 2.49843 29.9407V16.9781H1.77773C1.24921 16.9781 0.816794 16.7847 0.480467 16.4461C0.192187 16.1075 0 15.6722 0 15.1402C0 14.6081 0.192187 14.1728 0.528514 13.8342C0.864841 13.4957 1.29726 13.3022 1.82577 13.3022H2.64257V12.3832C2.64257 6.82095 5.42928 4.01562 10.9546 4.01562C11.4351 4.01562 11.8675 4.2091 12.2039 4.54767C12.5402 4.88624 12.7324 5.32155 12.7324 5.80523C12.7324 6.2889 12.5402 6.72421 12.2039 7.11115C11.8675 7.49809 11.4351 7.69156 10.9066 7.69156Z" fill="#5F2EEA"/>
        <path d="M19.2666 14.1724V14.511C20.1314 13.8338 21.0443 13.3018 22.0533 12.9149C23.0623 12.5279 24.1193 12.3828 25.2724 12.3828C25.7529 12.3828 26.3295 12.4312 26.906 12.4795C27.4826 12.5279 28.0111 12.673 28.4916 12.8665C28.972 13.06 29.4044 13.3018 29.6927 13.592C30.029 13.8822 30.1732 14.3175 30.1732 14.8012C30.1732 15.043 30.1251 15.2849 30.029 15.5267C29.933 15.7685 29.7888 15.962 29.6447 16.1071C29.5005 16.2522 29.3083 16.3973 29.0681 16.4941C28.8279 16.5908 28.6357 16.6392 28.3474 16.6392C28.1072 16.6392 27.8669 16.5908 27.5787 16.4457C26.906 16.1071 26.1373 15.962 25.3205 15.962C24.5037 15.962 23.6869 16.1071 22.9662 16.4457C22.2455 16.7843 21.5728 17.1712 21.0443 17.7516C20.5158 18.2837 20.0834 18.9608 19.7471 19.6863C19.4107 20.4118 19.2666 21.2341 19.2666 22.0563V29.9403C19.2666 30.4239 19.0744 30.8592 18.7381 31.1978C18.4017 31.5364 17.9693 31.7299 17.4889 31.7299C17.0084 31.7299 16.576 31.5364 16.1916 31.1978C15.8553 30.8592 15.6631 30.4239 15.6631 29.9403V14.1724C15.6631 13.6887 15.8553 13.2534 16.1916 12.9149C16.5279 12.5763 16.9603 12.3828 17.4889 12.3828C17.9693 12.3828 18.4017 12.5763 18.7381 12.9149C19.0744 13.2534 19.2666 13.6404 19.2666 14.1724Z" fill="#5F2EEA"/>
        <path d="M38.8697 8.89771C38.8697 9.47812 38.6775 10.0102 38.2451 10.3971C37.8127 10.8324 37.3322 11.0259 36.7557 11.0259C36.1791 11.0259 35.6506 10.8324 35.2662 10.3971C34.8338 9.9618 34.6416 9.47812 34.6416 8.89771C34.6416 8.3173 34.8338 7.78525 35.2662 7.39831C35.6986 6.963 36.1791 6.76953 36.7557 6.76953C37.3322 6.76953 37.8607 6.963 38.2451 7.39831C38.6295 7.83362 38.8697 8.3173 38.8697 8.89771ZM34.9299 29.8893V14.1698C34.9299 13.6861 35.1221 13.2508 35.4584 12.8639C35.7947 12.4769 36.2271 12.3318 36.7076 12.3318C37.1881 12.3318 37.6205 12.5253 37.9568 12.8639C38.2932 13.2024 38.4853 13.6377 38.4853 14.1698V29.8893C38.4853 30.3729 38.2932 30.8083 37.9568 31.1952C37.6205 31.5338 37.1881 31.7272 36.7076 31.7272C36.2271 31.7272 35.7947 31.5338 35.4584 31.1952C35.1221 30.8566 34.9299 30.4213 34.9299 29.8893Z" fill="#5F2EEA"/>
        <path d="M53.6203 12.3832C55.8784 12.3832 57.8964 13.1087 59.6261 14.5114V5.85359C59.6261 5.32155 59.8183 4.88624 60.1546 4.54767C60.4909 4.2091 60.9233 4.01562 61.4518 4.01562C61.9323 4.01562 62.3647 4.2091 62.7491 4.54767C63.0854 4.88624 63.2776 5.32155 63.2776 5.85359V22.0084C63.2776 24.717 62.3167 26.9902 60.4429 28.8766C58.521 30.7629 56.2628 31.7303 53.6203 31.7303C50.9777 31.7303 48.7195 30.7629 46.7976 28.8766C44.9238 26.9902 43.9629 24.717 43.9629 22.0084C43.9629 19.3482 44.9238 17.0749 46.7976 15.1402C48.6715 13.3022 50.9296 12.3832 53.6203 12.3832ZM59.6261 22.0567C59.6261 21.2345 59.4819 20.4606 59.1456 19.7351C58.8093 19.0096 58.4249 18.3808 57.8484 17.8004C57.3198 17.2683 56.6472 16.833 55.9265 16.4945C55.2058 16.1559 54.389 16.0108 53.5722 16.0108C52.7074 16.0108 51.9386 16.1559 51.2179 16.4945C50.4972 16.833 49.8726 17.2683 49.2961 17.8004C48.7675 18.3324 48.3351 19.0096 47.9988 19.7351C47.6625 20.4606 47.5183 21.2345 47.5183 22.1051C47.5183 22.9274 47.6625 23.7012 47.9988 24.4751C48.3351 25.2006 48.7195 25.8778 49.2961 26.4098C49.8246 26.9419 50.4492 27.3772 51.2179 27.7157C51.9386 28.0543 52.7554 28.1994 53.5722 28.1994C54.389 28.1994 55.2058 28.0543 55.9265 27.7157C56.6472 27.3772 57.3198 26.9419 57.8484 26.4098C58.3769 25.8778 58.8093 25.2006 59.1456 24.4751C59.4819 23.6529 59.6261 22.879 59.6261 22.0567Z" fill="#5F2EEA"/>
        <path d="M83.3612 29.9393V29.6008C82.4964 30.2779 81.5835 30.81 80.5745 31.1969C79.5655 31.5838 78.5085 31.7289 77.3554 31.7289C74.7128 31.7289 72.4546 30.7616 70.5328 28.8753C68.6589 26.9889 67.698 24.7156 67.698 22.007C67.698 19.3468 68.6589 17.0736 70.5328 15.1388C72.4066 13.2525 74.6648 12.2852 77.3554 12.2852C79.998 12.2852 82.2561 13.2525 84.178 15.1388C86.0518 17.0252 87.0128 19.2985 87.0128 22.007V29.891C87.0128 30.3746 86.8206 30.81 86.4843 31.1485C86.1479 31.4871 85.7155 31.6806 85.235 31.6806C84.7546 31.6806 84.3222 31.4871 83.9378 31.1485C83.5534 30.81 83.3612 30.423 83.3612 29.9393ZM77.3554 15.9611C76.4905 15.9611 75.7218 16.1062 75.0011 16.4448C74.2804 16.7833 73.6558 17.2187 73.0792 17.7507C72.5507 18.2827 72.1183 18.9599 71.782 19.6854C71.4456 20.4109 71.3015 21.1848 71.3015 22.0554C71.3015 22.8777 71.4456 23.6515 71.782 24.4254C72.1183 25.1993 72.5027 25.8281 73.0792 26.3601C73.6077 26.8922 74.2324 27.3275 75.0011 27.6661C75.7218 28.0046 76.5386 28.1497 77.3554 28.1497C78.1722 28.1497 78.989 28.0046 79.7097 27.6661C80.4304 27.3275 81.103 26.8922 81.6315 26.3601C82.1601 25.8281 82.5925 25.1509 82.9288 24.4254C83.2651 23.6999 83.4093 22.926 83.4093 22.1038C83.4093 21.2815 83.2651 20.5077 82.9288 19.7821C82.5925 19.0566 82.2081 18.4278 81.6315 17.8474C81.103 17.3154 80.4304 16.8801 79.7097 16.5415C78.989 16.1546 78.1722 15.9611 77.3554 15.9611Z" fill="#5F2EEA"/>
        <path d="M96.7184 29.9403L90.5684 14.9947C90.4723 14.7528 90.3762 14.4626 90.3762 14.1724C90.3762 13.9306 90.4243 13.6887 90.5204 13.4953C90.6164 13.2534 90.7606 13.06 90.9047 12.9149C91.0489 12.7698 91.2411 12.6247 91.4813 12.5279C91.7215 12.4312 91.9137 12.3828 92.202 12.3828C92.5383 12.3828 92.8746 12.4795 93.211 12.673C93.4993 12.8665 93.7395 13.1567 93.9317 13.5436L98.7363 25.1519L103.541 13.5436C103.685 13.2051 103.877 12.9149 104.118 12.7214C104.358 12.5279 104.742 12.3828 105.223 12.3828C105.463 12.3828 105.703 12.4312 105.943 12.5279C106.184 12.6247 106.328 12.7698 106.52 12.9149C106.712 13.06 106.808 13.2534 106.904 13.4953C107 13.7371 107.048 13.9306 107.048 14.1724C107.048 14.4143 107 14.7045 106.856 14.9947L97.0067 38.8399C96.8625 39.1785 96.6223 39.4687 96.334 39.7105C96.0457 39.9523 95.7094 40.0491 95.325 40.0491C94.7965 40.0491 94.3641 39.8556 94.0278 39.517C93.6914 39.1785 93.4993 38.7432 93.4993 38.2111C93.4993 37.9209 93.5473 37.6791 93.6914 37.4372L96.7184 29.9403Z" fill="#5F2EEA"/>
        <path d="M85.6673 7.73844C85.9555 8.02865 86.0997 8.41559 86.1477 8.8509C86.1477 9.28621 85.9555 9.67315 85.6673 9.96335C85.379 10.2536 85.0426 10.3987 84.6102 10.3987C84.1778 10.3987 83.7454 10.2052 83.409 9.91498C80.0458 6.52925 74.6164 6.52925 71.2532 9.91498C70.9649 10.2052 70.5805 10.3987 70.1961 10.3987C69.7637 10.3987 69.3793 10.2536 69.043 9.96335C68.7067 9.62478 68.5625 9.23784 68.6106 8.80253C68.6106 8.41559 68.8028 8.02865 69.091 7.73844L76.2981 0.483303C76.9227 -0.145476 77.8836 -0.145476 78.5082 0.483303L85.6673 7.73844Z" fill="#FDC851"/>
      </g>
    </svg>
  )
}
// eslint-enable
export default Logo
