import React from 'react'

import FieldInput from 'components/input/FieldInput'
import { Email } from 'icon'

const EmailInput = (props) => {
  return (
    <FieldInput
      {...props}
      id={props.id || 'email'}
      label="อีเมล"
      type="email"
      startIcon={<Email />}
      clearable
    />
  )
}

export default EmailInput
