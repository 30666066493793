import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Button } from 'components/button'
import { Col, Row, UserImg } from 'components/display'
import { FieldInput, PasswordInput } from 'components/input'
import AppMenu from 'pages/menu'
import { message } from 'utils'

const UserDetail = (props) => {
  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()

  const { detail } = props.admin.toJS()

  const onInit = useCallback(
    async (admin_id) => {
      try {
        setInit(true)

        if (admin_id) {
          await props.admin.getUserById(admin_id)
        } else {
          await props.admin.resetUser()
        }
      } catch (e) {
        message.error({ message: e.message })
      }
      setInit(false)
    },
    [props.admin]
  )

  useEffect(() => {
    onInit(id)
  }, [onInit, id])

  const onSave = async () => {
    try {
      setLoading(true)
      await props.admin.saveUser(detail)
      if (id) {
        message.success({ message: 'บันทึกสำเร็จ' })
      } else {
        navigate('/admin')
      }
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  const onChange = (val, name) => {
    detail[name] = val
    props.admin.setUser(detail)
  }

  const password = detail.password || ''
  const disabled = id ? true : false
  const pass = disabled ? undefined : (
    <PasswordInput
      label="รหัสผ่าน"
      name="password"
      value={password}
      onChange={onChange}
      errorText={password ? undefined : 'กรอกรหัสผ่าน'}
    />
  )
  return (
    <AppMenu name="admin">
      <PageView>
        <Row>
          <Col {...col.header} />

          <Col {...col.btn}>
            <Button loading={init || loading} text="บันทึก" onClick={onSave} />
          </Col>
        </Row>

        <Row>
          <Col {...col.img}>
            <ImgArea>
              <UserImg size="80px" img_url={detail.img_url} />
            </ImgArea>
          </Col>
          <Col {...col.text}>
            <FieldInput
              label="url รูปภาพ"
              name="img_url"
              value={detail.img_url}
              onChange={onChange}
            />
          </Col>
        </Row>

        <Row>
          <Col {...col.name}>
            <FieldInput
              label="ชื่อ"
              name="name"
              value={detail.name}
              onChange={onChange}
            />
          </Col>

          <Col {...col.name}>
            <FieldInput
              label="นามสกุล"
              name="surname"
              value={detail.surname}
              onChange={onChange}
            />
          </Col>
        </Row>

        <Row>
          <Col {...col.name}>
            <FieldInput
              label="อีเมล"
              name="email"
              value={detail.email}
              disabled={disabled}
              onChange={onChange}
            />
          </Col>
          <Col {...col.name}>{pass}</Col>
        </Row>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 850px;
`

const ImgArea = styled.div`
  text-align: center;
`

const col = {
  header: { xl: 21, lg: 20, md: 20, xs: 24 },
  btn: { xl: 3, lg: 4, md: 4, xs: 24 },

  name: { xl: 12, lg: 12, md: 12, xs: 24 },
  img: { xl: 6, lg: 6, md: 6, xs: 24 },
  text: { xl: 18, lg: 18, md: 12, xs: 24 },
}

export default inject('admin')(observer(UserDetail))
