import { Row, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Pagination, UserImg } from 'components/display'
import { Text } from 'components/typo'
import { LIFE_BRIDGE_STATE_TH } from 'definition/support'
import { format, helper } from 'utils'

function LifeBridgeAgentTable(props) {
  const navigate = useNavigate()

  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <Row justify="end" style={{ marginBottom: 8 }}>
        <Text xsmall color="#A0A3BD">
          {props.page?.total || '0'} รายการ
        </Text>
      </Row>
      <StyledTable
        scroll={{ x: 830 }}
        dataSource={props.data}
        columns={[
          {
            title: 'ที่',
            dataIndex: 'no',
            key: 'no',
            width: 46,
            render: (_, __, idx) => {
              const pagination = props.page || {}
              const no =
                (pagination.index ? pagination.index - 1 : 0) *
                  (pagination.per_page || 10) +
                idx +
                1
              return no || '-'
            },
          },
          {
            title: 'สมาชิก',
            dataIndex: 'agent',
            key: 'agent',
            width: 160,
            ellipsis: true,
            render: (_, row) =>
              (
                <>
                  <Avatar
                    img_url={row.img_url}
                    size="27px"
                    gender={row.gender || null}
                  />
                  <Text xsmall>{row.name || '-'}</Text>
                </>
              ) || '-',
          },
          {
            title: 'หน่วย',
            dataIndex: 'company_affiliation_name',
            key: 'company_affiliation_name',
            width: 140,
            render: (text) => text || '-',
          },
          {
            title: 'สังกัด',
            dataIndex: 'company_name',
            key: 'company_name',
            width: 100,
            render: (text) => text || '-',
          },
          {
            title: 'ใบอนุญาต',
            dataIndex: 'license_list',
            key: 'license_list',
            width: 140,
            render: (text = []) =>
              text.length > 0 ? text.map((t) => t.license_type).join('/') : '-',
          },
          {
            title: 'วันที่สมัคร',
            dataIndex: 'register_date',
            key: 'register_date',
            width: 124,
            render: (text) =>
              helper.isValue(text) ? format.toShortThaiDate(text) : '-',
          },
          {
            title: 'สถานะเอกสาร',
            dataIndex: 'document_status',
            key: 'document_status',
            width: 124,
            render: (text) => (text ? LIFE_BRIDGE_STATE_TH[text] : '-'),
          },
        ]}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(
                `/life-bridge/${record.company_affiliation_id}/agent/${record.life_bridge_agent_id}`
              )
            },
          }
        }}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`

const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`

const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default LifeBridgeAgentTable
