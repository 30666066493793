import { runInAction } from 'mobx'

import { config } from 'config'
import { helper, http } from 'utils'

import BaseStore from './BaseStore'

let state
export class Promotion extends BaseStore {
  constructor() {
    super()
    this.observable({
      filter: {
        status: '',
      },
      pagination: {
        index: 1,
        per_page: 10,
        total: 0,
      },
      list: [],
      current: {},
    })
    state = this
  }

  async getPromotionList(params) {
    const query = new URLSearchParams(params)
    const url = `${config.api}/v1/admin/promotion${query ? `?${query}` : ''}`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.list = list
      state.pagination = {
        index: params.page,
        per_page: params.per_page,
      }
    })
  }

  async getPromotionListCount(params) {
    const query = new URLSearchParams(params)
    const url = `${config.api}/v1/admin/promotion/count${
      query ? `?${query}` : ''
    }`
    const resp = await http.get(url)

    const { counter = 0 } = resp.body
    runInAction(() => {
      state.pagination.total = counter
    })
  }

  async getPromotion(code) {
    const url = `${config.api}/v1/admin/promotion/${code}`
    const resp = await http.get(url)
    runInAction(() => {
      state.current = resp.body || {}
    })
  }

  async createPromotion(params = {}) {
    const url = `${config.api}/v1/admin/promotion`
    await http.methodPost(url, params)
  }

  async updatePromotion(params = {}) {
    const { code } = params
    if (helper.isNull(code)) {
      throw new Error('promotion code cannot be null')
    }
    const url = `${config.api}/v1/admin/promotion/${code}`
    await http.methodPut(url, params)
  }

  async clearPromotion() {
    runInAction(() => {
      state.current = {}
    })
  }
}

export default new Promotion()
