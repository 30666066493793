const Rate_expect = {
  rate_lv1: 'ไม่เลย',
  rate_lv2: 'น้อย',
  rate_lv3: 'ปานกลาง',
  rate_lv4: 'ดี',
  rate_lv5: 'ดีมาก',
}
const Rate_feeling = {
  rate_lv1: 'ใช้งานยาก ขั้นตอนเยอะ',
  rate_lv2: `ทำงานง่ายขึ้น Feature เยอะ ครอบคลุมการทำงานในที่เดียว`,
}
const Rate_suggest = {
  rate_lv1: 'ไม่สนใจ',
  rate_lv2: 'สนใจ',
}

const Rate_package = {
  rate_lv1: 'ไม่สนใจ',
  rate_lv2: 'สนใจ',
}
export { Rate_expect, Rate_feeling, Rate_suggest, Rate_package }
