import styled from 'styled-components'

import { Text } from 'components/typo'

function EmptyTableMessage({ message = 'ไม่มีข้อมูล', className = '' }) {
  return (
    <EmptyContainer className={className}>
      <Text xsmall color="#A0A3BD">
        {message}
      </Text>
    </EmptyContainer>
  )
}

const EmptyContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  text-align: center;
`

export default EmptyTableMessage
