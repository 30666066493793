import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { HtmlEditor, FieldInput } from 'components/input'

const Detail = (props) => {
  const { privacy = {} } = props.consent.toJS()

  const onChange = (val, name) => {
    privacy[name] = val

    props.consent.setPrivacy(privacy)
  }

  return (
    <PageView>
      <VersionArea>
        <FieldInput
          label="version"
          name="ver"
          width="25%"
          value={privacy.ver}
          onChange={onChange}
        />
      </VersionArea>
      <HtmlEditor
        name="content"
        init={privacy.consent_id}
        value={privacy.content}
        onChange={onChange}
      />
    </PageView>
  )
}

const PageView = styled.div``

const VersionArea = styled.div`
  padding: 10px 0px;
`

export default inject('consent')(observer(Detail))
