import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import Text from 'components/typo/Text'
import IconButton from 'components/button/IconButton'

const Checkbox = (props) => {
  const { children, isDisabled } = props
  const theme = useTheme()
  const [checked, setCheck] = useState(true)

  const color = getColor({ isChecked: checked }, theme)
  const styleProps = { color }

  const toggle = () => {
    setCheck(!checked)
  }

  return (
    <Wrapper {...styleProps} disabled={isDisabled} onClick={toggle}>
      <Box {...styleProps}>{checked && <CheckIcon />}</Box>
      <Text xsmall color={theme.gray.body}>
        {children}
      </Text>
    </Wrapper>
  )
}

const CheckIcon = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1L4.3125 7.75L1.5 4.68182"
        stroke="#F7F7FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const getColor = (props, theme) => {
  const { color, gray } = theme

  if (props.isChecked)
    return {
      bg: color.primary,
      bgHover: color.primaryDark,
      bgFocus: color.primary,
      outlineFocus: color.primaryLight,
      border: 'none',
    }

  return {
    bg: 'none',
    bgHover: gray.placeholder,
    outlineFocus: gray.inputBackground,
    bgFocus: gray.line,
    border: `1px solid ${gray.line}`,
  }
}

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  border-radius: 16px;

  background-color: ${(props) => props.color.bg};
  border: ${(props) => props.color.border};

  &:hover {
    background-color: ${(props) => props.color.bgHover};
  }
`

const Wrapper = styled(IconButton)`
  display: flex;
  justify-self: flex-start;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }

  &:focus ${Box} {
    box-shadow: 0 0 0 6px ${(props) => props.color.outlineFocus};
    background-color: ${(props) => props.color.bgFocus};
  }
`

export default Checkbox
