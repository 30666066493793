import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'

import { Text } from 'components/typo'
import { Row, Col } from 'components/display'
import UserTable from 'components/table/userTable'
import { FieldInput } from 'components/input'
import AppMenu from 'pages/menu'
import { message, helper } from 'utils'

import download from '../../../assets/Download.png'

const MemberList = (props) => {
  const { users } = props.user.toJS()
  const { pagination = {}, list = [] } = users || {}
  const { index, per_page } = pagination

  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [text, setText] = useState('')
  const [timeout, setTimeoutId] = useState(null)

  const onInit = useCallback(
    async ({ index, per_page } = {}) => {
      try {
        setLoading(true)

        const params = helper.isValue(query)
          ? { q: query, page: index, per_page }
          : { page: index, per_page }

        await props.user.getCountUserList(params)
        await props.user.getUserList(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.user, query]
  )

  useEffect(() => {
    onInit({ index, per_page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInit])

  const handleChange = (value) => {
    setText(value)
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, 1500)
    )
  }

  const onDownload = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)

      await props.user.getExport()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [timeout])

  return (
    <AppMenu name="user">
      <PageView>
        <Row align="center">
          <Text large bold align="center">
            สมาชิกในระบบ
          </Text>
        </Row>
        <Row justify="space-between" align="center">
          <Col lg={8}>
            <FieldInput
              className="input"
              type="text"
              placeholder="ค้นหา"
              value={text}
              onChange={handleChange}
              startIcon={<SearchOutlined className="iconsSearch" />}
            />
          </Col>
          <Col style={{ alignSelf: 'center' }}>
            <div style={{ cursor: 'pointer' }} onClick={onDownload}>
              <img src={download} alt="download" />
            </div>
          </Col>
        </Row>
        <UserTable
          data={list}
          loading={loading}
          onChange={onInit}
          page={pagination}
        />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;

  > h1 {
    text-align: center;
  }
`

export default inject('user')(observer(MemberList))
