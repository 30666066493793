import styled, { useTheme } from 'styled-components'

import Text from '../typo/Text'
import BaseBtn from './BaseBtn'
import LoadingIcon from './LoadingIcon'

const Button = (props) => {
  const {
    className = '',
    startIcon,
    disabled,
    type,
    loading,
    text,
    onClick,
  } = props
  const theme = useTheme()
  const color = getColor(props, theme)
  const size = getSize(props)

  const styleProps = { size, color }

  if (loading) {
    return (
      <CustomButton {...styleProps}>
        <LoadingIcon loading={true} />
      </CustomButton>
    )
  }

  return (
    <CustomButton
      className={className}
      {...styleProps}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {startIcon && <StartIcon>{startIcon}</StartIcon>}
      <ButtonText>
        <Text small xsmall={props.small} bold>
          {text}
        </Text>
      </ButtonText>
    </CustomButton>
  )
}

const CustomButton = styled(BaseBtn)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`

const StartIcon = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  left: 16px;

  width: 24px;
  height: 24px;
`

const ButtonText = styled.div`
  flex: 1;
`

const getSize = (props) => {
  const { maxWidth, large, medium } = props
  const size = { height: '46px', width: '100%', maxWidth }

  if (large) size.height = '64px'
  else if (medium) size.height = '56px'

  return size
}

const getColor = (props, theme) => {
  const { color: colorType } = props
  const { color, gray } = theme

  if (colorType === 'primary')
    return {
      font: theme.gray.warningDark,
      bg: color.primary,
      bgHover: color.primaryDark,
      focusOutline: color.primaryLight,
    }

  if (colorType === 'secondary')
    return {
      font: color.primary,
      fontHover: color.primaryDark,
      border: color.primary,
      borderHover: color.primaryDark,
      focusOutline: color.primaryLight,
    }

  if (colorType === 'subtle')
    return {
      font: color.primary,
      fontHover: color.primaryDark,
      border: gray.line,
      focusOutline: gray.inputBackground,
    }

  if (colorType === 'text')
    return {
      font: color.primary,
      fontHover: color.primaryDark,
      border: 'none',
      bg: 'transparent',
      focusOutline: gray.inputBackground,
      bgFocus: gray.inputBackground,
    }

  return {
    font: gray.body,
    fontHover: gray.titleActive,
    border: gray.line,
    focusOutline: gray.inputBackground,
  }
}

export default Button
