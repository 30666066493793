import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import Text from 'components/typo/Text'

const Tooltip = (props) => {
  const { show = { v: 'bottom', h: 'right' }, text, children } = props

  const ref = useRef()
  const msgRef = useRef()

  const [horizontal, setHorizontal] = useState({ direction: 'left' })
  const [vertical, setVertical] = useState({ direction: 'bottom' })

  const onHover = () => {
    const maxHeight = window.innerHeight
    const maxWidth = window.innerWidth

    const box = ref.current.getBoundingClientRect()
    const msgBox = msgRef.current.getBoundingClientRect()

    onSetHorizontal({ maxWidth, box, msgBox })
    onSetVertical({ maxHeight, box, msgBox })
  }

  const onSetHorizontal = ({ maxWidth, box, msgBox }) => {
    const isOnRight = maxWidth - box.x < msgBox.width
    const isOnLeft = box.x < msgBox.width
    const hMap = {
      right: { direction: 'right' },
      left: { direction: 'left' },
    }
    let newHorizontal = hMap[show.h]
    if (isOnRight) newHorizontal = hMap.left
    else if (isOnLeft) newHorizontal = hMap.right
    setHorizontal((h) => ({ ...h, ...newHorizontal }))
  }

  const onSetVertical = ({ box, maxHeight, msgBox }) => {
    const isOnTop = box.y < msgBox.height
    const isOnBottom = maxHeight - box.y < msgBox.height
    const vMap = {
      top: { direction: 'top', value: -(10 + msgBox.height) },
      bottom: { direction: 'bottom', value: -(10 + msgBox.height) },
    }

    let newVertical = vMap[show.v]
    if (isOnTop) newVertical = vMap.top
    else if (isOnBottom) newVertical = vMap.bottom
    setVertical((v) => ({ ...v, ...newVertical }))
  }

  return (
    <Wrapper ref={ref} onMouseEnter={onHover}>
      {children}
      <MsgWrapper ref={msgRef} horizontal={horizontal} vertical={vertical}>
        <Text xsmall>{text}</Text>
      </MsgWrapper>
    </Wrapper>
  )
}

const MsgWrapper = styled.div`
  display: none;
  position: absolute;
  white-space: pre-wrap;

  border-radius: 16px;
  padding: 16px 24px;
  z-index: 100;
  width: 335px;

  color: ${(p) => p.theme.gray.offWhite};
  background-color: ${(p) => p.theme.gray.body};
  ${(p) => `${p.vertical.direction}: ${p.vertical.value}px`};
  ${(p) => (p.horizontal.direction === 'left' ? 'right' : 'left')}: -22px;

  &::after {
    content: '';
    position: absolute;
    ${(p) => (p.vertical.direction === 'top' ? 'bottom' : 'top')}: -10px;
    ${(p) => (p.horizontal.direction === 'left' ? 'right' : 'left')}: 24px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid ${(p) => p.theme.gray.body};

    transform: ${(p) =>
      p.vertical.direction === 'top' ? 'rotate(180deg)' : 'rotate(0)'};
  }
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:hover ${MsgWrapper} {
    display: flex;
  }
`

export default Tooltip
