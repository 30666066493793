import { CheckOutlined, FilterOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const { Item } = Menu

function Filter({
  label = '',
  width = 270,
  options = [],
  number = null,
  extendLabel = null,
  selectedValue = null,
  placement = 'bottomRight',
  onSelect = () => {},
}) {
  const [selectedState, setSelectedState] = useState(null)

  const onSelectMenu = (val) => {
    setSelectedState(val)
    onSelect(val)
  }

  useEffect(() => {
    setSelectedState(selectedValue)
    // Only first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dropdown
        placement={placement}
        overlay={
          <StyledMenu style={{ width }}>
            {options.map((opt) => (
              <MenuItem key={opt.value} onClick={() => onSelectMenu(opt.value)}>
                <div>
                  {selectedState === opt.value ? <CheckOutlined /> : null}
                </div>
                <span>{opt.label}</span>
              </MenuItem>
            ))}
          </StyledMenu>
        }
      >
        <ButtonSelector type="button" onClick={(e) => e.preventDefault()}>
          <FilterOutlined /> {label}{' '}
          {number ? (
            <>
              <Badge>{number}</Badge>{' '}
            </>
          ) : (
            ''
          )}
          {extendLabel ? <b>: {extendLabel}</b> : ''}
        </ButtonSelector>
      </Dropdown>
    </>
  )
}

const ButtonSelector = styled.button`
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 10px;
  border: 1px solid rgba(217, 219, 233, 1);
  background-color: white;
  cursor: pointer;
`

const StyledMenu = styled(Menu)`
  padding: 8px 0;
  border-radius: 1rem;
`

const MenuItem = styled(Item)`
  padding: 8px 24px;

  > span {
    display: grid;
    grid-template-columns: 18px auto;
    gap: 8px;
    font-size: 18px;
    line-height: 34px;
  }
`

const Badge = styled.span`
  min-width: 24px;
  padding: 0 0.5rem;
  min-height: 24px;
  text-align: center;
  color: var(--fd-secondary-dark-color);
  font-size: 0.875rem;
  border-radius: 100px;
  background-color: var(--fd-secondary-darkmode-color);
`

export default Filter
