import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'
import { Row, Col } from 'components/display'
import { Button } from 'components/button'
import { Text } from 'components/typo'
import AppMenu from 'pages/menu'

import Detail from './detail'

const ConsentDetail = (props) => {
  const [init, setInit] = useState(false)
  const [loading, setLoading] = useState(false)

  const onInit = useCallback(async () => {
    try {
      setInit(true)

      await props.consent.getPrivacy()
    } catch (e) {
      console.log('err:', e.message)
    }
    setInit(false)
  }, [props.consent])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onSave = async () => {
    try {
      setLoading(true)
      const { privacy = {} } = props.consent.toJS()
      await props.consent.savePrivacy(privacy)
    } catch (e) {
      message.error({
        title: `บันทึกข้อตกลง`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  return (
    <AppMenu name="consent.privacy">
      <PageView>
        <Row>
          <Col {...col.header}>
            <Text>ข้อตกลง Privacy</Text>
          </Col>

          <Col {...col.btn}>
            <Button loading={init || loading} text="บันทึก" onClick={onSave} />
          </Col>
        </Row>

        <Detail />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

const col = {
  header: {
    xl: 21,
    lg: 20,
    md: 20,
    xs: 24,
  },

  btn: {
    xl: 3,
    lg: 4,
    md: 4,
    xs: 24,
  },
}

export default inject('consent')(observer(ConsentDetail))
