import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import styled from 'styled-components'

import LoginImage from 'assets/images/login.png'
import { Button, GoogleBtn } from 'components/button'
import { EmailInput, PasswordInput } from 'components/input'
import { Display, Text } from 'components/typo'

const MainPage = (props) => {
  const [loading, setLoading] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState({})
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onEmail = async (e) => {
    e.preventDefault()
    try {
      setError({})
      setLoading({ email: true })
      setDisabled(true)
      await props.member.loginByEmail({
        email,
        password,
      })
    } catch (e) {
      let errorMsg = e.message

      if (
        [
          'There is no user record corresponding to this identifier.' +
            ' The user may have been deleted.',
          'EMAIL_NOT_FOUND',
        ].includes(e.message)
      ) {
        errorMsg = 'ไม่พบอีเมลของคุณอยู่ในระบบ'
      }

      if (e.code === 'auth/wrong-password') {
        errorMsg = 'อีเมลหรือรหัสผ่านของคุณไม่ถูกต้อง'
      }

      setError({ email: errorMsg })
    }
    setDisabled(false)
    setLoading({})
  }

  const onGoogle = async () => {
    try {
      setLoading({ google: true })
      setDisabled(true)
      await props.member.loginByGoogle()
    } catch (e) {
      console.log('message:', e.message)
    }
    setLoading({})
    setDisabled(false)
  }

  return (
    <PageView>
      <img src={LoginImage} alt="login" />
      <Login>
        <Display bold>เข้าสู่ระบบ</Display>
        <EmailInput
          autoFocus
          errorText={error.email || error.emailValidate}
          value={email}
          onChange={(val) => setEmail(val)}
        />
        <PasswordInput value={password} onChange={(val) => setPassword(val)} />

        <Button
          loading={loading.email}
          disabled={disabled}
          color="primary"
          large
          text="เข้าสู่ระบบ"
          onClick={onEmail}
        />

        <Separator>
          <TextMark>
            <Text>หรือ</Text>
          </TextMark>
        </Separator>

        <LoginOption>
          <GoogleBtn
            loading={loading.google}
            disabled={disabled}
            text="เข้าสู่ระบบผ่าน Gmail"
            onClick={onGoogle}
          />
        </LoginOption>
      </Login>
    </PageView>
  )
}

const Separator = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 0;
    border-top: 1px solid ${(props) => props.theme.gray.line};
    width: 100%;
    transform: translateY(-50%);
  }
`

const TextMark = styled.mark`
  background-color: white;
  padding: 0 20px;
`

const Login = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;

  width: 100%;
  max-width: 325px;
`

const LoginOption = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  row-gap: 24px;

  color: ${(props) => props.theme.gray.body} !important;
`

const PageView = styled.div`
  display: flex;
  justify-content: center;
  padding: 45px 70px;
  column-gap: 120px;
  width: 100%;
  @media screen and (max-width: 465px) {
  }
`

export default inject('member')(observer(MainPage))
