import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Link } from 'components/link'
import { Logo } from 'icon'

import UserMenu from './UserMenu'

const HeaderMenu = (props) => {
  const { member } = props
  const isLogin = member.isLogin()

  return (
    <TopBar>
      <InnerTopBar>
        <Link to="/">
          <LogoSection>
            <Logo />
          </LogoSection>
        </Link>
        {isLogin && <UserMenu />}
      </InnerTopBar>
    </TopBar>
  )
}

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`

const InnerTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 70px;
  width: 100%;
  max-width: 1440px;

  z-index: 20;
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  width: 100%;

  padding: 0 70px;
  position: fixed;

  background-color: ${(props) => props.theme.gray.white};
  border-bottom: 1px solid ${(props) => props.theme.gray.inputBackground};
`

export default inject('member')(observer(HeaderMenu))
