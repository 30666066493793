import React from 'react'
import { inject, observer } from 'mobx-react'

import { format, message } from 'utils'
import { TableRow, Cell } from 'components/display'
import { EditBtn, RemoveBtn } from 'components/button'

import col from './col'

const RowItem = (props) => {
  const { item, onSelect } = props

  const onClick = () => onSelect(item)
  const onItemRemove = async () => {
    try {
      await props.issue_problem.removeCategory(item.issue_problem_category_id)
    } catch (e) {
      let msg = e.message
      if (msg === 'the issue problem category is using in Issue Problem') {
        msg = 'ประเภทของศูนย์ช่วยเหลือ มีการใช้งานอยู่'
      }
      message.error({ message: msg })
    }
  }

  const confirmRemove = () => {
    const title = 'ยืนยันการลบ ประเภทของศูนย์ช่วยเหลือ ใช่หรือไม่?'
    message.confirm({ title }, onItemRemove)
  }

  return (
    <TableRow>
      <Cell {...col.name}>{item.name}</Cell>
      <Cell {...col.seq}>{item.seq || 0}</Cell>
      <Cell {...col.date}>{format.toDate(item.created_at)}</Cell>
      <Cell {...col.date}>{format.toDate(item.updated_at)}</Cell>
      <Cell {...col.btn}>
        <EditBtn onClick={onClick} />
      </Cell>
      <Cell {...col.btn}>
        <RemoveBtn onClick={confirmRemove} />
      </Cell>
    </TableRow>
  )
}

export default inject('issue_problem')(observer(RowItem))
