const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyD94_M7oJfxYMqC_88VcHCWFQckT7Sm-ug',
    authDomain: 'friday-prod.firebaseapp.com',
    projectId: 'friday-prod',
    storageBucket: 'friday-prod.appspot.com',
    messagingSenderId: '980201478494',
    appId: '1:980201478494:web:02704d3e5ffa59e61cd7cf',
    measurementId: 'G-6X43NMXM3G',
  },
  api: 'https://api.friday.in.th/api',
  version: '0.0.1',
}

module.exports = config
