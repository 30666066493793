import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { HtmlEditor, FieldInput } from 'components/input'

const Detail = (props) => {
  const { terms = {} } = props.consent.toJS()

  const onChange = (val, name) => {
    terms[name] = val
    props.consent.setTerms(terms)
  }

  return (
    <PageView>
      <VersionArea>
        <FieldInput
          label="version"
          name="ver"
          width="25%"
          value={terms.ver}
          onChange={onChange}
        />
      </VersionArea>
      <HtmlEditor
        name="content"
        init={terms.consent_id}
        value={terms.content}
        onChange={onChange}
      />
    </PageView>
  )
}

const PageView = styled.div``

const VersionArea = styled.div`
  padding: 10px 0px;
`

export default inject('consent')(observer(Detail))
