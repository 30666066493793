import React from 'react'
import styled from 'styled-components'
import { Table, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { UserImg, Pagination, Status } from 'components/display'
import { Text } from 'components/typo'
import { format } from 'utils'

import sorting from '../../../assets/sorting.png'

const columns = [
  {
    title: () => {
      return (
        <div>
          Transaction ID <img alt="sorting" src={sorting} />
        </div>
      )
    },
    dataIndex: 'id', //must match with the data
    key: 'id', //must match with the data
    width: 20,
    render: (text) => {
      return (
        <div>
          <Text>{text}</Text>
        </div>
      )
    },
  },

  {
    title: () => {
      return (
        <div>
          ชื่อ <img alt="sorting" src={sorting} />
        </div>
      )
    },
    dataIndex: 'login',
    key: 'login',
    width: 160,
    render: (_, row) => {
      //console.log('row data', row.login)
      return (
        (
          <div>
            <Avatar img_url={row.avatar_url} size="27px" />
            <Text>{row.login}</Text>
          </div>
        ) || '-'
      )
    },
  },

  {
    title: () => {
      return (
        <div>
          วันที่ทำธุรกรรม <img alt="sorting" src={sorting} />
        </div>
      )
    },
    dataIndex: 'date',
    key: 'date',
    width: 90,
    render: (date) => {
      return (
        <div>
          <Text> {format.toShortThaiDate(date) || '-'}</Text>
        </div>
      )
    },
  },
  {
    title: () => {
      return (
        <div>
          สถานะของธุรกรรม <img alt="sorting" src={sorting} />
        </div>
      )
    },
    dataIndex: 'status',
    key: 'status',
    width: 50,
    // render: (text) => text || '-',
    render: (status) => {
      return <Status status={status} />
    },
  },
]

function TransTable(props) {
  const navigate = useNavigate()

  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <StyledTable
        scroll={{ x: 300 }}
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(`/transactions/detail/${record.login}`)
            },
          }
        }}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`
const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default TransTable
