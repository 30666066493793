import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'

import { Text } from 'components/typo'
import { format } from 'utils/format'

const columns = [
  {
    title: 'วันที่เลือก',
    dataIndex: 'start_at',
    key: 'start_at',
    width: 190,
    render: (text) => format.toThaiDate(text),
  },
  {
    title: 'แพ็กเกจ',
    dataIndex: 'package_type',
    key: 'package_type',
    render: (text) => format.toThaiPackage(text),
  },
]

function SelectedPackageHistoryTable(props) {
  return (
    <Container className={props.className}>
      <Table
        title={() => <Text color="#A0A3BD">ประวัติการเลือกแพ็กเกจ</Text>}
        dataSource={props.data}
        columns={columns}
        pagination={false}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`

export default SelectedPackageHistoryTable
