import React from 'react'
import styled from 'styled-components'
import { Table, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { UserImg, Pagination, Status } from 'components/display'
import { Text } from 'components/typo'
import { format, helper } from 'utils'

const columns = [
  {
    title: 'User ID',
    dataIndex: 'user_id',
    key: 'user_id',
    width: 130,
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
    width: 240,
    render: (_, row) => {
      return (
        (
          <div>
            <Avatar img_url={row.img_url} size="27px" />
            <Text>{format.toUsername(row)}</Text>
          </div>
        ) || '-'
      )
    },
  },
  {
    title: 'อีเมล',
    dataIndex: 'email',
    key: 'email',
    width: 180,
    render: (text) => text || '-',
  },
  {
    title: 'หมายเลขโทรศัพท์',
    dataIndex: 'mobile',
    key: 'mobile',
    width: 190,
    render: (text) => text || '-',
  },
  {
    title: 'บริษัทต้นสังกัด',
    dataIndex: 'company_name',
    key: 'company_name',
    width: 190,
    render: (text) => text || '-',
  },
  {
    title: 'วันที่ลงทะเบียนเข้าสู่ระบบ',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 240,
    render: (text) => (text ? format.toShortThaiDate(text) : '-'),
  },
  {
    title: 'ระยะเวลาใช้งานจนถึงปัจจุบัน',
    dataIndex: 'air_time',
    key: 'air_time',
    width: 260,
    render: (text) => text || '-',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (text) => (text ? <Status status={text} /> : '-'),
  },
  {
    title: 'แพ็กเกจปัจจุบัน',
    dataIndex: 'package_type',
    key: 'package_type',
    width: 180,
    render: (text) => (text ? format.toThaiPackage(text) : '-'),
  },
  {
    title: 'วันชำระเงิน',
    dataIndex: 'payment_data',
    key: 'payment_data',
    width: 180,
    render: (data) =>
      helper.isValue(data) ? format.toShortThaiDate(data.start_at) : '-',
  },
]

function UserTable(props) {
  const navigate = useNavigate()

  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <StyledTable
        scroll={{ x: 1910 }}
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(`/user/${record.user_id}`)
            },
          }
        }}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`
const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default UserTable
