import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoRow, Row, Status } from 'components/display'
import UserImg from 'components/display/UserImg'
import { Link } from 'components/link'
import Loading from 'components/loading'
import AppMenu from 'pages/menu'
import { format, message } from 'utils'

function TransDetail(props) {
  const { transactions } = props
  const { TransacbyID = {} } = transactions.toJS()
  const { username } = useParams()

  const [loading, setLoading] = useState(false)

  const onInit = useCallback(
    async (username) => {
      try {
        setLoading(true)
        await transactions.getUserbyID(username)
      } catch (e) {
        message.error({ message: e.message })
      }
      setLoading(false)
    },
    [transactions]
  )

  useEffect(() => {
    onInit(username)
  }, [onInit, username])

  return (
    <AppMenu name="transactions">
      <Loading loading={loading}>
        <Row>
          <Link
            to="/transactions"
            style={{
              color: 'var(--fd-secondary-dark-color)',
              fontSize: '16px',
              fontWeight: '550',
            }}
          >
            {`< ธุรกรรมการเงิน`}
          </Link>
        </Row>
        <Container>
          <PageView>
            <ImgContainer>
              {' '}
              <Avatar size="68px" img_url={TransacbyID.avatar_url} />
            </ImgContainer>
            <div></div>

            <InfoContainer>
              <InfoRow label="User ID" text={TransacbyID.id} />
              <InfoRow label="ชื่อ" text={TransacbyID.login} />
              <InfoRow label="นามสกุล" text="จอห์นสัน" />
              <InfoRow label="Transaction ID" text={TransacbyID.node_id} />
              <InfoRow
                label="วันที่ทำธุรกรรม"
                text={format.toShortThaiDate(TransacbyID.date)}
              />
              <InfoRow
                label="สถานะของธุรกรรม"
                text={<Status status={TransacbyID.status} />}
              />
              <InfoRow label="ยอดชำระในธุรกรรม" text="฿300" />
              <InfoRow label="ช่องทางการชำระเงิน" text="บัตรเครดิต" />
            </InfoContainer>
          </PageView>
        </Container>
      </Loading>
    </AppMenu>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  width: 100%;
  max-width: 524px;
  margin: 0 50% 0 30%;
`

const PageView = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
`
const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Avatar = styled(UserImg)`
  margin: 0 auto 2rem;
`
const InfoContainer = styled.div`
  grid-column: 1 / span 2;
  margin-bottom: 2rem;
  row-gap: 20px;
`

export default inject('transactions')(observer(TransDetail))
