import * as admin from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

import { config } from 'config'

const app = admin.initializeApp(config.firebase, 'app')

const auth = () => {
  return getAuth(app)
}

const onAuthStateChanged = () => {
  return new Promise((resolve) => {
    auth().onAuthStateChanged((user) => {
      resolve(user)
    })
  })
}

const firebase = {
  auth,
  onAuthStateChanged,
  GoogleAuthProvider,
}

export { firebase }
