import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  doc: {
    issue_problem_category_id: undefined,
    group_id: undefined,
    user_id: undefined,
    status: 'pending',
    title: '',
    detail: '',
    chat_list: [],
  },
}

let state
export class IssueProblem extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        filter: {
          status: '',
        },
        pagination: {
          index: 1,
          per_page: 25,
          total: 0,
        },
        list: [],
      },
      detail: cloneDeep(original.doc),
      category_list: [],
    })
    state = this
  }

  async getList(val) {
    const { display } = this.toJS()
    let url = `${config.api}/v1/admin/support/issue-problem`
    if (val) {
      url = `${config.api}/v1/admin/support/issue-problem?keywords=${val}`
    }

    const resp = await http.get(url)

    const list = resp.body || []
    display.list = list
    runInAction(() => {
      state.display = display
    })
  }

  async getCountList() {
    const url = `${config.api}/v1/admin/support/issue-problem/count`
    const resp = await http.get(url)

    const { counter } = resp.body || { counter: 0 }
    runInAction(() => {
      state.display.pagination.total = counter
    })
  }

  async saveIssueProblemCategory(doc) {
    const { issue_problem_category_id } = doc

    let newDoc
    if (issue_problem_category_id) {
      const path = 'admin/support/issue-problem/category'
      const url = `${config.api}/v1/${path}/${issue_problem_category_id}`
      const resp = await http.put(url, { json: doc })
      newDoc = resp.body
    } else {
      const url = `${config.api}/v1/admin/support/issue-problem/category`
      const resp = await http.post(url, { json: doc })

      newDoc = resp.body
    }

    const { category_list = [] } = this.toJS()
    const index = category_list.findIndex((it) => {
      return it.issue_problem_category_id === newDoc.issue_problem_category_id
    })

    if (index !== -1) category_list[index] = newDoc
    else category_list.push(newDoc)

    runInAction(() => {
      state.category_list = category_list
    })
  }

  async getCategoryList() {
    const url = `${config.api}/v1/admin/support/issue-problem/category`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.category_list = list
    })
  }

  async getIssueProblem(id) {
    const url = `${config.api}/v1/admin/support/issue-problem/${id}/detail`
    const resp = await http.get(url)

    const detail = resp.body || {}
    runInAction(() => {
      state.detail = detail
    })
  }

  async replyIssueProblem(id, text) {
    const json = {
      text,
      file_list: [],
    }
    const url = `${config.api}/v1/admin/support/issue-problem/${id}/reply`
    const resp = await http.put(url, { json })

    const { detail } = this.toJS()
    const { status, item = {} } = resp.body || {}
    detail.status = status
    detail.chat_list.push(item)
    runInAction(() => {
      state.detail = detail
    })
  }

  async closeIssueProblem(id, text) {
    const url = `${config.api}/v1/admin/support/issue-problem/${id}/close`
    const resp = await http.put(url)

    const { detail } = this.toJS()
    const { status } = resp.body || {}
    detail.status = status
    runInAction(() => {
      state.detail = detail
    })
  }

  async removeCategory(id) {
    const url = `${config.api}/v1/admin/support/issue-problem/category/${id}`
    await http.delete(url)

    const { category_list = [] } = this.toJS()

    const index = category_list.findIndex(
      (it) => it.issue_problem_category_id === id
    )

    category_list.splice(index, 1)
    runInAction(() => {
      state.category_list = category_list
    })
  }
}

export default new IssueProblem()
