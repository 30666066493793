import { Comment as AntdComment, Avatar, Tooltip } from 'antd'

import { format } from 'utils'

const Comment = ({ item = {}, children = null }) => {
  return (
    <AntdComment
      action={null}
      author={
        <a href={`/user/${item.user_id}`}>
          {item.name} {item.surname}
        </a>
      }
      avatar={
        <Avatar src={item.img_url} alt={`${item.name} ${item.surname}`} />
      }
      content={children}
      datetime={
        <Tooltip
          title={format.toNameDate(
            item.updated_at ? item.updated_at : item.created_at
          )}
        >
          <span>
            {format.toTimeAgo(
              item.updated_at ? item.updated_at : item.created_at
            )}
          </span>
        </Tooltip>
      }
    />
  )
}

export default Comment
