import styled from 'styled-components'
import { Button } from 'antd'

const BaseBtn = styled(Button).attrs((p) => ({ htmlType: p.type }))`
  border: none;
  box-shadow: none;
  transition: all 0.2s;

  box-sizing: border-box !important;
  border-radius: 16px !important;

  height: ${(p) => p.size?.height};
  width: ${(p) => p.size?.width};
  max-width: ${(p) => p.size?.maxWidth};

  background: ${(p) => p.color?.bg} !important;
  border: ${(p) => `2px solid ${p.color?.border}`} !important;
  color: ${(p) => p.color?.font} !important;

  &:hover {
    background-color: ${(p) => p.color?.bgHover} !important;
    border: ${(p) => `2px solid ${p.color?.borderHover}`} !important;
    color: ${(p) => p.color?.fontHover} !important;
  }

  &:focus {
    box-sizing: content-box;
    box-shadow: ${(p) => `0 0 0 8px ${p.color?.focusOutline}`};
    background-color: ${(p) => p.color?.bgFocus};
  }

  &:disabled {
    opacity: 0.7;
  }

  &::after {
    display: none;
  }
`

export default BaseBtn
