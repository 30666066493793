import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

const Display = (props) => {
  const theme = useContext(ThemeContext)

  const color = getColor(theme, props)
  const font = getFont(props)

  const styleProps = {
    color,
    font,
  }

  return <CustomDisplay {...styleProps}>{props.children}</CustomDisplay>
}

function getColor(theme, props) {
  if (props.primary) {
    return theme.color.primary
  }

  if (props.secondary) {
    return theme.color.secondary
  }

  return theme.gray.titleActive
}

function getFont(props) {
  const { align, bold, small, large } = props
  const font = {
    weight: 'normal',
    size: '48px',
    height: '50px',
    align,
  }

  if (bold) {
    font.weight = '700'
  }

  if (small) {
    font.size = '32px'
    font.height = '34px'
  } else if (large) {
    font.size = '64px'
    font.height = '66px'
  }

  return font
}

const CustomDisplay = styled.h1`
  margin: 0;

  font-weight: ${(props) => props.font.weight};
  font-size: ${(props) => props.font.size};
  line-height: ${(props) => props.font.height};
  text-align: ${(props) => props.font.align};

  color: ${(props) => props.color};
`

export default Display
