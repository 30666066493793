import { Row } from 'antd'
import styled from 'styled-components'

const RowItem = (props) => {
  const { justify, align, gutter = [] } = props
  return (
    <PageView style={props.style}>
      <Row justify={justify} align={align} gutter={gutter}>
        {props.children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding-bottom: 8px;
`

export default RowItem
