const color = {
  active: '#0047FF',
  bg: 'white',
  font: '#4E4B66',
  border: '#000B8C',
  error: '#FF0000',

  disabled_bg: '#EBEBF6',
  disabled_font: '#999DD1',
}

const theme = {
  color: {
    primary: '#FDC851',
    primaryDark: '#DE9C02',
    primaryDarkMode: '#FEE6AE',
    primaryBg: '#FFF3D7',
    primaryLight: '#FFFCF5',

    secondary: '#FDC851',
    secondaryDark: '#DE9C02',
    secondaryDarkMode: '#FEE6AE',
    secondaryBg: '#FFF3D7',
    secondaryLight: '#FFFCF5',

    success: '#00BA88',
    successDark: '#00966D',
    successDarkMode: '#34EAB9',
    successBg: '#DFFFF6',
    successLight: '#F2FFFB',

    error: '#ED2E7E',
    errorDark: '#C30052',
    errorDarkMode: '#FF84B7',
    errorBg: '#FFDFED',
    errorLight: '#FFF3F8',

    warning: '#F4B740',
    warningDark: '#946200',
    warningDarkMode: '#FFD789',
    warningBg: '#FFF4DF',
    warningLight: '#FFF9EF',
  },
  gray: {
    body: '#4E4B66',
    titleActive: '#14142B',
    inputBackground: '#EFF0F6',
    offWhite: '#F7F7FC',
    placeholder: '#A0A3BD',
    background: '#D9DBE9',
    line: '#D9DBE9',
    label: '#6E7191',
    white: '#FFFFFF',
  },
}

export { color, theme }
