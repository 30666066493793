import styled from 'styled-components'

const Footer = () => {
  return (
    <PageView>
      <Title>
        สงวนลิขสิทธิ์ © {new Date().getFullYear() + 543} บริษัท ทีคิวดี จำกัด
      </Title>
    </PageView>
  )
}

const PageView = styled.div`
  color: black;
  background-color: #fdc851;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  padding: 0 40px;
  color: #946200;
`

export default Footer
