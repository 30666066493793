import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

const HeaderRow = (props) => {
  return (
    <PageView>
      <Row>{props.children}</Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 15px 10px;
  margin: 10px 0px;
  border-radius: 10px;
  color: black;
  background-color: #fdc851;
`

export default HeaderRow
