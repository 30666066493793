import React, {
  useMemo,
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react'
import styled from 'styled-components'
import { Table, Form, Input } from 'antd'
import { inject, observer } from 'mobx-react'
import { DeleteOutlined } from '@ant-design/icons'

import { message } from 'utils'

function CommonIssueCategoryTable(props) {
  const EditableContext = React.createContext(null)
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm()
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    )
  }
  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext)
    useEffect(() => {
      if (editing) {
        inputRef.current.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      setEditing(!editing)
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      })
    }

    const save = async () => {
      try {
        const values = await form.validateFields()
        await props.common_problem.saveCommonProblemCategory(
          values,
          record.common_problem_category_id
        )
      } catch (errInfo) {
        console.log('Save failed:', errInfo)
      }
      toggleEdit()
      window.location.reload()
    }
    const onInit = useCallback(async (val) => {
      if (props.common_problem.add) {
        try {
          props.common_problem.saveCommonProblemCategory(val)
        } catch (e) {
          message.error({
            title: `แสดงรายการ`,
            message: e.message,
          })
        }
        // await props.common_problem.getCategoryList()
        props.common_problem.setAdd()
      }
    }, [])
    useEffect(() => {
      onInit(props.common_problem.add)
    }, [onInit])

    let childNode = children

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      )
    }

    return <td {...restProps}>{childNode}</td>
  }

  const defaultColumns = useMemo(
    () => [
      {
        title: 'หมวด',
        dataIndex: 'name',
        key: 'name',
        width: '90%',
        editable: true,
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        width: '10%',
        render: (commonIssue) => {
          return (
            <div>
              <DeleteOutlined
                onClick={() => {
                  if (props.showModal) {
                    if (commonIssue) {
                      props.showModal(commonIssue)
                    }
                  }
                }}
              />
            </div>
          )
        },
      },
    ],
    [props.showModal, props.data]
  )

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  return (
    <Container className={props.className}>
      <StyledTable
        components={components}
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        pagination={false}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const StyledTable = styled(Table)`
  margin-bottom: 1rem;
  .anticon-delete {
    font-size: 30px;
  }
  .anticon-save {
    padding-left: 10px;
    font-size: 30px;
  }
`

export default inject('common_problem')(observer(CommonIssueCategoryTable))
