import styled from 'styled-components'

import FEMALE_PERSON_IMG from 'assets/female_person.png'
import MALE_PERSON_IMG from 'assets/male_person.png'
import PERSON_IMG from 'assets/person.png'

const AVATAR = {
  male: MALE_PERSON_IMG,
  female: FEMALE_PERSON_IMG,
}

const UserImg = (props) => {
  const { img_url, size = '50px', gender } = props

  const person = AVATAR[gender] || PERSON_IMG

  return (
    <Img
      className={props.className}
      style={{ width: size, height: size }}
      src={img_url || person}
    />
  )
}

const Img = styled.img`
  border-radius: 50%;
  background-color: var(--fd-greyscale-placeholder-color);
  object-fit: cover;
`

export default UserImg
