import { runInAction } from 'mobx'

import { config } from 'config'
import { helper, http } from 'utils'

import BaseStore from './BaseStore'

let state
export class LifeBridge extends BaseStore {
  constructor() {
    super()
    this.observable({
      filter: {
        status: '',
      },
      pagination: {
        index: 1,
        per_page: 10,
        total: 0,
      },
      list: [],
      current: {},
      agentList: [],
      currentAgent: {
        license_list: [],
        contract_attachment: {},
        criminal_record_attachment: {},
        id_card_attachment: {},
      },
      teamLogs: {},
      teamOptions: [],
      provinceOptions: [],
      lifeBridgeAgentOptions: [],
    })
    state = this
  }

  modifyPagination(paging = {}) {
    if (paging.page) {
      return {
        index: paging.page,
        per_page: paging.per_page,
        total: paging.total,
      }
    }
    return this.toJS().pagination
  }

  async getLifeBridgeList(params = {}) {
    const query = new URLSearchParams(helper.filterNullObj(params))
    let url = `${config.api}/v1/admin/lifebridge/company-affiliation/overview${
      query ? `?${query}` : ''
    }`
    const resp = await http.get(url)

    const list = resp.body.data || []
    runInAction(() => {
      state.list = list
      state.pagination = this.modifyPagination(resp.body.paging)
    })
  }

  async getProvinceOptions() {
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/provinces/options`
    const resp = await http.get(url)

    const list = resp.body.options || []
    runInAction(() => {
      state.provinceOptions = list
    })
  }

  async getTeamOptions() {
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/options`
    const resp = await http.get(url)

    const list = (resp.body.options || []).map((opt) => ({
      ...opt,
      label: opt.name,
      value: opt.company_affiliation_id,
    }))
    runInAction(() => {
      state.teamOptions = list
    })
  }

  async createTeam(params) {
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/create`
    await http.methodPost(url, params)
  }

  async getTeam(company_affiliation_id) {
    // eslint-disable-next-line max-len
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/${company_affiliation_id}/details`
    const resp = await http.get(url)

    runInAction(() => {
      state.current = resp.body
    })
  }

  async updateTeam(params) {
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/update`
    await http.methodPut(url, params)
  }

  async deleteTeam(params) {
    const url = `${config.api}/v1/admin/lifebridge/company-affiliation/delete`
    await http.delete(url, {
      json: params,
    })
  }

  async getTeamAgents(params) {
    const query = new URLSearchParams(params)
    const url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/list${
      query ? `?${query}` : ''
    }`
    const resp = await http.get(url)
    runInAction(() => {
      state.agentList = resp.body.data
      state.pagination = this.modifyPagination(resp.body.paging)
    })
  }

  async getTeamLogs(company_affiliation_id, params) {
    const query = new URLSearchParams(params)
    const url = `${
      config.api
    }/v1/admin/lifebridge/logs-company-affiliation/table/${company_affiliation_id}${
      query ? `?${query}` : ''
    }`
    const resp = await http.get(url)
    runInAction(() => {
      state.teamLogs = resp.body.data
      state.pagination = this.modifyPagination(resp.body.data.paging)
    })
  }

  async getLifeBridgeAgentOptions(params) {
    const query = new URLSearchParams(params)
    let url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/options${
      query ? `?${query}` : ''
    }`
    const resp = await http.get(url)

    const list = resp.body.data || []
    runInAction(() => {
      state.lifeBridgeAgentOptions = list
    })
  }

  async getLifeBridgeAgent(agent_id) {
    let url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/individual/${agent_id}`
    const resp = await http.get(url)

    runInAction(() => {
      state.currentAgent = resp.body || {}
    })
  }

  async updateLifeBridgeAgent(params) {
    let url = `${config.api}/v1/admin/lifebridge/company-affiliation/life-bridge-agent/update`
    await http.methodPut(url, params)
  }

  async updateDocumentLifeBridgeAgent(params) {
    let url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/update-document`
    await http.methodPut(url, params)
  }

  async approveLifeBridgeAgent(params) {
    let url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/approve`
    await http.methodPut(url, params)
  }

  async deleteLifeBridgeAgent(params) {
    let url = `${config.api}/v1/admin/lifebridge/company-affiliation/life-bridge-agent/update`
    await http.methodPut(url, { ...params, company_affiliation_id: null })
  }
}

export default new LifeBridge()
