import { Col, Modal, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoRow } from 'components/display'
import { Link } from 'components/link'
import Loading from 'components/loading'
import LifeBridgeTeamEditAgentTable from 'components/table/lifeBridgeTeamEditAgentTable'
import { Text } from 'components/typo'
import { message } from 'utils'

function LifeBridgeTeamEditAgent(props) {
  const {
    current = {},
    agentList = [],
    teamOptions = [],
    pagination = {},
  } = props.life_bridge.toJS()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [selectedAgent, setAgent] = useState({})
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false)
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)

  const getTeamDetail = useCallback(
    async (company_affiliation_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeam(company_affiliation_id)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getTeamAgents = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeamAgents(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getTeamOptions = useCallback(async () => {
    try {
      setLoading(true)
      await props.life_bridge.getTeamOptions()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [props.life_bridge])

  const onTableChange = useCallback(
    async (page) => {
      getTeamAgents({
        page: page.index,
        per_page: page.per_page,
        company_affiliation_id: id,
        status: 'success',
      })
    },
    [id, getTeamAgents]
  )

  const onDeleteAgent = useCallback((agent) => {
    setAgent(agent)
    setConfirmModalOpen(true)
  }, [])

  const onEditAgent = useCallback(
    async (selectedCompany, agent) => {
      try {
        setLoading(true)
        await props.life_bridge.updateLifeBridgeAgent({
          company_affiliation_id: selectedCompany,
          life_bridge_agent_id: agent.life_bridge_agent_id,
          life_bridge_agent_name: agent.name,
        })
        await getTeamAgents({
          page: 1,
          per_page: 10,
          company_affiliation_id: id,
          status: 'success',
        })
      } catch (e) {
        message.error({
          title: `ไม่สามารถทำรายการได้`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [getTeamAgents, id, props.life_bridge]
  )

  const onCloseConfirmModal = useCallback(() => setConfirmModalOpen(false), [])
  const onCloseSuccessModal = useCallback(() => setSuccessModalOpen(false), [])

  const onConfirmDeleteAgent = useCallback(async () => {
    try {
      setLoading(true)
      setConfirmModalOpen(false)
      await props.life_bridge.deleteLifeBridgeAgent({
        life_bridge_agent_id: selectedAgent.life_bridge_agent_id,
        life_bridge_agent_name: selectedAgent.name,
        company_affiliation_id: null,
      })
      await getTeamAgents({
        page: 1,
        per_page: 10,
        company_affiliation_id: id,
        status: 'success',
      })
      setSuccessModalOpen(true)
    } catch (e) {
      message.error({
        title: `ไม่สามารถทำรายการได้`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [id, selectedAgent, props.life_bridge, getTeamAgents])

  useEffect(() => {
    getTeamDetail(id)
    getTeamAgents({
      page: 1,
      per_page: 10,
      company_affiliation_id: id,
      status: 'success',
    })
    getTeamOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <Modal
        width={516}
        visible={isConfirmModalOpen}
        onCancel={onCloseConfirmModal}
        footer={null}
      >
        <ModalContainer>
          <Text large bold align="center">
            ยืนยันการลบสมาชิก
          </Text>
          <Text align="center">
            ยืนยันที่จะลบ {selectedAgent.name || '-'} ใช่หรือไม่
          </Text>
          <button className="btn btn-primary" onClick={onConfirmDeleteAgent}>
            ยืนยัน
          </button>
          <button className="btn btn-ghost" onClick={onCloseConfirmModal}>
            ยกเลิก
          </button>
        </ModalContainer>
      </Modal>
      <Modal
        width={516}
        visible={isSuccessModalOpen}
        footer={null}
        closable={false}
      >
        <ModalContainer>
          <Text large bold align="center">
            ลบสมาชิกทีมแล้ว
          </Text>
          <Text align="center">กำลังดำเนินการต่อ...</Text>
          <button className="btn btn-primary" onClick={onCloseSuccessModal}>
            เข้าใจแล้ว
          </button>
        </ModalContainer>
      </Modal>
      <Container>
        <Row gutter={[24, 24]}>
          <Link
            to={`/life-bridge/${id}`}
            style={{ color: 'var(--fd-secondary-dark-color)' }}
          >
            {`< หน่วย ${current.company_affiliation_name}`}
          </Link>
          <Col span={24}>
            <Row justify="center">
              <Text large bold align="center">
                แก้ไขสมาชิก หน่วย {current.company_affiliation_name}
              </Text>
            </Row>
          </Col>
          <Col span={24}>
            <StyledInfoRow label="หัวหน้าทีม" text={current.leader_name} />
            <div style={{ height: 8 }} />
            <StyledInfoRow label="จังหวัด" text={current.province} />
            <div style={{ height: 8 }} />
            <StyledInfoRow
              label="จำนวนสมาชิก"
              text={`${current.members_count || 0} คน`}
            />
          </Col>
          <Col span={24}>
            {agentList.length > 0 ? (
              <Loading loading={loading}>
                <LifeBridgeTeamEditAgentTable
                  teamId={id}
                  data={agentList}
                  teamOptions={teamOptions}
                  page={pagination}
                  onDelete={onDeleteAgent}
                  onTeamChange={onEditAgent}
                  onChange={onTableChange}
                />
              </Loading>
            ) : (
              <EmptyContainer>
                <Text xsmall color="#A0A3BD">
                  ยังไม่มีสมาชิกหน่วย
                </Text>
              </EmptyContainer>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
`

const StyledInfoRow = styled(InfoRow)`
  > label {
    width: 30%;
  }
  > div {
    width: 70%;
  }
`

const EmptyContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  text-align: center;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 60px;
`

export default inject('life_bridge')(observer(LifeBridgeTeamEditAgent))
