import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import AppMenu from 'pages/menu'
import Loading from 'components/loading'
import { Button } from 'components/button'
import Modal from 'components/Modal'
import { TextArea } from 'components/input'
import { message } from 'utils'

import Header from './Header'
import Message from './Message'

const IssueProblem = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [text, setText] = useState('')
  const { id } = useParams()
  const { detail } = props.issue_problem.toJS()
  const { member } = props
  const { user } = member.toJS()
  const navigate = useNavigate()
  const [isOpened, setOpen] = useState(false)

  const onInit = useCallback(
    async (issue_problem_id, admin_id) => {
      try {
        setLoading(true)
        await props.issue_problem.getIssueProblem(issue_problem_id)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.issue_problem]
  )

  useEffect(() => {
    onInit(id)
  }, [onInit, id])

  const onReply = async (text) => {
    try {
      setSaving(true)

      await props.issue_problem.replyIssueProblem(id, text)
    } catch (e) {
      message.error({
        title: `บันทึกคำที่พบบ่อย`,
        message: e.message,
      })
    }
    setSaving(false)
  }

  const onCloseIssue = async (text) => {
    try {
      setSaving(true)

      await props.issue_problem.closeIssueProblem(id)
    } catch (e) {
      message.error({
        title: `บันทึกคำที่พบบ่อย`,
        message: e.message,
      })
    }
    setSaving(false)
  }
  const onBack = () => {
    navigate('/issue-problem')
  }
  const onClick = async () => {
    await onReply(text)
    setText('')
  }
  const handleCancel = () => {
    setOpen(false)
  }

  const content = detail.chat_list.map((item, i) => {
    return <Message key={i} item={item} />
  })

  const disabled = detail.status === 'closed'
  return (
    <AppMenu name="issue">
      <Modal visible={isOpened} setOpen={setOpen}>
        <ModalDetail>
          <h1>ปิด Issue</h1>
          <p>
            เมื่อกดยืนยันแล้วจะไม่สามารถตอบกลับใน Issue ID{' '}
            <span>{detail.issue_problem_id}</span> ได้อีก
          </p>
          <button onClick={handleCancel}>กลับ</button>
          <button onClick={onCloseIssue}>ยืนยัน</button>
        </ModalDetail>
      </Modal>
      <PageView>
        <BtnBack onClick={onBack}>
          <LeftOutlined />
          ศูนย์การช่วยเหลือ
        </BtnBack>
        <Header doc={detail} />
        <Loading loading={loading} />
        <MessageList>{content}</MessageList>
        <ReplyBox>
          <img src={user.img_url} size="51px" alt="user profile" />
          <TextArea
            label="ตอบข้อความ"
            disabled={disabled || loading}
            value={text}
            onChange={(val) => setText(val)}
          />
        </ReplyBox>
        <Btn>
          <Button
            className="btnCloseIssue"
            disabled={disabled}
            loading={saving}
            text="ปิด issue"
            onClick={() => setOpen(true)}
          />
          <Button
            className="btnAnswer"
            text="ตอบ"
            color="primary"
            loading={loading}
            disabled={disabled}
            onClick={onClick}
          />
        </Btn>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px 40px;
`
const ModalDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  p {
    text-align: center;
    color: #4e4b66;
  }
  button {
    cursor: pointer;
    background: #fdc851;
    border-radius: 16px;
    width: 220px;
    height: 52px;
    border: transparent;
    margin-bottom: 5px;
    color: #946200;
    :last-child {
      color: #4e4b66;
      background: transparent;
    }
  }
`
const MessageList = styled.div`
  padding: 20px 0px;
`

const Btn = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 40%;
  margin-left: 60%;
  .btnCloseIssue {
    margin-right: 8px;
    color: #fdc851 !important;
    border: 2px solid #fdc851 !important;
  }
  .btnAnswer {
    color: #946200 !important;
  }
`
const BtnBack = styled.div`
  padding-bottom: 25px;
  align-items: center;
  color: #de9c02;
  :hover {
    cursor: pointer;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;
  }
`

const ReplyBox = styled.div`
  display: flex;
  img {
    width: 51px;
    height: 51px;
    border-radius: 25px;
    margin-right: 1rem;
  }
  :last-child {
    margin-left: 20px;
  }
`

export default inject('issue_problem', 'member')(observer(IssueProblem))
