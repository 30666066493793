const config = {
  mode: 'localhost',
  firebase: {
    apiKey: 'AIzaSyCJyNQte79aUJvJQo-OK38fUyl6z2zEIcQ',
    authDomain: 'friday-develop.firebaseapp.com',
    projectId: 'friday-develop',
    storageBucket: 'friday-develop.appspot.com',
    messagingSenderId: '17124439675',
    appId: '1:17124439675:web:5d7c76c0cffb73dfad54de',
    measurementId: 'G-DF17Z59P86',
  },
  api: 'https://friday.hlo.app/api',
  version: '0.1',
}

module.exports = config
