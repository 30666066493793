import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import { message, format } from 'utils'
import { Row, InfoRow } from 'components/display'
import UserImg from 'components/display/UserImg'
import Loading from 'components/loading'
import { Link } from 'components/link'
import SelectedPackageHistoryTable from 'components/table/selectedPackageHistoryTable'
import PaymentHistoryTable from 'components/table/paymentHistoryTable'
// import CreditHistoryTable from 'components/table/creditHistoryTable'
import AppMenu from 'pages/menu'

const UserDetail = (props) => {
  const [, setInit] = useState(false)
  const [loading, setLoading] = useState(false)

  const { id } = useParams()
  const { detail } = props.user.toJS()

  const onInit = useCallback(
    async (admin_id) => {
      try {
        setInit(true)
        setLoading(true)
        await props.user.getUserById(admin_id)
      } catch (e) {
        message.error({ message: e.message })
      }
      setLoading(false)
      setInit(false)
    },
    [props.user, setLoading]
  )

  useEffect(() => {
    onInit(id)
  }, [onInit, id])

  return (
    <AppMenu name="user">
      <Row>
        <Link to="/user" style={{ color: 'var(--fd-secondary-dark-color)' }}>
          {`< สมาชิกในระบบ`}
        </Link>
      </Row>
      <Loading loading={loading}>
        <PageView>
          <Avatar size="68px" img_url={detail.img_url} />
          <InfoContainer>
            <InfoRow label="User ID" text={detail.user_id} />
            <InfoRow label="ชื่อ" text={detail.name} />
            <InfoRow label="นามสกุล" text={detail.surname} />
            <InfoRow label="หมายเลขโทรศัพท์" text={detail.mobile} />
            <InfoRow label="อีเมล" text={detail.email} />
            <InfoRow label="บริษัทต้นสังกัด" />
            <InfoRow
              label="วันที่ลงทะเบียนเข้าสู่ระบบ"
              text={format.toThaiDate(detail.created_at)}
            />
            <InfoRow label="ระยะเวลาใช้งานจนถึงปัจจุบัน" />
            <InfoRow label="สถานะ" text={detail.status} />
            <InfoRow
              label="แพ็กเกจปัจจุบัน"
              text={format.toThaiPackage(detail.package_type)}
            />
            <InfoRow label="วันชำระเงิน" />
            <InfoRow
              label="รายการ Ticket ทั้งหมด"
              text={detail.user_ticket + ''}
            />
            <InfoRow
              label={`สถานะการให้ยอมรับ T&C สำหรับเวอร์ชั่นล่าสุด`}
              text={detail.t_and_c_updated ? 'ยอมรับ' : 'ไม่ยอมรับ'}
            />
            <InfoRow label="สถานะการให้ความยินยอมจากลูกค้า สำหรับเวอร์ชั่นล่าสุด" />
            <InfoRow label="สถานะการให้ความยินยอมจากตัวแทน สำหรับเวอร์ชั่นล่าสุด" />
          </InfoContainer>
          <SelectedPackageHistoryTable
            data={detail.user_package_history || []}
          />
          <PaymentHistoryTable data={detail.user_payment_history || []} />
          {/* <CreditHistoryTable /> */}
        </PageView>
      </Loading>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Avatar = styled(UserImg)`
  display: block;
  margin: 0 auto 2rem;
`

const InfoContainer = styled.div`
  margin-bottom: 2rem;
`

export default inject('user')(observer(UserDetail))
