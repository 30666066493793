import { Row, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Pagination, Status, UserImg } from 'components/display'
import { Text } from 'components/typo'
import { format, helper } from 'utils'

function LifeBridgeTeamAgentTable(props) {
  const navigate = useNavigate()

  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <Row justify="end" style={{ marginBottom: 8 }}>
        <Text xsmall color="#A0A3BD">
          {props.page?.total || '0'} รายการ
        </Text>
      </Row>
      <StyledTable
        scroll={{ x: 830 }}
        dataSource={props.data}
        columns={[
          {
            title: 'ที่',
            dataIndex: 'no',
            key: 'no',
            width: 46,
            render: (_, __, idx) => {
              const pagination = props.page || {}
              const no =
                (pagination.index ? pagination.index - 1 : 0) *
                  (pagination.size || 10) +
                idx +
                1
              return no || '-'
            },
          },
          {
            title: 'สมาชิก',
            dataIndex: 'name',
            key: 'name',
            width: 324,
            ellipsis: true,
            render: (text, row) =>
              (
                <>
                  <Avatar
                    img_url={row.img_url}
                    size="27px"
                    gender={row.agent?.gender || null}
                  />
                  <Text xsmall>{text}</Text>
                </>
              ) || '-',
          },
          {
            title: 'สังกัด',
            dataIndex: 'company_name',
            key: 'company_name',
            width: 100,
            render: (text) => text || '-',
          },
          {
            title: 'ใบอนุญาต',
            dataIndex: 'license_list',
            key: 'license_list',
            width: 140,
            render: (text = []) =>
              text && text.length > 0
                ? text.map((t) => t.license_type).join('/')
                : '-',
          },
          {
            title: 'วันที่สมัคร',
            dataIndex: 'register_date',
            key: 'register_date',
            width: 124,
            render: (text) =>
              helper.isValue(text) ? format.toShortThaiDate(text) : '-',
          },
          {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text) => <Status status={text} />,
          },
        ]}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              if (record.life_bridge_agent_id) {
                navigate(
                  `/life-bridge/${props.teamId}/agent/${record.life_bridge_agent_id}`
                )
              }
            },
          }
        }}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`

const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`

const Avatar = styled(UserImg)`
  margin-right: 0.5rem;
`

export default LifeBridgeTeamAgentTable
