import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import Joi from 'joi'

import { loginEmail, checkGoogle } from 'app/firebaseApp'
import { error, firebase, http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const noUserMsg = 'ไม่พบสมาชิกอยู่ในระบบ'

const original = {
  uid: '',
  provider: {},
  img_url: undefined,
  email: '',
  name: '',
  surname: '',
  role: '',
}

let state
export class Member extends BaseStore {
  constructor() {
    super()
    this.observable({
      verifying: false,
      user: cloneDeep(original),
    })

    state = this
    http.setMember(this)
  }

  reset() {
    firebase.auth().signOut()

    runInAction(() => {
      state.verifying = false
      state.user = cloneDeep(original)
      state.firebase = {}
    })
  }

  async getCurrentUser() {
    return await firebase.onAuthStateChanged()
  }

  async checkLoginUser() {
    runInAction(() => {
      state.verifying = true
    })

    try {
      const user = await this.getCurrentUser()

      if (user) {
        runInAction(() => {
          state.firebase = user
        })
        await this.getUserProfile({ user })
      }
    } catch (e) {
      console.log('check user:', e.message)
    }

    runInAction(() => {
      state.verifying = false
    })
  }

  getProvider({ user }) {
    const list = user.providerData || []
    return list.map((item) => item.providerId)
  }

  setUserProfile({ user }) {
    if (user) {
      runInAction(() => {
        state.user = user
      })
    }
  }

  async getUserProfile(params = {}) {
    const { user } = params

    const token = await user.getIdToken()
    await http.setToken(token)

    const url = `${config.api}/v1/admin/profile`
    const resp = await http.get(url)

    const data = resp.body
    await this.setUserProfile({ user: data })
  }

  async editUserProfile(json = {}) {
    const url = `${config.api}/v1/admin/profile`
    const resp = await http.put(url, { json })
    const data = resp.body
    this.setUserProfile({ user: data })
  }

  async deleteUserProfile() {
    const url = `${config.api}/v1/admin/profile/inactive`
    const resp = await http.put(url)
    const data = resp.body
    return data
  }

  isLogin() {
    return this.user.uid !== ''
  }

  async logout(cb = () => {}) {
    await http.setToken()
    await this.reset()
    cb()
  }

  validateEmail(email) {
    return Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .validate(email)
  }

  async loginByEmail(params = {}) {
    let { email, password, isNotSave } = params
    const validate = await this.validateEmail(email)
    if (validate.error) {
      error.lunch({ message: `This's email ${validate.value} not found` })
      return
    }

    const user = await loginEmail(validate.value, password, { isNotSave })
    error.isNull(user, { message: noUserMsg })

    return this.getUserProfile({ user })
  }

  async loginByGoogle() {
    const result = await checkGoogle()

    const { user } = result
    error.isNull(user, { message: 'user not found' })
    return await this.getUserProfile({ user })
  }

  setUserRegister(data = {}) {
    const { user, status } = data

    error.isError(status === 'used', { message: 'Account เป็นสมาชิกอยู่แล้ว' })

    this.setUserProfile({ user })
  }

  async resetPassword({ email }) {
    const json = { email }
    const url = `${config.api}/v1/public/customer/reset/password`
    await http.put(url, { json })
  }

  async setNewPassword({ ref_code, code, password }) {
    const json = { code, password }
    const url = `${config.api}/v1/public/customer/set/password/${ref_code}`
    await http.put(url, { json })
  }
}

export default new Member()
