import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  user: {
    name: '',
    surname: '',
    email: '',
  },
}

let state
export class Admin extends BaseStore {
  constructor() {
    super()
    this.observable({
      users: {
        filter: {
          status: '',
        },
        pagination: {
          index: 1,
          per_page: 40,
          total: 0,
        },
        list: [],
      },
      detail: cloneDeep(original.user),
    })
    state = this
  }

  async getUserList(val) {
    let url = `${config.api}/v1/admin/manage`
    if (val) {
      url = `${config.api}/v1/admin/manage?keyword=${val}`
    }

    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.users.list = list
    })
  }

  async getCountUserList() {
    const url = `${config.api}/v1/admin/manage/count`
    const resp = await http.get(url)

    const { counter = 0 } = resp.body
    runInAction(() => {
      state.users.pagination.total = counter
    })
  }

  async getUserById(id) {
    const url = `${config.api}/v1/admin/manage/${id}/detail`
    const resp = await http.get(url)

    const doc = resp.body || {}
    runInAction(() => {
      state.detail = doc
    })
  }

  async saveUser(detail) {
    const { admin_id } = detail
    let res
    if (admin_id) {
      const url = `${config.api}/v1/admin/manage/${admin_id}`
      res = await http.put(url, { json: detail })
    } else {
      const url = `${config.api}/v1/admin/manage`
      res = await http.post(url, { json: detail })
    }

    const doc = res.body || {}
    runInAction(() => {
      state.detail = doc
    })
  }

  setUser(doc) {
    runInAction(() => {
      state.detail = doc
    })
  }

  resetUser() {
    runInAction(() => {
      state.detail = cloneDeep(original.user)
    })
  }
}

export default new Admin()
