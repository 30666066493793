import React from 'react'
import styled, { useTheme } from 'styled-components'

const Text = (props) => {
  const theme = useTheme()

  const style = getStyle(props)
  const font = getFont(props)
  const color = getColor(props, theme)
  const styleProps = { font, style, color }

  return <CustomText {...styleProps}>{props.children}</CustomText>
}

const getFont = (props) => {
  const { align, bold, xsmall, small, large, huge } = props
  const font = {
    weight: 'normal',
    size: '18px',
    height: '34px',
    align,
  }

  if (bold) {
    font.weight = '600'
  }

  if (xsmall) {
    font.size = '14px'
    font.height = '24px'
  } else if (small) {
    font.size = '16px'
    font.height = '28px'
  } else if (large) {
    font.size = '24px'
    font.height = '38px'
  } else if (huge) {
    font.size = '60px'
    font.height = '48px'
  }

  return font
}

const getStyle = (props) => {
  const style = {}

  if (props.oneLine) {
    style.whitespace = 'nowrap'
  }

  return style
}

const getColor = (props, theme) => {
  const { primary, secondary, color } = props

  if (primary) return { font: theme.color.primary }
  if (secondary) return { font: theme.color.secondary }
  if (color) return { font: color }

  return { font: 'inherit' }
}

const CustomText = styled.span`
  transition: all 0.2s;

  color: ${(props) => props.color.font};
  font-weight: ${(props) => props.font.weight};
  font-size: ${(props) => props.font.size};
  line-height: ${(props) => props.font.height};
  text-align: ${(props) => props.font.align};

  white-space: ${(props) => props.style.whitespace};
`

export default Text
