import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import AppMenu from 'pages/menu'
import Modal from 'components/Modal'
import Loading from 'components/loading'
import { FieldInput, TextArea, Dropdown } from 'components/input'
import { message } from 'utils'

function ProblemDetail(props) {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { detail } = props.common_problem.toJS()
  const navigate = useNavigate()
  const [isOpened, setOpen] = useState(false)

  const onInit = useCallback(
    async (id) => {
      try {
        if (id) {
          setLoading(true)
          await props.common_problem.getCommonProblemById(id)
        }
      } catch (e) {
        message.error({ message: e.message })
      }
      setLoading(false)
    },
    [props.common_problem]
  )

  useEffect(() => {
    onInit(id)
  }, [onInit, id])

  useEffect(() => {
    const { category_list } = props.common_problem.toJS()
    const opts = category_list.map((it) => {
      return {
        label: it.name,
        value: it.common_problem_category_id,
      }
    })
    setOptions(opts)
  }, [props.common_problem])

  const onChange = (val, name) => {
    detail[name] = val
    props.common_problem.setCommonProblem(detail)
  }

  const onSave = async () => {
    try {
      setLoading(true)
      let detail = props.common_problem.detail
      await props.common_problem.saveCommonProblem(detail)
      message.success({ message: 'บันทึกสำเร็จ' })
      navigate('/issue-problem')
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    }
    setLoading(false)
  }

  const onDelete = () => {
    try {
      props.common_problem.remove(id)
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    navigate('/issue-problem')
  }

  const showModal = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const onBack = () => {
    navigate('/issue-problem')
  }
  return (
    <>
      <Modal visible={isOpened} setOpen={setOpen}>
        <ModalDetail>
          <h1>ลบหมวด</h1>
          <p>
            เมื่อกดยืนยันแล้ว <b>หัวข้อ{detail.title}</b> จะถูกลบออกจากระบบ
          </p>
          <button onClick={handleCancel}>กลับ</button>
          <button onClick={onDelete}>ยืนยัน</button>
        </ModalDetail>
      </Modal>
      <AppMenu name="issue">
        <Loading loading={loading}>
          <BodyArea>
            <BtnBack onClick={onBack}>
              <LeftOutlined />
              ปัญหาที่พบบ่อย
            </BtnBack>
            <Header>
              {id ? <span onClick={() => showModal()}>ลบหัวข้อนี้</span> : null}
              <button onClick={onSave}>บันทึก</button>
            </Header>
            <div className="fieldInput">
              <span>หมวด</span>
              <Dropdown
                label="เลือกหมวด"
                name="common_problem_category_id"
                value={detail.common_problem_category_id}
                options={options}
                onChange={onChange}
              />
            </div>
            <div className="fieldInput">
              <span>หัวข้อ</span>
              <FieldInput
                label="ระบุ"
                name="title"
                value={detail.title}
                onChange={onChange}
              />
            </div>
            <div className="fieldInput">
              <span>สถานะ</span>
              <Dropdown
                label="สถานะ"
                name="status"
                value={detail.status}
                options={options.statusOptions}
                onChange={onChange}
              />
            </div>
            <div className="fieldInput">
              <span>คำอธิบาย</span>
              <TextArea
                label="ระบุ"
                rows="6"
                name="detail"
                value={detail.detail}
                onChange={onChange}
              />
            </div>
          </BodyArea>
        </Loading>
      </AppMenu>
    </>
  )
}

const BodyArea = styled.div`
  div {
    display: flex;
    flex-direction: row;
    span {
      align-self: center;
      font-weight: 400;
      font-size: 18px;
    }
  }
  .fieldInput span {
    color: #a0a3bd;
    white-space: nowrap;
    padding-left: 16px;
    width: 95px;
  }
  .fieldInput {
    padding-bottom: 23px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 23px;
  span {
    cursor: pointer;
    color: #fdc851 !important;
    padding-right: 12px;
  }
  button {
    cursor: pointer;
    color: #946200;
    font-size: 14px;
    font-weight: 600;
    background: #fdc851;
    border: transparent;
    border-radius: 16px;
    width: 120px;
    height: 40px;
  }
`

const BtnBack = styled.div`
  align-items: center;
  color: #de9c02;

  span {
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;
    /* padding-bottom: 16px; */
  }
`
const ModalDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  p {
    text-align: center;
    color: #4e4b66;
  }
  button {
    cursor: pointer;
    background: #fdc851;
    border-radius: 16px;
    width: 220px;
    height: 52px;
    border: transparent;
    margin-bottom: 5px;
    color: #946200;
    :last-child {
      color: #4e4b66;
      background: transparent;
    }
  }
`
export default inject('common_problem')(observer(ProblemDetail))
