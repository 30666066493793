import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'components/button/Button'
import { Col, Row } from 'components/display'
import { Dropdown, FieldInput } from 'components/input'
import { Link } from 'components/link'
import Loading from 'components/loading'
import {
  PackageDurationOptions,
  PackageTypeOptions,
  PromotionTypeOptions,
} from 'definition/support'
import AppMenu from 'pages/menu'
import { helper, message } from 'utils'

const requiredFields = [
  'code',
  'name',
  'promotion_type',
  'value',
  'package_type',
  'package_duration',
]

const validate = (values = {}) => {
  const errors = {}
  requiredFields.forEach((field) => {
    if (helper.isNull(values[field])) {
      errors[field] = 'กรุณากรอกรายละเอียด'
    }
  })

  return errors
}

const PromotionCreate = (props) => {
  const { promotion = {} } = props
  const { current } = promotion.toJS()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(false)
  const [promotionData, setPromotionData] = useState(current)
  const navigate = useNavigate()

  const onChange = (val, name) => {
    setPromotionData({ ...promotionData, [name]: val })
    setErrors({ ...errors, [name]: null })
  }

  const onSave = useCallback(async () => {
    try {
      setLoading(true)
      setErrors({})
      const errors = validate(promotionData)
      const isValid = helper.isEmpty(errors)
      if (isValid) {
        await promotion.createPromotion({
          ...promotionData,
          status: 'inactive',
        })
        message.success({ message: 'บันทึกสำเร็จ' })
        navigate('/setting/promotion')
      } else {
        setErrors(errors)
      }
    } catch (e) {
      message.error({
        title: `บันทึก`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [promotion, promotionData, setLoading, navigate])

  useEffect(() => {
    return () => {
      setPromotionData({})
      setErrors({})
    }
  }, [])

  return (
    <AppMenu name="promotion">
      <Row>
        <Link
          to="/setting/promotion"
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< ตั้งค่าโปรโมชัน`}
        </Link>
      </Row>
      <Loading loading={loading}>
        <PageView>
          <Container>
            <Row gutter={[24, 24]}>
              <Col {...col.field}>
                <FieldInput
                  label="CODE"
                  name="code"
                  value={promotionData.code}
                  onChange={onChange}
                  errorText={errors.code}
                />
              </Col>
              <Col {...col.field}>
                <FieldInput
                  label="ชื่อโปรโมชัน"
                  name="name"
                  value={promotionData.name}
                  onChange={onChange}
                  errorText={errors.name}
                />
              </Col>
              <Col {...col.field}>
                <Dropdown
                  label="ประเภทโปรโมชัน"
                  name="promotion_type"
                  value={promotionData.promotion_type}
                  options={PromotionTypeOptions}
                  onChange={onChange}
                  errorText={errors.promotion_type}
                />
              </Col>
              <Col {...col.field}>
                <FieldInput
                  label="มูลค่าโปรโมชัน"
                  name="value"
                  value={promotionData.value}
                  onChange={onChange}
                  errorText={errors.value}
                  isNumber
                />
              </Col>
              <Col {...col.field}>
                <FieldInput
                  label="โปรโมชันลิมิต"
                  name="promotion_limit"
                  value={promotionData.promotion_limit}
                  onChange={onChange}
                  errorText={errors.promotion_limit}
                  isNumber
                />
              </Col>
              <Col {...col.field} />
              <Col {...col.field}>
                <Dropdown
                  label="ประเภทแพ็กเกจ"
                  name="package_type"
                  value={promotionData.package_type}
                  options={PackageTypeOptions}
                  onChange={onChange}
                  errorText={errors.package_type}
                />
              </Col>
              <Col {...col.field}>
                <Dropdown
                  label="ระยะเวลาแพ็กเกจ"
                  name="package_duration"
                  value={promotionData.package_duration}
                  options={PackageDurationOptions}
                  onChange={onChange}
                  errorText={errors.package_duration}
                />
              </Col>
            </Row>
            <ButtonContainer>
              <Row justify="end">
                <Button
                  maxWidth="117px"
                  type="button"
                  text="บันทึก"
                  onClick={onSave}
                />
              </Row>
            </ButtonContainer>
          </Container>
        </PageView>
      </Loading>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 524px;
  padding: 2rem 0;
  margin: 0 auto;
`

const ButtonContainer = styled.div`
  padding-top: 24px;
`

const col = {
  field: { xl: 12, lg: 12, md: 12, xs: 24 },
}

export default inject('promotion')(observer(PromotionCreate))
