import React from 'react'

import Button from 'components/button/Button'

const RemoveBtn = (props) => {
  const { text = 'ลบ', loading, disabled, onClick } = props
  return (
    <Button
      small
      text={text}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    />
  )
}

export default RemoveBtn
