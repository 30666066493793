import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { message } from 'utils'
import { HeaderRow, TableTitle, Col } from 'components/display'
import { Drawer } from 'components/dialog'
import Loading from 'components/loading'
import AppMenu from 'pages/menu'

import col from './col'
import RowItem from './RowItem'
import Detail from './Detail'

const CategoryList = (props) => {
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [doc, setDoc] = useState(undefined)

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await props.issue_problem.getCategoryList()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.issue_problem])

  useEffect(() => {
    onInit()
  }, [onInit])

  const onClose = () => setDoc(undefined)
  const onConfirm = async () => {
    try {
      setSaving(true)

      await props.issue_problem.saveIssueProblemCategory(doc)
      onClose()
      message.success()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setSaving(false)
  }

  const onSelect = (item) => setDoc(item)

  const { category_list } = props.issue_problem.toJS()

  const content = category_list.map((it, i) => {
    return <RowItem key={i} item={it} onSelect={onSelect} />
  })

  const visible = doc !== undefined
  const disabled = visible ? doc.seq === '' || doc.name === '' : false
  return (
    <AppMenu name="issue.problem.category">
      <PageView>
        <TableTitle
          title="ประเภทของศูนย์ช่วยเหลือ"
          onAdd={() => setDoc({ seq: '', name: '' })}
        />

        <HeaderRow>
          <Col {...col.name}>ชื่อ</Col>
          <Col {...col.seq}>ลำดับ</Col>
          <Col {...col.date}>สร้างเมื่อ</Col>
          <Col {...col.date}>ปรับปรุงเมื่อ</Col>
        </HeaderRow>
        <Loading loading={loading}>{content}</Loading>
      </PageView>
      <Drawer
        title="ประเภทของศูนย์ช่วยเหลือ"
        visible={visible}
        disabled={disabled}
        loading={saving}
        width={400}
        onClose={onClose}
        onConfirm={onConfirm}
      >
        <Detail
          doc={doc}
          disabled={saving}
          onChange={(val) => setDoc({ ...val })}
        />
      </Drawer>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default inject('issue_problem')(observer(CategoryList))
