import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { LeftOutlined } from '@ant-design/icons'

import Modal from 'components/Modal'
import { TableTitle } from 'components/display'
import AppMenu from 'pages/menu'
import CommonIssueCategoryTable from 'components/table/commonIssueCategoryTable'
import { message } from 'utils'

const CategoryList = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [categoryToRemove, setCategoryToRemove] = useState({})

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.common_problem.getCategoryList()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
    setLoading(false)
  }, [props.common_problem])

  useEffect(() => {
    onInit()
  }, [onInit, props.common_problem.add])

  const showModal = (commonProblem) => {
    setVisible(true)
    setCategoryToRemove(commonProblem)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const { category_list } = props.common_problem.toJS()

  const onAdd = () => {
    props.common_problem.setAdd()
  }
  const onBack = () => {
    navigate('/issue-problem')
  }

  const onDelete = async () => {
    try {
      await props.common_problem.removeCategory(
        categoryToRemove.common_problem_category_id
      )
      window.location.reload()
    } catch (e) {
      message.error({
        message: e.message,
      })
    }
  }
  return (
    <AppMenu name="issue">
      <PageView>
        <Modal visible={visible} setOpen={setVisible} footer={null}>
          <ModalDetail>
            <h1>ลบหมวด</h1>
            <p>
              เมื่อกดยืนยันแล้วหัวข้อที่อยู่ใน
              <b> {categoryToRemove.name} </b> ทั้งหมด จะถูกย้ายไปอยู่ใน
              <b> หมวดชั่วคราว</b>
            </p>
            <button onClick={handleCancel}>กลับ</button>
            <button onClick={onDelete}>ยืนยัน</button>
          </ModalDetail>
        </Modal>
        <BtnBack onClick={onBack}>
          <LeftOutlined />
          ปัญหาที่พบบ่อย
        </BtnBack>
        <div className="containerBtn">
          <div className="Title">แก้ไขหมวด</div>
          <TableTitle onAdd={onAdd} />
        </div>
        <CommonIssueCategoryTable
          showModal={showModal}
          data={category_list}
          loading={loading}
          onChange={onInit}
        />
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  .containerBtn {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    .Title {
      display: flex;
      justify-content: center;
      width: 100%;
      font-size: 24px;
      font-weight: 700;
    }
  }
  .modalStyle {
    background-color: azure;
  }
`
const BtnBack = styled.div`
  align-items: center;
  color: #de9c02;
  :hover {
    cursor: pointer;
  }

  span {
    font-size: 16px;
    font-weight: 600;
    padding-right: 6px;
    /* padding-bottom: 16px; */
  }
`
const ModalDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 700;
    font-size: 32px;
  }
  p {
    text-align: center;
    color: #4e4b66;
  }
  button {
    cursor: pointer;
    background: #fdc851;
    border-radius: 16px;
    width: 220px;
    height: 52px;
    border: transparent;
    margin-bottom: 5px;
    color: #946200;
    :last-child {
      color: #4e4b66;
      background: transparent;
    }
  }
`

export default inject('common_problem')(observer(CategoryList))
