import SideMenu from 'menu/SideMenu'

const AppMenu = (props) => {
  const { name, children } = props
  return (
    <SideMenu title="เมนู" name={name} list={MENU}>
      {children}
    </SideMenu>
  )
}

const MENU = [
  {
    key: 'profile',
    name: 'บัญชีผู้ใช้งาน',
    type: 'topic',
    submenu: [
      {
        key: 'profile',
        name: 'โปรไฟล์ของฉัน',
        link: '/profile',
      },
    ],
  },
  {
    key: 'management',
    name: 'การจัดการ',
    type: 'topic',
    submenu: [
      {
        key: 'dashboard',
        name: 'แดชบอร์ด',
        link: '/',
      },
      {
        key: 'lifebridge',
        name: 'Life Bridge',
        link: '/life-bridge',
      },
      {
        key: 'admin',
        name: 'ผู้ดูแลระบบ',
        link: '/admin',
      },
      {
        key: 'user',
        name: 'สมาชิกในระบบ',
        link: '/user',
      },
      // {
      //   key: 'common',
      //   name: 'คำถามที่พบบ่อย',
      //   link: '/common-problem',
      // },
      {
        key: 'issue',
        name: 'ศูนย์ช่วยเหลือ',
        link: '/issue-problem',
      },
      {
        key: 'transactions',
        name: 'ธุรกรรมการเงิน',
        link: '/transactions',
      },
      {
        key: 'consent.terms',
        name: 'เงื่อนไขการใช้บริการ',
        link: '/consent/terms',
      },
      {
        key: 'consent.privacy',
        name: 'นโยบายความเป็นส่วนตัว',
        link: '/consent/privacy',
      },
      {
        key: 'provinces',
        name: 'ตั้งค่าจังหวัด',
        link: `/setting/provinces`,
      },
      {
        key: 'promotion',
        name: 'ตั้งค่าโปรโมชัน',
        link: '/setting/promotion',
      },
      {
        key: 'feedback',
        name: 'การให้คะแนนและรีวิว',
        link: `/feedback`,
      },
    ],
  },
]

export default AppMenu
