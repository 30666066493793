import React from 'react'
import { useTheme } from 'styled-components'

import Text from 'components/typo/Text'

const HelperText = (props) => {
  const theme = useTheme()
  const { helperText, successText, errorText } = props
  return (
    <>
      {helperText && (
        <Text xsmall color={theme.gray.label}>
          {helperText}
        </Text>
      )}
      {successText && (
        <Text xsmall color={theme.color.successDark}>
          {successText}
        </Text>
      )}
      {errorText && (
        <Text xsmall color={theme.color.errorDark}>
          {errorText}
        </Text>
      )}
    </>
  )
}

export default HelperText
