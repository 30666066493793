import React, { useState, useEffect, useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'

import { FieldInput } from 'components/input'
import { Text } from 'components/typo'
import { Link } from 'components/link'
import Loading from 'components/loading'
import { TableTitle, Row } from 'components/display'
import { message } from 'utils'
import AppMenu from 'pages/menu'

import imgSort from '../../../assets/sorting.png'

const MemberList = (props) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [text, setText] = useState('')
  const [query, setQuery] = useState('')
  const [timeoutId, setTimeoutId] = useState(null)
  const timeout = 1500

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await props.admin.getCountUserList()
      await props.admin.getUserList()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [props.admin])

  const onQuery = useCallback(
    async (val) => {
      try {
        setLoading(true)
        await props.admin.getUserList(val)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      }
      setLoading(false)
    },
    [props.admin]
  )

  useEffect(() => {
    onInit()
  }, [onInit])

  const onAdd = () => {
    navigate('/admin/create')
  }
  useEffect(() => {
    let values = query
    onQuery(values)
  }, [query, onQuery])

  const handleChange = (value) => {
    setText(value)
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, timeout)
    )
  }

  const { users } = props.admin.toJS()
  const { list = [] } = users || {}

  return (
    <AppMenu name="admin">
      <PageView>
        <Row align="center">
          <Text large bold align="center">
            ผู้ดูแลระบบ
          </Text>
        </Row>
        <RowAdmin>
          <FieldInput
            className="input"
            type="text"
            placeholder="ค้นหา"
            value={text}
            onChange={handleChange}
            startIcon={<SearchOutlined className="iconsSearch" />}
            admin
          />
          <TableTitle onAdd={onAdd} />
        </RowAdmin>
        <TableAdmin>
          <table>
            <thead>
              <tr className="headerTable">
                <th className="headerName">
                  ชื่อ{'  '}
                  <span>
                    <img src={imgSort} alt="sort icon" />
                  </span>
                </th>

                <th className="headerEmaill">
                  อีเมล{'  '}
                  <span>
                    <img src={imgSort} alt="sort icon" />
                  </span>
                </th>
                <th className="headerTel">
                  หมายเลขโทรศัพท์{'  '}
                  <span>
                    <img src={imgSort} alt="sort icon" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <Loading loading={loading}>
                {list.map((e, index) => (
                  <tr className="infoTable" key={index}>
                    <td>
                      <img src={e.img_url} alt="user profile" />
                      <Link to={`/admin/${e.admin_id}/edit`}>
                        <span>
                          {e.name} {e.surname}
                        </span>
                      </Link>
                    </td>
                    <td>{e.email}</td>
                    <td>{e.mobile ? e.mobile : '-'}</td>
                  </tr>
                ))}
              </Loading>
            </tbody>
          </table>
        </TableAdmin>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  padding-right: 50px;
`
const RowAdmin = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TableAdmin = styled.div`
  padding: 18px 10px 1px 1px;
  overflow: auto;

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #d9dbe9;
  }
  table th:first-child {
    border-left: hidden;
    border-radius: 10px 0 0 0;
  }
  table th:last-child {
    border-right: hidden;
    border-radius: 0 10px 0 0;
  }

  table th {
    border-left: 1px solid #d9dbe9;
    background-color: #eff0f7;
    width: 25%;
    height: 59px;
  }
  .headerTable {
    display: table;
    width: 100%;
    height: 59px;
    table-layout: fixed;
  }
  .headerTable th {
    font-weight: 600;
    padding-left: 20px;
    text-align: start;
  }
  tbody {
    display: block;
    max-height: 280px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-collapse: collapse;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    ::-webkit-scrollbar {
      /* padding-left: 10px; */
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-left: 4px white solid;
      background: #fff3d7;
      border-radius: 5px;
    }
  }
  .infoTable {
    display: table;
    width: 100.7%;
    height: 70px;
    table-layout: fixed;
  }
  .infoTable td {
    text-align: start;
    padding-left: 20px;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .infoTable td span {
    padding-left: 9px;
  }
  tbody tr td {
    border-collapse: collapse;
    border: 1px solid #d9dbe9;
    text-align: center;
  }
  tbody td:first-child {
    border-left: hidden;
  }
  tbody td:last-child {
    border-right: hidden;
  }
  .selectTable {
    padding-left: 0 !important;
    text-align: center !important;
  }

  img {
    width: 29px;
    height: 29px;
    border-radius: 25px;
  }
`

export default inject('admin')(observer(MemberList))
