import { Row, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Pagination, Status } from 'components/display'
import { format, helper } from 'utils'

const columns = [
  {
    title: 'Promotion Name',
    dataIndex: 'name',
    key: 'name',
    width: 180,
    render: (text) => text || '-',
  },
  {
    title: 'Promotion Code',
    dataIndex: 'code',
    key: 'code',
    width: 180,
    render: (text) => text || '-',
  },
  {
    title: 'ใช้งาน',
    dataIndex: 'promotion_used',
    key: 'promotion_used',
    width: 180,
    render: (text) => (text ? `${text} ครั้ง` : '-'),
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (text) => (text ? <Status status={text} /> : '-'),
  },
  {
    title: 'วันที่อัพเดต',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: 120,
    render: (text) =>
      helper.isValue(text) ? format.toShortThaiDate(text) : '-',
  },
  {
    title: 'วันที่สร้าง',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    render: (text) =>
      helper.isValue(text) ? format.toShortThaiDate(text) : '-',
  },
]

function PromotionTable(props) {
  const navigate = useNavigate()

  const pagination = props.page ? (
    <Pagination page={props.page} onChange={props.onChange} />
  ) : undefined

  return (
    <Container className={props.className}>
      <StyledTable
        scroll={{ x: 720 }}
        dataSource={props.data}
        columns={columns}
        loading={props.loading}
        onRow={(record) => {
          return {
            onClick: (e) => {
              navigate(`/setting/promotion/${record.code}`)
            },
          }
        }}
        pagination={false}
      />
      <Row justify="end" lg={24}>
        {pagination}
      </Row>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`
const StyledTable = styled(Table)`
  margin-bottom: 1rem;
`

export default PromotionTable
