import { Divider, Empty, Progress } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import StarRatings from 'react-star-ratings'
import styled from 'styled-components'

import Loading from 'components/loading'
import { Text } from 'components/typo'
import { format, message } from 'utils'

import FeedbackComment from '../component/Comment'

function Rating(props) {
  const { feedback } = props
  const { pagination = {} } = feedback.toJS()

  const { data = {} } = props
  const { star_summary = {}, rateCount } = data
  const average = star_summary.avg
  const dataArr = Object.values(star_summary)
  const dataKey = Object.keys(star_summary)
  const Rate = {
    rate_lv1: 'bad',
    rate_lv2: 'low',
    rate_lv3: 'normal',
    rate_lv4: 'high',
    rate_lv5: 'perfect',
  }

  const [loading, setLoading] = useState(false)

  const { per_page } = pagination
  const [page, setPage] = useState(1)
  const [comments, setComments] = useState([])
  const [prevY, setPrevY] = useState(0)

  let hasMore = true
  let loadingRef = useRef(null)
  let prevYRef = useRef({})
  let pageRef = useRef({})
  let commentRef = useRef({})
  commentRef.current = comments
  pageRef.current = page
  prevYRef.current = prevY

  const onInit = useCallback(async () => {
    try {
      setLoading(true)

      await feedback.getFeedback(pageRef.current, per_page)

      setComments([...commentRef.current, ...toJS(props.feedback.comment)])

      if (toJS(props.feedback.comment).length < per_page) {
        feedback.setHasmore(false)
      } else {
        feedback.setHasmore(true)
      }

      hasMore = props.feedback.hasMore
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [feedback])

  useEffect(() => {
    onInit()

    setPage(pageRef.current + 1)

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleObserver, options)
    observer.observe(loadingRef.current)
  }, [])

  const handleObserver = (entities, observer) => {
    setLoading(true)

    const y = entities[0].boundingClientRect.y

    if (prevYRef.current > y && hasMore) {
      onInit()
      setPage(pageRef.current + 1)
    }
    setLoading(false)
    setPrevY(y)
  }

  return (
    <>
      <Topsection>
        <div className="strCon">
          <Text huge bold align="center">
            {average}
          </Text>
          <StarRatings
            rating={average}
            starRatedColor="#5F2EEA"
            name="rating"
            starDimension={'40px'}
          />
          <CountRate>
            <Text align="center" color="gray">
              <span> {format.toLatinNum(rateCount)} รีวิว</span>
            </Text>
          </CountRate>
        </div>

        <BarItems>
          {dataArr.map((item, idx) =>
            idx !== 5 ? (
              <Wrapper>
                <span className="barName">{Rate[dataKey[idx]]}</span>
                <Progress
                  strokeColor={'#FDC850'}
                  strokeWidth={15}
                  key={Rate[dataKey[idx]]}
                  percent={item}
                />
              </Wrapper>
            ) : null
          )}
        </BarItems>
      </Topsection>
      <Divider />
      <ol>
        {comments.length > 0 ? (
          comments.map((item, idx) => {
            return (
              <FeedbackComment key={idx} item={item}>
                {' '}
                <p>
                  <StarRatings
                    rating={item.level}
                    starRatedColor="#5F2EEA"
                    name="rating"
                    starDimension={'15px'}
                  />
                </p>
              </FeedbackComment>
            )
          })
        ) : (
          <Empty />
        )}
        <div ref={loadingRef}>
          <Loading loading={loading}></Loading>
        </div>
      </ol>
    </>
  )
}

const Topsection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 20px;
  .star-ratings {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-top: 10px !important;
  }

  .strCon {
    display: grid;
    grid-template-columns: auto;
    margin-top: 50px;
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  width: 600px;

  span {
    padding-right: 10px;
  }
`

const BarItems = styled.div`
  display: grid;
  grid-template-columns: auto;
  .barName {
    width: 80px;
  }
`

const CountRate = styled.div`
  text-align: center;
`

export default inject('feedback')(observer(Rating))
