import React from 'react'
import styled from 'styled-components'
import { Table } from 'antd'

import { Text } from 'components/typo'
import { format } from 'utils/format'

const columns = [
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
    width: 190,
  },
  {
    title: 'จำนวนเงิน',
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
  },
  {
    title: 'วันที่ชำระเงิน',
    dataIndex: 'payment_date',
    key: 'payment_date',
    width: 170,
    render: (text) => format.toThaiDate(text),
  },
  {
    title: 'วิธีการชำระเงิน',
    dataIndex: 'payment_type',
    key: 'payment_type',
    width: 170,
    render: (text) => format.toThaiPaymentType(text),
  },
  {
    title: '',
    dataIndex: 'show',
    key: 'show',
    width: 100,
  },
]

function PaymentHistoryTable(props) {
  return (
    <Container className={props.className}>
      <Table
        title={() => <Text color="#A0A3BD">ประวัติการชำระเงิน</Text>}
        dataSource={props.data}
        columns={columns}
        pagination={false}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 2rem;
`

export default PaymentHistoryTable
