import React from 'react'

/* eslint-disable */
const Info = (props) => {
  const { size = 1 } = props

  const width = `${Math.ceil(24 * size)}`
  const height = `${Math.ceil(24 * size)}`
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21Z"
        fill="#4E4B66"
      />
      <path
        d="M12 11C11.4 11 11 11.4 11 12V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V12C13 11.4 12.6 11 12 11Z"
        fill="#4E4B66"
      />
      <path
        d="M12.7 7.3C12.3 6.9 11.6 6.9 11.3 7.3C11.2 7.4 11.1 7.5 11.1 7.6C11.1 7.7 11 7.8 11 8C11 8.1 11 8.3 11.1 8.4C11.2 8.5 11.2 8.59999 11.3 8.69999C11.5 8.89999 11.7 9 12 9C12.3 9 12.5 8.89999 12.7 8.69999C12.8 8.59999 12.9 8.5 12.9 8.4C12.9 8.3 13 8.2 13 8C13 7.9 13 7.7 12.9 7.6C12.9 7.5 12.8 7.4 12.7 7.3Z"
        fill="#4E4B66"
      />
    </svg>
  )
}

export default Info
