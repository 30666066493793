import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ScrollToTop = (props) => {
  const { location, children } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

export default ScrollToTop
