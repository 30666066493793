import React, { useState, useEffect, useRef } from 'react'
import JoditEditor from 'jodit-react'

const HtmlEditor = (props) => {
  const { onChange, init, name, value } = props

  const editor = useRef(null)
  const [content, setContent] = useState('')

  useEffect(() => {
    setContent(value || '')
  }, [init])

  const onEditorChange = (val) => {
    setContent(val)
    onChange(val, name)
  }

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  }

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={onEditorChange}
      // onChange={(val) => onChange(val, name)}
    />
  )
}

export default HtmlEditor
