import { SearchOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { InfoRow } from 'components/display'
import { FieldInput } from 'components/input'
import { Link } from 'components/link'
import LifeBridgeTeamAgentTable from 'components/table/lifeBridgeTeamAgentTable'
import { Text } from 'components/typo'
import { config } from 'config'
import { http, message } from 'utils'

import EmptyTableMessage from '../../components/EmptyTableMessage'
import SettingButton from './SettingButton'

function LifeBridgeTeamDetail(props) {
  const {
    current = {},
    agentList = [],
    pagination = {},
  } = props.life_bridge.toJS()
  const { id } = useParams()

  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [timeout, setTimeoutId] = useState(null)
  const [newcomerAgents, setNewcomerAgents] = useState(0)

  const handleChange = (value) => {
    setText(value)
    if (timeout) clearTimeout(timeout)
    setTimeoutId(
      setTimeout(() => {
        setQuery(value)
      }, 1500)
    )
  }

  const getTeamDetail = useCallback(
    async (company_affiliation_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeam(company_affiliation_id)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getTeamAgents = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeamAgents(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getTeamAgentsNewcomer = useCallback(async () => {
    try {
      setLoading(true)
      const query = new URLSearchParams({
        page: 1,
        per_page: 999,
        company_affiliation_id: id,
        status: 'newcomer',
      })
      const url = `${config.api}/v1/admin/lifebridge/life-bridge-agent/list${
        query ? `?${query}` : ''
      }`
      const resp = await http.get(url)
      const data = (resp.body || {}).data || []
      setNewcomerAgents(data.length)
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    } finally {
      setLoading(false)
    }
  }, [id])

  const onTableChange = (page) => {
    getTeamAgents({
      page: page.index,
      per_page: page.per_page,
      company_affiliation_id: id,
      status: 'success',
      ...(query ? { name: query } : {}),
    })
  }

  useEffect(() => {
    getTeamDetail(id)
    getTeamAgentsNewcomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    getTeamAgents({
      page: 1,
      per_page: 10,
      company_affiliation_id: id,
      status: 'success',
      ...(query ? { name: query } : {}),
    })
  }, [id, query, getTeamAgents])

  return (
    <Container>
      <Link
        to="/life-bridge"
        style={{ color: 'var(--fd-secondary-dark-color)' }}
      >
        {`< Life Bridge`}
      </Link>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row justify="center">
            <Text large bold align="center">
              หน่วย {current.company_affiliation_name}
            </Text>
          </Row>
        </Col>
        <Col span={24}>
          <StyledInfoRow label="หัวหน้าทีม" text={current.leader_name} />
          <div style={{ height: 8 }} />
          <StyledInfoRow label="จังหวัด" text={current.province} />
          <div style={{ height: 8 }} />
          <StyledInfoRow
            label="จำนวนสมาชิก"
            text={`${current.members_count || 0} คน`}
          />
        </Col>
        <Col span={24}>
          <Row justify="space-between">
            <InputContainer>
              <FieldInput
                admin
                className="input"
                type="text"
                placeholder="ค้นหา"
                value={text}
                onChange={handleChange}
                startIcon={<SearchOutlined className="iconsSearch" />}
              />
            </InputContainer>
            <ButtonContainer>
              <Link
                to={`/life-bridge/${id}/new`}
                disabled={newcomerAgents === 0}
              >
                <button
                  className="btn btn-secondary btn-sm"
                  type="button"
                  disabled={newcomerAgents === 0}
                >
                  เข้าร่วมใหม่{newcomerAgents > 0 ? ` (${newcomerAgents})` : ''}
                </button>
              </Link>
              <SettingButton
                agentList={agentList}
                companyAffiliationId={id}
                companyAffiliationName={current.company_affiliation_name}
              />
            </ButtonContainer>
          </Row>
        </Col>
        <Col span={24}>
          {agentList.length > 0 ? (
            <LifeBridgeTeamAgentTable
              teamId={id}
              data={agentList}
              page={pagination}
              loading={loading}
              onChange={onTableChange}
            />
          ) : (
            <EmptyTableMessage message="ยังไม่มีสมาชิกหน่วย" />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`

const StyledInfoRow = styled(InfoRow)`
  > label {
    width: 30%;
  }
  > div {
    width: 70%;
  }
`

const InputContainer = styled.div`
  width: 100%;
  max-width: 300px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > button {
    width: 120px;
  }
`

export default inject('life_bridge')(observer(LifeBridgeTeamDetail))
