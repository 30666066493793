import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Loading from 'components/loading'
import MainLayout from 'menu'
import AdminDetail from 'pages/admin/detail'
import AdminList from 'pages/admin/list'
import CommonProblemCategoryList from 'pages/commonProblem/category'
import CommonProblemDetail from 'pages/commonProblem/detail'
import CommonProblemList from 'pages/commonProblem/list'
import PrivacyPage from 'pages/consent/privacy'
import TermsPage from 'pages/consent/terms'
import Dashboard from 'pages/dashboard'
import DesignSystem from 'pages/designSystem'
import Feedback from 'pages/feedback'
import IssueProblemCategoryList from 'pages/issueProblem/category'
import IssueProblemDetail from 'pages/issueProblem/detail'
import IssueProblemList from 'pages/issueProblem/list'
import LifeBridge from 'pages/lifeBridge'
import LifeBridgeList from 'pages/lifeBridge/list'
import LifeBridgeNew from 'pages/lifeBridge/new'
import LifeBridgeTeam from 'pages/lifeBridge/team'
import LifeBridgeTeamAgent from 'pages/lifeBridge/team/agent'
import LifeBridgeTeamCreate from 'pages/lifeBridge/team/create'
import LifeBridgeTeamDetail from 'pages/lifeBridge/team/detail'
import LifeBridgeTeamEdit from 'pages/lifeBridge/team/edit'
import LifeBridgeTeamEditAgent from 'pages/lifeBridge/team/editAgent'
import LifeBridgeTeamHistory from 'pages/lifeBridge/team/history'
import LifeBridgeTeamNew from 'pages/lifeBridge/team/new'
import LoginPage from 'pages/login'
import Profile from 'pages/profile'
import ProfileEdit from 'pages/profile/edit'
import PromotionCreate from 'pages/promotion/create'
import PromotionDetail from 'pages/promotion/detail'
import PromotionList from 'pages/promotion/list'
import ProvinceList from 'pages/setting/province'
import Transactions from 'pages/transaction'
import TransDetail from 'pages/transaction/detail'
import UserDetail from 'pages/user/detail'
import UserList from 'pages/user/list'

const App = (props) => {
  const [loading, setLoading] = useState(false)
  const isLogin = props.member.isLogin()

  const onCheck = useCallback(async () => {
    if (isLogin) return
    try {
      setLoading(true)
      await props.member.checkLoginUser()
    } catch (e) {
      console.log('check user:', e.message)
    }
    setLoading(false)
  }, [props.member, isLogin])

  useEffect(() => {
    onCheck()
  }, [onCheck])

  if (loading) {
    return (
      <Routes>
        <Route path="*" element={<Loading />} />
      </Routes>
    )
  }

  const content = isLogin ? (
    <>
      <Route path="/" element={<Dashboard />} />
      <Route path="/user" element={<UserList />} />
      <Route path="/user/:id" element={<UserDetail />} />
      <Route path="/admin" element={<AdminList />} />
      <Route path="/admin/create" element={<AdminDetail />} />
      <Route path="/admin/:id/edit" element={<AdminDetail />} />

      <Route path="/life-bridge" element={<LifeBridge />}>
        <Route index element={<LifeBridgeList />} />
        <Route path="new" element={<LifeBridgeNew />} />
        <Route path="create" element={<LifeBridgeTeamCreate />} />
        <Route path=":id" element={<LifeBridgeTeam />}>
          <Route index element={<LifeBridgeTeamDetail />} />
          <Route path="new" element={<LifeBridgeTeamNew />} />
          <Route path="edit" element={<LifeBridgeTeamEdit />} />
          <Route path="edit-agent" element={<LifeBridgeTeamEditAgent />} />
          <Route path="history" element={<LifeBridgeTeamHistory />} />
          <Route path="agent/:agent_id" element={<LifeBridgeTeamAgent />} />
        </Route>
      </Route>

      <Route
        path="/common-problem/:id/detail"
        element={<CommonProblemDetail />}
      />
      <Route path="/common-problem/detail" element={<CommonProblemDetail />} />
      <Route
        path="/common-problem/category"
        element={<CommonProblemCategoryList />}
      />
      <Route path="/common-problem" element={<CommonProblemList />} />
      <Route path="/issue-problem" element={<IssueProblemList />} />
      <Route
        path="/issue-problem/:id/detail"
        element={<IssueProblemDetail />}
      />
      <Route
        path="/issue-problem/category"
        element={<IssueProblemCategoryList />}
      />
      <Route path="/consent/privacy" element={<PrivacyPage />} />
      <Route path="/consent/terms" element={<TermsPage />} />

      <Route path="/transactions" element={<Transactions />} />
      <Route path="/transactions/detail/:username" element={<TransDetail />} />

      <Route path="/setting/provinces" element={<ProvinceList />} />
      <Route path="/setting/promotion" element={<PromotionList />} />
      <Route path="/setting/promotion/create" element={<PromotionCreate />} />
      <Route path="/setting/promotion/:code" element={<PromotionDetail />} />

      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/edit" element={<ProfileEdit />} />

      <Route path="/feedback" element={<Feedback />} />

      <Route path="/design-system" element={<DesignSystem />} />
    </>
  ) : (
    <Route path="*" element={<LoginPage />} />
  )
  return (
    <MainLayout>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {content}
      </Routes>
    </MainLayout>
  )
}

export default inject('member')(observer(App))
