import React, { useState } from 'react'
import styled from 'styled-components'

import usePopup from '../hooks/usePopup'
import useClickAway from '../hooks/useClickAway'

const Popup = (props) => {
  const { show = { v: 'bottom', h: 'right' }, hover = false } = props
  const [isOpen, setOpen] = useState(false)
  const { ref, innerRef, horizontal, vertical, onCalculatePosition } = usePopup(
    { show }
  )

  useClickAway({
    name: 'popup',
    onClick: () => setOpen(false),
    forwardRef: ref,
  })

  const content =
    typeof props.content === 'function'
      ? props.content({ horizontal, vertical })
      : props.content
  const children =
    typeof props.children === 'function'
      ? props.children({ isOpen })
      : props.children

  if (hover) {
    return (
      <HoverWrapper ref={ref} onMouseEnter={onCalculatePosition}>
        {children}
        <HoverInnerWrapper
          ref={innerRef}
          horizontal={horizontal}
          vertical={vertical}
        >
          {content}
        </HoverInnerWrapper>
      </HoverWrapper>
    )
  }

  return (
    <Wrapper
      ref={ref}
      onClick={(e) => {
        e.stopPropagation()
        setOpen((open) => !open)
        onCalculatePosition()
      }}
    >
      {children}
      <InnerWrapper
        ref={innerRef}
        isOpen={isOpen}
        horizontal={horizontal}
        vertical={vertical}
        onClick={(e) => {
          e.stopPropagation()
          setOpen((open) => !open)
        }}
      >
        {content}
      </InnerWrapper>
    </Wrapper>
  )
}

const InnerWrapper = styled.div`
  display: flex;
  position: absolute;
  white-space: pre-wrap;

  z-index: 100;
  visibility: ${(p) => (p.isOpen ? 'visible' : 'hidden')};

  ${({ vertical: { direction, value } }) => `${direction}: ${value}px`};
  ${(p) => (p.horizontal.direction === 'left' ? 'right' : 'left')}: 0;
`

const HoverInnerWrapper = styled.div`
  display: none;
  position: absolute;

  z-index: 100;

  ${({ vertical: { direction, value } }) => `${direction}: ${value}px`};
  ${(p) => (p.horizontal.direction === 'left' ? 'right' : 'left')}: -22px;
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const HoverWrapper = styled(Wrapper)`
  &:hover ${HoverInnerWrapper} {
    display: flex;
  }
`

export default Popup
