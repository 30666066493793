import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Card } from 'components/display'
import AppMenu from 'pages/menu'
import { message } from 'utils'

const Dashboard = (props) => {
  const { dashboard } = props
  const { data = {} } = dashboard.toJS()

  const [, setLoading] = useState(false)

  const onInit = useCallback(async () => {
    try {
      setLoading(true)
      await dashboard.getDashboard()
    } catch (e) {
      message.error({
        title: `แสดงรายการ`,
        message: e.message,
      })
    }
    setLoading(false)
  }, [dashboard])

  useEffect(() => {
    onInit()
  }, [onInit])

  return (
    <AppMenu name="dashboard">
      <PageView>
        <CardContainer>
          <Card label="สมาชิก 6 เดือนล่าสุด" value={data.user_count} />
          <Card label="รายรับ 6 เดือนล่าสุด" value={'-'} />
          <Card label="ปัญหาที่รอแก้ไข" value={data.issue_problem_count} />
          <Card
            label="Daily active users"
            value={data.active_users?.daily_active_users || '-'}
          />
          <Card
            label="Weekly active users"
            value={data.active_users?.weekly_active_users || '-'}
          />
          <Card
            label="Monthly active user"
            value={data.active_users?.monthly_active_users || '-'}
          />
          <Card label="Downloads" value={'50+'} />
          <Card label="App Store" value={'-'} />
          <Card label="Google Play" value={'50+'} />
        </CardContainer>
      </PageView>
    </AppMenu>
  )
}

const PageView = styled.div`
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 465px) {
  }
`
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-gap: 1rem;
`

export default inject('dashboard')(observer(Dashboard))
