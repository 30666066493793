import React from 'react'
import styled from 'styled-components'
import { Row } from 'antd'

import { color } from '../../definition'

const TableRow = (props) => {
  const { onClick } = props
  return (
    <PageView className={onClick ? 'pointer' : ''} onClick={onClick}>
      <Row justify="start" align="middle">
        {props.children}
      </Row>
    </PageView>
  )
}

const PageView = styled.div`
  padding: 12px 10px;
  border-radius: 10px;
  border: 1px solid ${color.border};
  background-color: white;
  margin-bottom: 4px;

  &.pointer {
    cursor: pointer;
  }
`

export default TableRow
