import { runInAction } from 'mobx'
import axios from 'axios'

import BaseStore from './BaseStore'

let state

export class Transactions extends BaseStore {
  constructor() {
    super()
    this.observable({
      Transactionsdata: null,
      TransacbyID: {},
      pagination: {
        index: 1,
        per_page: 10,
        total: 30,
      },
    })

    state = this
  }

  async getUserProfile(params = {}) {
    // //const { user } = params

    // const url = `api.github.com/users`
    // const resp = await http.get(url)

    // const data = resp.body
    const res = await axios.get(
      `https://api.github.com/users?client_id=2398fb7cd8665a22f40d&client_secret=f946ca9f57b70986b7e5c7d9021cafb1e76ce82e`
    )

    await this.AddData(res.data)

    // runInAction(() => {
    //   state.Transactionsdata = res.data
    // })
  }

  getRandomDate() {
    const maxDate = Date.now()
    const timestamp = Math.floor(Math.random() * maxDate)
    return new Date(timestamp)
  }

  getRandomStatus() {
    let status = ['approve', 'reject', 'fail', 'cancel']

    let random = Math.floor(Math.random() * status.length)
    return status[random]
  }

  async AddData(param = []) {
    const newData = param.map((object) => {
      let newDate = this.getRandomDate()
      let newStatus = this.getRandomStatus()
      let newPair = { status: newStatus, date: newDate }
      return { ...object, ...newPair }
    })

    runInAction(() => {
      state.Transactionsdata = newData
    })
  }

  async getUserbyID(userid) {
    // //const { user } = params

    // const url = `api.github.com/users`
    // const resp = await http.get(url)

    // const data = resp.body
    const res = await axios.get(
      `https://api.github.com/users/${userid}?client_id=2398fb7cd8665a22f40d&client_secret=f946ca9f57b70986b7e5c7d9021cafb1e76ce82e`
    )
    let newDate = this.getRandomDate()
    let newStatus = this.getRandomStatus()
    let newPair = { status: newStatus, date: newDate }
    let newData = { ...res.data, ...newPair }

    runInAction(() => {
      state.TransacbyID = newData
    })
  }

  async SearchUser(txt) {
    const res = await axios.get(
      `https://api.github.com/search/users?q=${txt}&client_id=2398fb7cd8665a22f40d&client_secret=f946ca9f57b70986b7e5c7d9021cafb1e76ce82e`
    )

    await this.AddData(res.data.items)
  }
}

export default new Transactions()
