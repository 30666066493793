import styled from 'styled-components'

const IconButton = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: unset !important;
  padding: 0;
`

export default IconButton
