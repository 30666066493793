import { Row } from 'antd'
import { inject, observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Filter from 'components/Filter'
import { Link } from 'components/link'
import LifeBridgeTeamNewAgentTable from 'components/table/lifeBridgeTeamNewAgentTable'
import { Text } from 'components/typo'
import {
  LIFE_BRIDGE_STATE,
  LIFE_BRIDGE_STATE_OPTIONS,
  LIFE_BRIDGE_STATE_TH,
} from 'definition/support'
import { message } from 'utils'

import EmptyTableMessage from '../../components/EmptyTableMessage'

function LifeBridgeTeamNew(props) {
  const {
    current = {},
    agentList = [],
    pagination = {},
  } = props.life_bridge.toJS()
  const { id } = useParams()

  const [selectedState, setSelectedState] = useState(LIFE_BRIDGE_STATE.NEW)
  const [loading, setLoading] = useState(false)

  const getTeamDetail = useCallback(
    async (company_affiliation_id) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeam(company_affiliation_id)
      } catch (e) {
        message.error({
          message: 'เกิดข้อผิดพลาดบางอย่าง',
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const getTeamAgentsNewcomer = useCallback(
    async (params) => {
      try {
        setLoading(true)
        await props.life_bridge.getTeamAgents(params)
      } catch (e) {
        message.error({
          title: `แสดงรายการ`,
          message: e.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [props.life_bridge]
  )

  const onSelectMenu = (val) => {
    setSelectedState(val)
    getTeamAgentsNewcomer({
      page: 1,
      per_page: 10,
      company_affiliation_id: id,
      status: val,
    })
  }

  const onTableChange = (page) => {
    getTeamAgentsNewcomer({
      page: page.index,
      per_page: page.per_page,
      company_affiliation_id: id,
      status: selectedState,
    })
  }

  useEffect(() => {
    getTeamDetail(id)
    getTeamAgentsNewcomer({
      page: 1,
      per_page: 10,
      company_affiliation_id: id,
      status: LIFE_BRIDGE_STATE.NEW,
    })
  }, [id, getTeamAgentsNewcomer, getTeamDetail])

  return (
    <>
      <Row style={{ marginBottom: 8 }}>
        <Link
          to={`/life-bridge/${id}`}
          style={{ color: 'var(--fd-secondary-dark-color)' }}
        >
          {`< ${current.company_affiliation_name}`}
        </Link>
      </Row>
      <Row justify="center">
        <Text large bold align="center">
          สมาชิกที่เข้าร่วมใหม่ หน่วย {current.company_affiliation_name}
        </Text>
      </Row>
      <Row justify="end" style={{ marginBottom: 8 }}>
        <Filter
          label="สถานะเอกสาร"
          selectedValue={selectedState}
          extendLabel={LIFE_BRIDGE_STATE_TH[selectedState]}
          options={LIFE_BRIDGE_STATE_OPTIONS.slice(
            0,
            LIFE_BRIDGE_STATE_OPTIONS.length - 1
          )}
          onSelect={onSelectMenu}
        />
      </Row>
      {agentList.length > 0 ? (
        <LifeBridgeTeamNewAgentTable
          teamId={id}
          data={agentList}
          page={pagination}
          loading={loading}
          selectedState={selectedState}
          onChange={onTableChange}
        />
      ) : (
        <EmptyTableMessage message="ยังไม่มีสมาชิกที่เข้าร่วมใหม่" />
      )}
    </>
  )
}

export default inject('life_bridge')(observer(LifeBridgeTeamNew))
