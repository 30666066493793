import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { http } from 'utils'
import { config } from 'config'

import BaseStore from './BaseStore'

const original = {
  detail: {
    common_problem_category_id: undefined,
    seq: 1,
    title: '',
    detail: '',
  },
}

let state
export class CommonProblem extends BaseStore {
  constructor() {
    super()
    this.observable({
      display: {
        filter: {
          status: '',
        },
        pagination: {
          index: 1,
          per_page: 25,
          total: 0,
        },
        list: [],
      },
      detail: cloneDeep(original.detail),
      category_list: [],
      add: false,
    })
    state = this
  }

  async getList(val) {
    const { display } = this.toJS()
    let url = `${config.api}/v1/admin/support/common-problem`
    if (val) {
      url = `${config.api}/v1/admin/support/common-problem?keywords=${val}`
    }
    const resp = await http.get(url)

    const list = resp.body || []
    display.list = list
    runInAction(() => {
      state.display = display
    })
  }

  async getCountList() {
    const url = `${config.api}/v1/admin/support/common-problem/count`
    const resp = await http.get(url)

    const { counter } = resp.body || { counter: 0 }
    runInAction(() => {
      state.display.pagination.total = counter
    })
  }

  async getCategoryList() {
    const url = `${config.api}/v1/admin/support/common-problem/category`
    const resp = await http.get(url)

    const list = resp.body || []
    runInAction(() => {
      state.category_list = list
    })
  }

  async getCommonProblemById(id) {
    const url = `${config.api}/v1/admin/support/common-problem/${id}`
    const resp = await http.get(url)

    const doc = resp.body || {}
    runInAction(() => {
      state.detail = doc
    })
  }

  setCommonProblem(doc) {
    runInAction(() => {
      state.detail = doc
    })
  }

  async saveCommonProblemCategory(val, id) {
    const url = `${config.api}/v1/admin/support/common-problem/category`
    const common_problem_category_id = id
    let newDoc
    if (common_problem_category_id) {
      const resp = await http.methodPut(
        `${url}/${common_problem_category_id}`,
        val
      )
      newDoc = resp.body
    } else {
      const opt = { name: 'โปรดระบุ' }
      const resp = await http.methodPost(url, opt)
      newDoc = resp.body
    }

    runInAction(() => {
      state.category_list = newDoc
    })
  }

  async saveCommonProblem(doc) {
    const { common_problem_id } = doc

    if (common_problem_id) {
      const url = `${config.api}/v1/admin/support/common-problem/${common_problem_id}`
      await http.methodPut(url, doc)
    } else {
      const url = `${config.api}/v1/admin/support/common-problem`
      await http.methodPost(url, doc)
    }
  }

  async remove(id) {
    const url = `${config.api}/v1/admin/support/common-problem/${id}`
    await http.delete(url)
  }

  async removeCategory(id) {
    const url = `${config.api}/v1/admin/support/common-problem/category/${id}`
    await http.delete(url)

    const { category_list = [] } = this.toJS()

    const index = category_list.findIndex(
      (it) => it.common_problem_category_id === id
    )

    category_list.splice(index, 1)
    runInAction(() => {
      state.category_list = category_list
    })
  }

  async saveNewCommonProblem(detail, id) {
    if (id) {
      const url = `${config.api}/v1/admin/support/common-problem/${id}`
      await http.put(url, { json: detail })
    } else {
      const url = `${config.api}/v1/admin/support/common-problem`
      await http.post(url, { json: detail })
    }
  }

  setAdd() {
    runInAction(() => {
      state.add = !state.add
    })
  }
}

export default new CommonProblem()
