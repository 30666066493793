import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import AppMenu from 'pages/menu'
import Button from 'components/button/Button'
import { InfoRow, UserImg } from 'components/display'
import DeleteProfie from 'pages/profile/DeleteProfie'
import { format, message } from 'utils'

function Profile(props) {
  const { member } = props
  const { user } = member.toJS()

  const onDeleteProfile = useCallback(
    async (cb = () => {}) => {
      await member.deleteUserProfile()
      cb()
      message.success({ message: 'profile has been deleted' })
    },
    [member]
  )

  return (
    <AppMenu name="profile">
      <Container>
        <div>
          <Avatar img_url={user.img_url} size="127px" />
        </div>
        <div>
          <Header>
            <Title>โปรไฟล์ของฉัน</Title>
            {/* <Link to="/profile/edit">
              <Button
                maxWidth="117px"
                type="button"
                text="แก้ไข"
                onClick={() => {}}
              />
            </Link> */}
          </Header>
          <InfoContainer>
            <CustomInfoRow label="ชื่อ" text={user.name} />
            <CustomInfoRow label="นามสกุล" text={user.surname} />
            <CustomInfoRow label="เพศ" text={user.gender} />
            <CustomInfoRow
              label="วันเกิด"
              text={format.toDate(user.birthdate)}
            />
            <CustomInfoRow label="หมายเลขโทรศัพท์" text={user.mobile} />
            <CustomInfoRow label="อีเมล" text={user.email} />
          </InfoContainer>
          {/* <DeleteProfie onSubmit={onDeleteProfile} /> */}
        </div>
      </Container>
    </AppMenu>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 35% 65%;
  padding: 4rem 0;
`
const Avatar = styled(UserImg)`
  display: block;
  margin: 0 auto;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  button {
    min-width: 117px;
  }
`
const Title = styled.h1`
  font-weight: bold;
  margin: 0;
`
const InfoContainer = styled.div`
  margin-bottom: 2rem;
`
const CustomInfoRow = styled(InfoRow)`
  > label:first-of-type {
    width: 100%;
    max-width: 150px;
  }
  > div:last-of-type {
    width: 100%;
  }
`

export default inject('member')(observer(Profile))
