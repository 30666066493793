import React from 'react'
import styled from 'styled-components'

import { helper } from 'utils'
import { FieldInput } from 'components/input'

const Detail = (props) => {
  const { doc = {}, disabled, onChange } = props
  const onValChange = (val, name) => {
    doc[name] = val
    onChange(doc)
  }

  return (
    <PageView>
      <FieldInput
        name="seq"
        label="ลำดับ"
        value={doc.seq}
        onChange={onValChange}
        disabled={disabled}
        isNumber={true}
        errorText={helper.isTextError(doc.seq, 'กรอกลำดับ')}
      />

      <FieldInput
        name="name"
        label="ชื่อ"
        value={doc.name}
        onChange={onValChange}
        disabled={disabled}
        errorText={helper.isTextError(doc.name, 'กรอกชื่อ')}
      />
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
`

export default Detail
